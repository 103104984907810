/* Use some styles for react-datepicker too */
.calendar {
}
.calendar-date-picker {
    width: 269px;
    z-index: 105;
}
.datepicker__container,
.react-datepicker__tether-element {
    z-index: 5;
}
.calendar-datepicker .titles .day,
.react-datepicker__header .react-datepicker__day-name,
.calendar-day {
    float: left;
    width: 39px;
    padding-right: 2px;
    padding-left: 2px;
    padding-bottom: 8px;
    text-align: center;
    color: $base-font-colour;
    border-bottom: 2px solid $border-1;
    font-weight: bold;
    &:first-child {
        width: 37px;
        padding-left: 0px;
    }
    &:last-child {
        width: 37px;
        padding-right: 0px;
    }
}
.calendar-datepicker .months .month,
.calendar-datepicker .week .day,
.react-datepicker__month .react-datepicker__day,
.calendar-date {
    float: left;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    cursor: pointer;
    margin: 2px;
    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }
    &.react-datepicker__day--selected,
    &.selected {
        font-weight: bold;
    }
    &.react-datepicker__day--selected,
    &.selected,
    &.selected-range {
        border: 1px solid $border-1;
        background-color: $grey-bg-1;
        line-height: 33px;
    }
    &:hover {
        border: 1px solid $border-1;
        background-color: $grey-bg-1;
        line-height: 33px;
        color: $verint-blue;
    }
}
.react-datepicker__header {
    position: relative;
}
.react-datepicker__month .react-datepicker__day.react-datepicker__day--disabled,
.calendar-date-disabled {
    color: $form-disabled-text;
    &:hover {
        border: none;
        background-color: transparent;
        line-height: 35px;
        cursor: default;
    }
}
.calendar-datepicker .header,
.react-datepicker__current-month,
.calendar-header {
    position: relative;
    display: block;
    font-size: 16px;
    text-align: center;
    margin-bottom: 15px;
}
.calendar-header-year {
    color: $button-text;
}
.calendar-datepicker .previous,
.react-datepicker__navigation--previous,
.calendar-prev-month {
    @extend .icon-left-open-1;
    position: absolute;
    left: 0;
    top: -3px;
    cursor: pointer;
    z-index: 1;
    /* use before as can't add icon- class to react-datepicker */
    &:before {
        color: $base-font-colour;
        font-family: 'fontello';
        font-size: 20px;
    }
}
.calendar-datepicker .next,
.react-datepicker__navigation--next,
.calendar-next-month {
    @extend .icon-right-open-1;
    position: absolute;
    font-size: 20px;
    right: 0;
    top: -3px;
    cursor: pointer;
    z-index: 1;
    /* use before as can't add icon- class to react-datepicker */
    &:before {
        color: $base-font-colour;
        font-family: 'fontello';
        font-size: 20px;
    }
}
.calendar-datepicker .today,
.react-datepicker__month .react-datepicker__day.react-datepicker__day--today,
.calendar-date.calendar-today {
    color: $verint-blue;
    font-weight: bold;
    font-weight: 500;
}
/* Old motools datepicker overrides */
.calendar-datepicker {
    padding: 15px;
    overflow: hidden;
    z-index: 5005; /* to get over old overlay */
    .unavailable,
    .otherMonth {
        color: $form-disabled-text;
    }
    .body {
        position: relative;
        overflow: hidden;
        height: 235px;
        width: 270px
    }
    .titles .day,
    .week .day {
        float: none;
    }
    .closeButton {
        display: none;
    }
    .months .month {
        width: 86px;
        height: 55px;
        &, &.selected, &.selected:hover {
            line-height: 55px;
        }
        &:hover {
            line-height: 53px;
        }
    }
    .day {
        border: none !important;
    }
    .next, .previous {
        font-size: 26px;
        top: -8px;
        &:before {
            content: "" !important;
        }
    }
    .months {
        @include group;
    }
}

/* react-datepicker specific styles */
.react-datepicker {
    background-color: $white;
    border: 1px solid $border-1;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    padding: 15px;
}

.react-datepicker__triangle {
    display: none;
}
