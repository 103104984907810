.main-content {
    position: absolute;
    left: 0;
    right: 0;
    min-width: 1024px;

    .main-nav + .side-nav + &,
    .main-nav + & {
        top: $main-nav-height
    }
    .secondary-nav + .side-nav + &,
    .secondary-nav + & {
        top: $main-nav-height;
    }
    .tertiary-nav + & {
        top: $main-nav-height + $secondary-nav-height
    }
    .side-nav + & {
        margin-left: $side-nav-width;
        margin-top: $main-nav-height;
        min-width: 1024px - $side-nav-width;
    }

    &.standard-page {
        background: $white;
        overflow: auto;
        padding: 10px;
    }
    &.iframe-page {
        bottom: 3px;
        padding: 0;
        background: $white;
        iframe {
            width: 100%;
            height: 100%;
            border: none;
        }
    }
}
.main-body {
    margin-left: 220px;
}
