@include checkboxes;
@include radios;

@mixin input-error {
    label {
       color: $red;
    }
    .input.text,
    .input.textarea,
    input[type=password],
    input[type=text],
    input[type=email]
    textarea {
        background-color: $white;
        border-color: $red;
    }
    .icon-wrapper:before {
        color: $red;
    }
}

@mixin input-group {
    @include group;
    margin-bottom: 15px;
    position: relative;
    &.input-error {
        @include input-error;
    }
    label {
        color: $base-font-colour;
        display: block;
        margin-bottom: 10px;
    }
    input[type=password],
    input[type=text],
    input[type=email] {
        @include input-text;
        &:disabled {
            color: $button-text;
        }
    }
    textarea {
        @include input-textarea;
    }
    &.input-large {
        margin-bottom: 20px;
        .input.text {
            padding: 8px 10px;
            font-size: 14px;
            line-height: 18px;
        }
    }
    &.last-group {
        margin-bottom: 10px;
    }
    .dropdown {
        textarea ~ .dropdown-menu,
        input ~ .dropdown-menu {
            min-width: 100%;
            li,
            li span {
                max-width: none;
            }
        }
    }
    .working {
        color: $button-text;
        &:before {
            content: url('../images/conv-spinner-small.gif');
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
        }
    }
    /* Make dropdown buttons full width */
    .dropdown {
        .button {
            width: 100%;
        }
        .dropdown-menu {
            min-width: 100%;
        }
        .button {
            text-align: left;
        }
    }
}

@mixin input-text {
    display: block;
    @include border-radius(3px);
    background: $white;
    border: 1px solid  $form-border;
    padding: 5px;
    line-height: 18px;
    width: 100%;
    color: $base-font-colour;
    @include box-sizing(border-box);
    &:focus {
        outline: none;
        border-color: $blue-focus;
    }
    &.small-input {
        font-size: 12px;
        padding: 3px 3px 2px;
    }
}
@mixin input-textarea {
    @include input-text;
    resize: vertical;
    line-height: 18px;
}

.input-group { @include input-group; }
.reactable-filter-input,
.input.text {
    @include input-text;
}
.input.textarea { @include input-textarea; }

.input.time,
.input.date {
    &.icon-wrapper:before {
        right: 5px;
        left: auto;
        color: $base-font-colour;
        font-size: 14px;
        top: 7px;
        z-index: 0;
        cursor: pointer;
    }
    &.icon-wrapper .input.text {
        padding-left: 5px;
    }
    .input {
        padding-right: 23px;
    }
}
.toolbar {
    .input.date {
        width: 115px;
    }
    .input.text {
        min-width: 115px;
    }
    .input.time {
        &, &.hr-24 {
            width: 75px;
        }
        &.hr-12 {
            width: 88px;
        }
    }
}

.reactable-filter-input,
.input.text-wrapper,
.input.textarea,
.input.text {
    background: $white;
    border: 1px solid  $form-border;
    @include border-radius(3px);
    &.active,
    &:focus {
        outline: none;
        border-color: $blue-focus;
    }
    min-width: 150px;
}
.input.text-wrapper {
    > input,
    > textarea {
        background: none;
        border: none;
    }
}
.input.search {
    .clear-search {
        display: none;
        position: absolute;
        right: 8px;
        top: 7px;
        color: $button-text;
        &:hover {
            cursor: pointer;
            color: $base-font-colour;
        }
    }
    &.has-results {
        .clear-search {
            display: block;
        }
    }
    .button.go {
        position: absolute;
        top: 0;
        left: 100%;
        &:active {
            position: absolute;
            top: 1px;
        }
    }
    &.has-go-button {
        margin-right: 60px;
        .input.text {
            @include border-top-right-radius(0);
            @include border-bottom-right-radius(0);
        }
        .button {
            @include border-top-left-radius(0);
            @include border-bottom-left-radius(0);
            min-width: 60px;
            width: 60px;
            text-align: center;
            &.working {
                color: transparent;
                &:before {
                    margin-top: -2px;
                    top: 2px;
                    left: 7px;
                }
            }
        }
        .input.text {
            border-right-width: 0px;
            &:focus {
                border-right-width: 1px;
            }
        }
    }
}
.input.icon-wrapper {
    position: relative;
    &:before {
        position: absolute;
        top: 5px;
        left: 8px;
        font-size: 18px;
        color: $verint-blue;
        z-index: 1;
    }
    .input.text {
        padding-left: 26px;
    }
}
.input.textarea,
.input.text {
    padding: 5px;
}
.input.inner {
    background: transparent;
    border: 0;
    outline: none;
    line-height: 21px;
    min-width: 150px;
}
.input.textarea.inner {
    background: transparent;
    border: 0;
    outline: none;
    resize: vertical;
    padding: 10px;
}
.input-group.inline-inputs {
    margin-bottom: 5px;
    fieldset {
        border: none;
        padding: 0;
        margin: 0;
    }
}
.input-group.inline-group {
    > .input,
    > input[type=text],
    > input[type=email],
    > input[type=password],
    > * {
        display: inline-block;
        vertical-align: middle;
        width: auto;
    }
    .input.radio{
        display: none;
    }
    .input.checkbox {
        appearance: none;
        background-color: $white;
        border: solid 1px $form-border;
        border-radius: 2px;
        display: inline-block;
        height: 14px;
        position: relative;
        width: 14px;

        &:active {
            background-color: $grey-2;
            border-color: $verint-blue;
        }

        &:hover {
            border-color: $verint-blue;
        }

        &:checked:before {
            background: url('data:image/svg+xml; utf8, <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><polygon fill="%230075E4" points="4.7,10 1.5,6.9 2.9,5.5 4.7,7.2 9.2,2.7 10.6,4.1 "/></svg>');
            content: '';
            display: block;
            height: 12px;
            left: 0;
            position: absolute;
            top: 0;
            width: 12px;
        }

        &:focus {
            outline: solid 2px $blue-focus;
        }
    }
    label {
        margin-bottom: 0;
        margin-right: 5px;
    }
    .input-helper {
        display: block;
    }
    &.input-right {
        .input {
            float: right;
        }
    }
}
.input-group.token-input {
    input[type=text] {
        border: none;
    }
}
.block-input {
    .input.radio + label,
    .input.checkbox + label {
        margin-bottom: 0px;
    }
    margin-bottom: 10px;
    .input-group {
        display: inline-block;
        margin-left: 1px;
    }
}
.input-helper {
    color: $button-text;
    margin-left: 0px;
    margin-top: 5px;
}
.input-col {
    float: left;
    position: relative;
    margin-right: 15px;
    &.input-error {
        @include input-error;
    }
    &.large {
        margin-right: 20px;
    }
    &:last-child {
        margin-right: 0;
    }
}
.input-group-block {
    .input-group {
        margin-bottom: 5px;
    }
    .input-group:last-child {
        margin-bottom: 0px;
    }
    margin-bottom: 15px;
}
@include keyframes(bubble-fade-in) {
    0% {
        @include transform(translateY(20px) translateX(-50%));
        opacity: 0;
    }
    100% {
        @include transform(translateY(0px) translateX(-50%));
        opacity: 1;
    }
}
.input-notification-bubble {
    /* In case element is always in DOM, hide when empty */
    &:empty {
        display: none;
    }
    position: absolute;
    z-index: 2;
    bottom: -20px;
    padding: 4px 7px;
    font-size: 12px;
    left: 50%;
    @include border-radius(3px);
    @include transform(translateX(-50%));

    @include animation-name(bubble-fade-in);
    @include animation-duration(0.25s);

    &.prompt-notification,
    &.warning-notification,
    &.critical-notification {
        white-space: nowrap;
        &:after, &:before {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }
        &:after {
            border-width: 6px;
            margin-left: -6px;
        }
        &:before {
            border-width: 7px;
            margin-left: -7px;
        }
    }
    &.prompt-notification {
        background: $information;
        border: 1px solid $prompt-blue-border;
        &:after {
            border-color: transparent;
            border-bottom-color: $information;
        }
        &:before {
            border-color: transparent;
            border-bottom-color: $prompt-blue-border;
        }
    }
    &.warning-notification {
        background: $warning-yellow;
        border: 1px solid $warning-yellow-border;
        &:after {
            border-color: transparent;
            border-bottom-color: $warning-yellow;
        }
        &:before {
            border-color: transparent;
            border-bottom-color: $warning-yellow-border;
        }
    }
    &.critical-notification {
        background: $orange;
        border: 1px solid $red;
        z-index: 1000;
        &:after {
            border-color: transparent;
            border-bottom-color: $orange;
        }
        &:before {
            border-color: transparent;
            border-bottom-color: $red;
        }
    }
}
@include keyframes(checkbox-bubble-fade-in) {
    0% {
        @include transform(translateY(20px));
        opacity: 0;
    }
    100% {
        @include transform(translateY(0px));
        opacity: 1;
    }
}
.input.checkbox ~ .input-notification-bubble{
    left: 7px;
    bottom: -29px;
    @include transform(none);
    @include animation-name(checkbox-bubble-fade-in);
}
[contenteditable=true]:empty:before {
    content: attr(placeholder);
    display: block; /* For Firefox */
    color: $button-text;
}
.input.multi-item-wrapper {
    @include input-text;
    padding: 0 0 5px 0px;
    min-height: 32px;
    input {
        width: auto;
        padding: 0;
        margin-left: 5px;
        margin-top: 5px;
        line-height: 21px;
        display: inline-block;
        border: none !important;
        background: transparent !important;
    }
    &.focus,
    &:focus {
        outline: none;
        border-color: $blue-focus;
    }
    .tag {
        margin-left: 5px;
        margin-top: 5px;
        margin-right: 0px;
    }
    .tag:not(.tag-error) {
        border-color: $form-border;
        max-width: none;
    }
}

/* Toggle */
.input.toggle {
    @include remove-list;
    background: $white;
    border: 1px solid  $form-border;
    @include border-radius(3px);
    @include group;
    position: relative;
    display: inline-block;
    &.toggle-dont-animate .toggle-handle {
        @include transition(none);
    }
    &.toggle-no-value .toggle-handle {
        display: none;
    }
    padding: 0;
    margin: 0;
}
.input.toggle.on .toggle-handle {
    left: 50%;
}
.toggle-handle,
.toggle-option {
    float: left;
    min-width: 50px;
    text-align: center;
    line-height: 19px;
    cursor: pointer;
    padding: 0;
    margin: 0;
}
.toggle-option {
    padding: 0 5px;
}
.toggle-handle {
    position: absolute;
    left: 0px;
    line-height: 23px;
    top: -3px;
    background: $base-font-colour;
    border: 1px solid $base-font-colour;
    color: $white;
    @include border-radius(3px);
    @include transition(125ms left ease-out);
    &.ghost {
        opacity: 0.35;
        color: $base-font-colour;
    }
}
.toggle-handle-green {
    background-color: $green;
    border-color: $green-light-border;
}
.toggle-handle-red {
    background-color: $red;
    border-color: $legacy-button-red-border;
}

/* Slider */
.input.slider {
    @include group;
}
.slider-bar {
    float: left;
    position: relative;
    border: 1px solid  $form-border;
    background: $white;
    @include border-radius(3px);
    width: 150px;
    height: 19px;
    padding: 0;
    margin: 0;
}
/* fuck jquery */
.slider-bar.ui-slider {
    @extend .slider-bar;
    margin-left: 5px;
}
.slider-handle {
    position: absolute;
    top: -3px !important;
    cursor: pointer;
    background: $base-font-colour;
    border: 1px solid $base-font-colour;
    height: 23px;
    @include border-radius(3px);
    width: 23px;
    outline: none;
    margin: 0;
    padding: 0;
}
.ui-slider-handle {
    @extend .slider-handle;
}
.slider-value {
    float: left;
    line-height: 19px;
    margin-right: 5px;
}

/* Editable list */
.input.editable-list {
    @include input-textarea;
    @include remove-list;
    padding: 10px;
    overflow-y: auto;
}
.editable-list-item {
    line-height: 22px;
}
.editable-list-edit {
    float: right;
    margin-top: -8px;
}

/* Multi-select */
.input.multi-select {
    @include border-radius(3px);
    overflow: scroll;
    background: $white;
    border: 1px solid  $form-border;
    .multi-select-groups,
    .multi-select-items {
        @include remove-list;
        @include group;
        padding: 10px 8px 8px 10px;
    }
    .multi-select-items {
        overflow-y: auto;
    }
    .multi-select-group,
    .multi-select-item {
        float: left;
        cursor: pointer;
        width: 49%;
        margin-right: 2px;
        margin-bottom: 2px;
        label {
            @include ellipsis;
            @include border-radius(3px);
            border: 1px transparent solid;
            padding: 10px;
            display: block;
            margin-right: 0;
        }
        &:hover label,
        .input.checkbox:checked + label {
            border-color: $form-border;
            background-color: $grey-bg-1;
        }
        &:hover label {
            color: $verint-blue;
        }
    }
    .multi-select-groups {
        background: $white;
        border-bottom: 1px solid  $form-border;
    }
}

/* Time input hack */
.input-time-range {
    white-space: nowrap;
    .is-select {
        width: 50px;
        min-width: 0 !important;
    }
    select:first-child + .button {
        @include border-radius(3px 0px 0px 3px !important);
        border-left: 1px solid  $form-border !important;
    }
    .input-time-to {
        display: inline-block;
        margin: 0 5px;
    }
}
