.dialog-add-reminder {
    label {
        display: inline-block;
        margin-right: 10px;
        width: 125px;
        text-align: right;
        color: $button-text;
        &.checkbox-label {
            width: initial;
        }
    }
    .toolbar.date-time-inputs {
        display: inline-block;
        vertical-align: middle;
        .send-update-time-inputs {
            .is-select {
                width: 50px;
                min-width: 0;
            }
            select:first-child + .button {
                @include border-radius(3px 0px 0px 3px);
                border-left: 1px solid  $border-1;
            }
        }
    }
    .assign-dropdown {
        display: inline-block;
        vertical-align: middle;
    }
}
