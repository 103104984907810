.reject-box,
.edit-box,
.reply-box {
    @include border-radius(3px);
    background: $white;
    border: 1px solid  $border-1;
}
.reply-box {
    position: relative;
    margin: 50px 5px 0px;
    .toolbar .action-reply {
        margin-left: 0;
    }
    .toolbar .action-note {
        margin-right: 0;
    }
    .toolbar .action-reply:before {
        width: 15px;
    }
    .toolbar .crm-button-open,
    .crm-toolbar,
    .crm-controls,
    .crm-inputs,
    .forward-toolbar,
    .forward-inputs,
    .forward-controls,
    .toolbar .forward-button-open,
    .toolbar .note-button-open,
    .note-toolbar,
    .note-controls,
    .toolbar .reply-button-open,
    .reply-toolbar,
    .reply-controls {
        display: none;
    }
    .reply-chars-remaining {
        position: absolute;
        bottom: 50px;
        right: 20px;
    }
}
.edit-box {
    .reply-chars-remaining {
        position: absolute;
        bottom: 50px;
        right: 70px;
    }
    .send-private-message {
        margin-left: 10px;
    }
}
.reply-controls {
    position: relative;
    .send-private-message {
        margin-left: 10px;
    }
    .insert-snippet {
        position: absolute;
        top: 10px;
        right: 10px;
        .button {
            opacity: 0.5;
        }
        &.show, &:hover,
        &.active, &:active {
            .button {
                opacity: 1;
            }
        }
        &.active, &:active {
            top: 11px;
        }
    }
}
.reply-chars-remaining {
    color: $button-text;
    &.warning {
        color: $base-font-colour;
    }
    &.critical {
        color: $red;
        font-weight: bold;
        font-weight: 500;
    }
}
.reply-box-notification {
    display: none;
    .reply-box.has-notification & {
        display: block;
    }
    &:before {
        margin-right: 4px;
    }
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-top-width: 1px;
    border-top-style: solid;
    padding: 10px 15px;
    &.no-top-border {
        border-top: none;
    }

}
.reply-box {
    .all-button-closed {
        display: none;
    }
    &:not(.forward-open):not(.reply-open):not(.note-open):not(.crm-open) {
        .reply-button-closed,
        .note-button-closed,
        .forward-button-closed,
        .crm-button-closed {
            display: none;
        }
        .all-button-closed {
            display: block;
        }
    }
}
.reply-box.forward-open {
    .toolbar .forward-button-closed,
    .reply-placeholder {
        display: none;
    }
    .toolbar .forward-button-open {
        display: inline-block;
    }
    .forward-toolbar,
    .forward-controls,
    .forward-inputs {
        display: block;
    }
    &.has-notification  .reply-box-notification {
        @include border-radius(3px);
        margin: 10px 10px 5px;
    }
}
.reply-box.crm-open {
    .toolbar .crm-button-closed,
    .reply-placeholder {
        display: none;
    }
    .toolbar .crm-button-open {
        display: inline-block;
    }
    .crm-toolbar,
    .crm-controls,
    .crm-inputs {
        display: block;
    }
}
.reply-box.note-open {
    .toolbar .note-button-closed,
    .reply-placeholder {
        display: none;
    }
    .toolbar .note-button-open {
        display: inline-block;
    }
    .note-toolbar,
    .note-controls {
        display: block;
    }
}
.reply-box.reply-open {
    .toolbar .reply-button-closed,
    .reply-placeholder {
        display: none;
    }
    .toolbar .reply-button-open {
        display: inline-block;
    }
    .reply-toolbar,
    .reply-controls {
        display: block;
    }
}
.reject-box-toolbar,
.edit-box-toolbar,
.reply-box-toolbar {
    padding: 10px 5px;
    border-bottom: 1px solid $border-1;
    margin: 0 5px;
    .reject-box &,
    .edit-box &,
    .reply-box.note-open &,
    .reply-box.reply-open & {
        border-bottom: none;
    }
}
.reply-box-inputs {
    .reply-box.forward-open &,
    .reply-box.crm-open & {
        margin: 15px;
    }
}
.reject-box-body,
.edit-box-body,
.reply-box-body {
    @include border-bottom-left-radius(3px);
    @include border-bottom-right-radius(3px);
    .input.textarea {
        width: 100%;
        min-height: 81px;
        padding: 15px;
        /* CSS to preserve spaces in div contenteditable */
        white-space: pre-wrap;       /* css-3 */
        white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
        white-space: -pre-wrap;      /* Opera 4-6 */
        white-space: -o-pre-wrap;    /* Opera 7 */
        word-wrap: break-word;       /* Internet Explorer 5.5+ */
        .reply-text-preview {
            white-space: normal;
        }
    }
    &.has-clipboard .input.textarea,
    &.can-add-photo .input.textarea {
        /* So text doesn't go under clipboard/photo button */
        padding-right: 40px;
    }
    &.can-add-photo.has-clipboard .input.textarea {
        /* So text doesn't go under either photo and clipboard button when both enabled */
        padding-right: 80px;
    }
    .clipping-preview {
        color: $form-disabled-text;
        strong {
            color: $verint-blue;
        }
    }

    .reply-insert-controls {
        position: absolute;
        bottom: 7px;
        left: 10px;
        .button-group {
            display: inline-block;
        }
        .button {
            vertical-align: top;
            opacity: 0.5;
        }
        .button:not(.disabled):not([disabled]) {
            &.open,
            &:hover {
                opacity: 1;
            }
        }
        .button.open {
            background: $white;
        }
    }

    min-height: 134px;
    .reply-box.forward-open & {
        @include box-shadow(inset 1px 1px 2px 0px $legacy-conversations-reply-box-shadow);
        background: $yellow-pale;
        min-height: 0;
        border-top: 1px solid  $border-1;
    }
    .reply-box.note-open & {
        @include box-shadow(inset 1px 1px 2px 0px $legacy-conversations-reply-box-shadow);
        background: $yellow-pale;
        min-height: 0;
        border-top: 1px solid  $border-1;
    }
    .reject-box &,
    .edit-box &,
    .reply-box.crm-open &,
    .reply-box.reply-open & {
        @include box-shadow(inset 1px 1px 2px 0px $legacy-conversations-reply-box-shadow);
        background: $white;
        min-height: 0;
        border-top: 1px solid  $border-1;
    }
    .reply-box.reply-open.public-reply & {
        background: $bright-blue-background-alpha-03;
    }
    .reply-box.reply-open.private-reply & {
        background: $dark-blue-alpha-05;
    }

    .reply-box.has-notification & {
        border-top: none;
    }
}

.reject-box-actions,
.edit-box-actions,
.reply-box-actions {
    padding: 10px;
}
.reply-placeholder {
    padding: 15px;
    color: $button-text;
    cursor: text;
    min-height: 130px;
}
.reply-box-channel-selector {
    text-align: left;
    max-width: 190px;
}
.reply-as-text {
    @include ellipsis;
    max-width: 295px;
}
.reply-shorten {
    color: $button-text;
    margin-left: 15px;
}
.crm-inputs {
    .crm-search {
        min-width: 415px;
    }
}

.reply-box-attachments:not(:empty) {
    border-top: 1px dashed $border-1;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
}
.reply-box-image-preview {
    display: inline-block;
    position: relative;
    background: $login-bg;
    margin-right: 5px;
    @include border-radius(3px);

    &:hover .reply-box-image-remove {
        display: block;
    }
    &.dz-processing:not(.dz-complete) .reply-box-image,
    &:hover .reply-box-image {
        opacity: 0.25;
    }
    &.dz-processing:not(.dz-complete) .reply-box-image-progress {
        opacity: 1;
    }
    &.dz-error {
        background: $orange;
        .reply-box-image {
            opacity: 0.15;
        }
        .reply-box-image-error {
            display: block;
        }
        .reply-box-image-remove {
            color: $white;
        }
    }
}
.reply-box-image-remove {
    z-index: 1;
    display: none;
    position: absolute;
    top: 3px;
    right: 3px;
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: $button-text;
    &:hover {
        color: $white;
    }
    text-align: center;
    cursor: pointer;
    &:before {
        margin-right: 0;
    }
}
.reply-box-image-progress {
    @include border-radius(3px);
    opacity: 0;
    @include transition(opacity 0.125s ease-in-out);
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    height: 10px;
    background: $white-alpha-80;
    overflow: hidden;
    z-index: 1;
    span {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 0;
        background: $verint-blue-alpha-80;
        @include transition(width 0.250s ease-in-out);
    }
}
.reply-box-image-error {
    display: none;
    color: $white;
    font-size: 24px;
    position: absolute;
    top: 0;
    width: 24px;
    height: 24px;
    line-height: 24px;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;

    &:hover .reply-box-image-error-message {
        display: block;
    }
}

.reply-box-image-error-message {
    display: none;
    left: -50px;
    top: -57px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
}

.reply-box-image {
    @include border-radius(3px);
    display: block;
    object-fit: cover;
    height: 75px;
    width: 75px;

    display: flex;
    align-items: center;
    justify-content: center;

    [class^="icon-"] {
        color: $white;
        font-size: 37.5px;

        &:before {
            margin: 0;
        }
    }
}
.reply-box-image-with-caption {
    &:nth-child(2n) {
        margin-left: 5px;
    }
    display: inline-block;
    @include group;
    .reply-box-image-preview {
        float: left;
    }
    .input.textarea,
    textarea {
        float: left;
        height: 75px;
        width: 157px;
    }
}

.reply-box-drop-target {
    pointer-events: none;
    display: block;
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background: $white-alpha-80;
    border: 3px dashed $verint-blue;
    @include border-radius(3px);
    opacity: 0;
    span {
        text-align: center;
        top: 50%;
        left: 0;
        right: 0;
        margin-top: -15px;
        position: absolute;
        font-size: 24px;
    }

}
.dz-drag-hover {
    &.reply-box-drop-target {
        pointer-events: auto;
        opacity: 1.0;
    }
}

/* Forward inputs */
#forward-input-to {
    width: calc(100% - 30px);
    .dropdown-menu {
        min-width: 0;
        max-width: 100%;
    }
    .dropdown-scroll {
        display: block;
    }
    li:not(.hover):not(:hover) .forward-input-email {
        color: $button-text;
    }
}
.tag.send-private-message {
    max-width: none;
    border-color: $border-1;
    height: 26px;
    line-height: 25px;
}
