// frontend/sass/conversations/_customer_panel.scss should be kept in sync with
// frontend2/styles/_customer_panel.scss wherever possible. Conversations
// sets some extra properties in frontend/sass/conversations/_app.scss
// and queues sets some properties (mostly for things like buttons) in
// frontend2/styles/_customer_panel_queues_extras.scss

$customer-detail-label-margin-right: 10px;


.customer-integration-pane {
    .customer-panel-integration-header {
        padding: 12px 0px;
        margin: 0px 13px;
        border-top: 1px solid $border-2;
        border-bottom: 1px solid $border-1;

        .customer-header {
            border-bottom: none;

            .integration-logo,
            .integration-logo-wide {
                float: left;
                height: 40px;
            }

            .integration-logo {
                width: 40px;

                &.compact {
                    height: 25px;
                    width: 25px;
                }
            }

            .customer-name {
                font-weight: 500;
                font-size: 12pt;
                color: $base-font-colour;
                height: 30px;
                line-height: 30px;
                display: block;
                margin-left: 50px;

                &.compact {
                    line-height: 23px;
                    margin-left: 30px;
                }
            }

            .header-left {
                float: left;
                width: 70%;
            }

            .header-right {
                float: right;
                text-align: right;
                width: 30%;
            }

            .header-link-integration {
                width: 100%;

                .button-wrapper {
                    float: left;

                    >button:only-child {
                        margin-left: 10px !important
                    }
                }
            }

            &:after {
                content: "";
                display: table;
                clear: both;
            }
        }
        .search-bar.customer-details{
            margin: 0px 13px;
            padding: 12px 0px 0px 0px;
            display: flex;

            .search-bar-input {
                flex-grow: 1;
            }

            .search-field-dropdown {
                margin-left: 12px;
            }
        }
    }

    .customer-extra-detail {
        &.has-button {
            float: right;
        }
    }

    .customer-detail {
        padding-top: 2px;
        min-height: 18px;
        line-height: 18px;
    }

    .customer-integration-details {
        width: 100%;
        overflow: auto;
        padding-bottom: 20px;

        .customer-details {
            .title {
                font-weight: bold;
                font-weight: 500;
                color: $base-font-colour;
                height: 30px
            }
        }

        .customer-detail-block {
            &.card-ticket {
                border: 1px solid $border-1;
                padding: 6px;
                margin-top: 0;
                border-bottom: 0;

                &:nth-child(2) {
                    border-top-left-radius: 3px;
                    border-top-right-radius: 3px;
                }

                &:last-of-type {
                    border-bottom: 1px solid $border-1;
                    border-bottom-left-radius: 3px;
                    border-bottom-right-radius: 3px;
                }

                .customer-detail {
                    padding: 7px 0px;
                }
            }

            &.card-user-profile {
                border: 1px solid $border-1;
                border-radius: 3px;
                padding: 6px;

                .customer-detail-name {
                    font-weight: 500;
                    font-size: 10pt;
                    color: $base-font-colour;
                }

                &:last-of-type {
                    border-bottom: 1px solid $border-1;
                }
            }

            &.card-search-result {
                border: 1px solid $border-1;
                border-radius: 3px;
                padding: 6px;

                &:nth-child(2) {
                    margin-top:0;
                }

                &:last-of-type {
                    border-bottom: 1px solid $border-1;
                }
            }
        }

        .loading-wrapper {
            overflow-x: hidden;
            margin-top: 12px;
            position: relative;
            height: 48px;
            min-height: 48px;
        }
    }

    // Inside the dialog box
    [class^="dialogs__dialog_"] {
        min-width: 570px;

        .ticket-view .toolbar {
            padding: 10px 0px;
        }

        .ticket-view fieldset {
            margin: 0;
            padding: 0;
            max-width: 540px;
        }

        .input-group {
            max-width: inherit;

            label {
                margin-bottom: 5px;
            }

            .comment-details {
                max-height: 100px;
            }

            textarea, .comment-details {
                overflow: auto;
            }

            textarea, select, input, .comment-details{
                margin-top: 10px;
            }

            select {
                margin-left: 10px;
            }
            .card-comment {
                padding:10px;
                background: $highlight;
                border-radius: 3px;
                line-height: 18px;
                margin:0px;

                &.card-comment-end-user{
                    background: $white;
                }
                .comment-timestamp{
                    text-align:right;
                    color: $base-font-colour;
                    font-size:11px;
                }
                .comment-detail {
                    overflow-x: auto;
                    white-space: pre-wrap;
                }
            }
        }
    }



    .form-input-select {
        background-color: $white;
        border-radius: 3px;
        border: 1px solid  $border-1;
        color: $base-font-colour;
        cursor: pointer;
        display: inline-block;
        font-size: 13px;
        font-weight: normal;
        height: 30px;
        line-height: 18px;
        padding: 5px;
        text-align: left;
        vertical-align: middle;

        &:focus {
            outline: none;
            border-color: $blue-focus;
        }
    }

    .multi-select-multi-level-dropdown {

        input {
            padding-left: 26px;
        }
    }
}

.customer-integrations-dialog-wrapper {
    @extend .customer-integration-pane;

    textarea {
        width: 100%;
        resize: vertical;
    }
}

.customer-panel {
    height: 100%;
    background-color: $white;
    border-top: 1px solid $border-2;
    padding-bottom: 10px;
    position: relative;
    a {
        @include link;
    }
}

.customer-panel.no-annotations {
    .customer-header {
        height: 57px;
    }

    .customer-panel-details {
        top: 57px;
    }

    .customer-header .toolbar {
        display: none;
    }
}

.customer-panel-pane {
    position: absolute;
    /* these will make them as wide as the customer panel */
    left: 0;
    right: 0;

    &.customer-header {
        @include group;
        top: 0;
        height: 95px;
        padding: 12px 0px;
        margin: 0px 13px;
        border-bottom: 1px solid $border-1;
        .customer-profile-image {
            float: left;
            width: 30px;
            height: 30px;
        }
        .customer-name {
            margin-left: 40px;
            font-weight: bold;
            font-weight: 500;
            font-size: 11pt;
            color: $base-font-colour;
            height: 30px;
        }
        > .toolbar {
            margin-top: 10px;
        }
    }

    &.customer-panel-details {
        top: 95px;
        overflow-y: auto;
        padding-bottom: 50px;
        bottom: 0;

        &.is-top-pane {
            bottom: 50%;
        }
    }

    &.customer-integration-pane {
        display: none;

        /* duplication here with iframe code */
        &.is-bottom-pane {
            top: 50%;
            bottom: 0;
            display: flex;
            flex-direction: column;

            .customer-panel-integrations {
                height: 100%;
                width: 100%;
                padding-top: 10px;
                border-top: 1px solid $border-2;
                margin: 0 13px;
            }
        }
    }

    &.customer-iframe-pane {
        display: none;

        &.is-bottom-pane {
            top: 50%;
            bottom: 0;
            display: block;

            .customer-iframe {
                height: 100%;
                padding-top: 10px;
                border-top: 1px solid $border-2;
                margin: 0 13px;

                iframe {
                    width: 100%;
                    height: 100%;
                    display: block;
                    border: none;

                }
                .customer-iframe-multiple {
                    padding: 3px 8px;
                }
                .customer-detail {
                    padding-left: 7px;
                    padding-right: 7px;
                }
                .multiple-field-name {
                    color: $button-text;
                }
            }
        }
    }
}
.customer-banned-list {
    @include antialiased;
    @include border-radius(3px);
    background: $orange;
    color: $white;
    padding: 10px;
    margin: 10px 10px 0px;
    ul {
        @include remove-list;
        margin-top: 8px;
    }
    ul li {
        margin-top: 2px;
    }
}
.customer-details {
    margin: 0px 13px 1px;
    padding: 10px 0px 5px 0px;
    .customer-detail-block {
        @include group;
        margin-top: 10px;
    }
    > .customer-detail-block:first-child {
        margin-top: 0px;
    }
    &.user-card-wrapper,
    &.confirmation-dialog-wrapper {
        border-bottom: 1px solid $border-1;
        padding-bottom: 10px;

        .button-wrapper {
            float: right;

            >button {
                margin: 4px;
            }
        }
    }
    &.user-card-wrapper-scroll {
        overflow-y: scroll;
        max-height: 30vh;
        margin-right: 0;
    }
}
.customer-detail {
    @include group;
    min-height: 17px;
    line-height: 17px;
    &.has-tags {
        margin-bottom: -5px;
        .customer-detail-value {
            overflow: visible;
            margin-left: 26px;
        }
        .tags {
            white-space: normal;
            @include remove-list;
        }
        .tag {
            margin-bottom: 5px;
        }
        .tag-name {
            white-space: nowrap;
        }
    }
    &.has-input {
        .customer-detail-label {
            margin-top: 3px;
            margin-right: $customer-detail-label-margin-right - 4px;
        }
        .customer-detail-value {
            margin-top: -3px;
        }
    }
    .customer-detail-menu .dropdown.dropdown-open,
    &:hover .customer-detail-menu .dropdown,
    &.always-show-menu-toggle .customer-detail-menu .dropdown {
        display: block;
    }
}
.customer-detail-editing {
    @include group;
    padding-right: 17px;
    padding-top: 4px;
    .toolbar {
        float: right;
    }
}
.customer-detail-button {
    display: inline-block;
    margin-right: 3px;
    vertical-align: middle;
    &.button-group.group-tiny {
        margin-top: 0px;
    }
    .button {
        &.is-following {
            padding-left: 3px;
        }
    }
}
.customer-detail-feedback {
    float: right;
    width: 16px;
    height: 16px;
    position: relative;
    &.working:before {
        position: absolute;
        content: "";
        background: url("../../images/conv-spinner-small.gif") no-repeat;
        width: 16px;
        height: 16px;
    }
    &.icon-check-1:before {
        color: $green-dark;
        font-size: 16px;
        position: relative;
        left: 3px;
    }
    &.icon-cancel-1:before {
        color: $red;
        font-size: 16px;
        position: relative;
        left: 4px;
    }
}
.customer-detail.has-input .customer-detail-feedback {
    top: 6px;
    left: 4px;
}
.customer-detail-block-spacer {
    border-top: 1px solid $border-2;
    margin-top: 25px;
}
.customer-detail-menu {
    float: right;
    .dropdown {
        display: none;
    }
}
.customer-detail-label {
    @include ellipsis;
    &:before {
        color: $button-text !important;
        font-size: 14px;
    }
    line-height: 16px;
    color: $button-text;
    min-width: 15px;
    max-width: 35%;
    white-space: nowrap;
    margin-right: $customer-detail-label-margin-right;
    float:left;
}
.customer-detail-value {
    @include ellipsis;
    &.placeholder {
        color: $button-text;
    }
}
.customer-detail-header {
    margin-top: 8px;
    line-height: 16px;
    color: $button-text;
    min-width: 15px;
    white-space: nowrap;
    margin-right: $customer-detail-label-margin-right;
}
.customer-detail.customer-detail-multiline {
    .customer-detail-value {
        white-space: pre-wrap;
    }
    .customer-detail-menu .dropdown {
        display: block;
        visibility: hidden;
    }
    .customer-detail-menu .dropdown.dropdown-open,
    &:hover .customer-detail-menu .dropdown {
        visibility: visible;
    }
}
.customer-extra-details {
    margin-left: 26px;
    .customer-extra-detail {
        @include group;
        position: relative;
        margin-top: 9px;
        word-wrap: break-word;
        &:first-child {
            margin-top: 8px;
        }
        &:hover .customer-detail-pin {
            display: block;
            color: $button-text;
        }
        &.has-button {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    &:not(.customer-extra-expand) .customer-extra-detail {
        &:hover .customer-detail-pin {
            color: $account-clipboard-dropdown-menu !important;
        }
    }
    .customer-extra-detail:not(.pinned):not(.customer-extra-toggle){
        display: none;
    }
    &.customer-extra-expand {
        .customer-extra-detail:not(.pinned):not(.customer-extra-toggle){
            display: block;
        }
        .customer-extra-detail.pinned {
            .customer-detail-pin {
                display: block;
                color: $account-clipboard-dropdown-menu !important;
            }
        }
    }
    .customer-extra-toggle {
        cursor: pointer;
        background: $grey-bg-2;
        border: 1px solid $border-1;
        @include border-radius(2px);
        display: inline-block;
        padding-left: 3px;
        padding-right: 3px;
        line-height: 6px;
        padding-bottom: 6px;
        &:active {
            position: relative;
            top: 1px;
        }
    }
    .customer-detail-pin {
        float: right;
        cursor: pointer;
        display: none;
    }
    .customer-extra-detail-label {
        color: $button-text;
    }
}
.customer-notes {
    margin-top: 12px;
    margin-left: 13px;
    margin-right: 13px;
}
.customer-note-block {
    position: relative;
    padding: 15px 5px;
    border-top: 1px solid $border-2;
    &:last-child {
        margin-bottom: 0px;
    }
    &:hover {
        .customer-note-grip {
            display: block;
        }
    }
}
.customer-note-grip {
    display: none;
    position: absolute;
    top: 7px;
    bottom: 5px;
    left: -5px;
    width: 6px;
    background: url('../../images/grip.png');
    cursor: move;
}
.customer-actions {
    border-top: 1px solid $border-2;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 10px;
}
.dg-customer-panel-ban {
    left: 50%;
    top: 40%;
    margin-left: -180px;
    margin-top: -75px;
}
.tooltip{
    color: $button-text;
    font-size: 12px;
    &.tag{
        margin: -1px -5px 0 -5px;
    }
}
/* Hide tooltips temporarily */
.new-tooltip,
.new-tooltip-inverted {
    display: none;
}

.merge-ticket-body {
    .ticket-to-merge {
        border-bottom: solid 1px $border-1;
        padding: 5px;
        margin-bottom: 20px;
        .title {
            font-weight: bold;
        }
        .subject {
            color: $base-font-colour;
        }
        div, label{
            margin: 5px 0;
        }
    }

    .ticket-search-results {
        max-height: 220px;
        overflow: auto;
        border: solid 1px $border-1;
        margin: -1px 1px
    }
}

.card-ticket-selectable {
    border: solid 1px $border-1;
    margin: -1px 0;
    padding: 10px;
    min-height: 40px;

    &:hover {
        background-color: $white;
    }
    .left {
        float: left;
    }
    .right {
        float: right;
    }
    .text {
        margin-top: 2px
    }
    .dismiss {
        float: right;
        margin-left: 5px;
        > button {
            padding: 0;
        }
    }
}

.dialog-body-wrapper {
    min-height: 430px;
}

.modal-tab-wrapper {
    border-bottom: solid 1px $border-1;
    min-height: 50px;
    .tab {
        float: left;
        padding-top: 19px;
        min-height: 30px;
        &:hover {
            background-color: $white;
            border-bottom: solid 2px $border-1;
        }
        &.selected {
            border-bottom: solid 2px $border-1;
        }
    }
}
