.button-group {
    @include group;
    .button {
        float: left;
    }
    .dropdown {
        @include group;
        float: left;
    }
    > .dropdown:first-child:not(:last-child) .button,
    > .button:first-child:not(:last-child) {
        border-right: none;
    }
    > .dropdown:not(:first-child):not(:last-child) .button,
    > .button:not(:first-child):not(:last-child) {
        border-left: none;
        border-right: none;
    }
    > .dropdown:not(:first-child):last-child .button,
    > .button:not(:first-child):last-child {
        border-left: none;
    }
    &.group-tiny {
        margin-top: -4px;
        .button {
            height: 21px;
            line-height: 20px;
            padding: 0px 3px;
            min-width: 0px;
            &:not(:empty){
                padding-left: 7px;
                padding-right: 7px;
            }
            &:before {
                padding-top: 0;
                padding-right: 0px;
                padding-left: 0px;
            }
            &:empty.has-dropdown {
                min-width: 19px;
                &:after {
                    right: 4px;
                    top: 2px;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }
}
