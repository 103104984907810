//colors
.deepanalytics {
    .switch {
        border: 1px solid $border-1;
        width: 40px;
        height: 17px;
        border-radius: 13px;
        cursor: pointer;
        display: inline-block;
        float: right;
        margin: 8px 0;

        &.on {
            .switch-toggle {
                left: 21px;
            }
        }
    }
    .switch-toggle {
        width: 16px;
        height: 15px;
        left: 1px;
        border-radius: 12px;
        background: $button-text;
        position: relative;
        transition: left 0.2s ease-in-out;
    }
}
