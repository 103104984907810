.has-dropdown {
    padding-right: 29px;
    position: relative;
    &:after {
        @extend .icon-down-open-1, :before;
        font-family: "fontello";
        font-size: 15px;
        position: absolute;
        right: 10px;
    }
}
.dropdown-menu {
    &, ul {
        @include remove-list;
    }
    background-color: $white;
    border: 1px solid $border-1;
    color: $base-font-colour;
    float: left;
    max-height: 300px;
    max-width: $max-dropdown-width;
    min-width: 50px;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    z-index: 1;
    &.has-search {
        max-height: none;
        overflow: visible;
        .dropdown-scroll,
        .scrolled {
            max-height: 300px;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
    &.no-max-height {
        max-height: none;
        overflow: visible;
    }
    &.fixed-width {
        width: $fixed-dropdown-width;
    }
    li {
        @include user-select(none);
        @include ellipsis;
        border-top: 1px solid $border-2;
        font-weight: normal;
        line-height: 25px;
        padding: 0px 25px;
        position: relative;
        text-align: left;
        &.info {
            &, &:before {
                color: $verint-blue;
            }
        }
        &:first-child {
            border-top: none;
        }
        &:not(.disabled):not(.info):not(.dropdown-header):not(.no-results) {
            cursor: pointer;
            &.selected,
            &:hover,
            &.hover {
                background: $highlight;
                color: $verint-blue;
            }
        }
        &.disabled {
            color: $base-font-colour;
            cursor: not-allowed;
        }
        &.clear-selection {
            color: $base-font-colour;
        }
        img {
            display: inline-block;
            height: 15px;
            vertical-align: middle;
        }
        input[type=checkbox] {
            display: inline-block;
            margin-right: 3px;
            vertical-align: middle;
        }
        @include checkboxes(5px, 3px);
        span {
            &:before {
                height: 23px;
            }
        }
        &.item-with-dropdown .has-dropdown:before,
        &:before {
            font-size: 14px;
            left: 6px;
            position: absolute;
        }
        &.item-with-dropdown {
            overflow: visible;
            padding-left: 0;
            padding-right: 0;
            .has-dropdown {
                padding-left: 25px;
                padding-right: 25px;
            }
        }
        &.item-with-dropdown .has-dropdown:after,
        &.has-dropdown:after {
            color: $base-font-colour;
            font-family: "fontello";
        }
        &.item-with-dropdown .has-dropdown:after,
        &:after {
            font-size: 14px;
            position: absolute;
            right: 10px;
            top: 0;
        }
        a {
            display: block;
            &, &:active, &:visited {
                color: $base-font-colour;
                text-decoration: none;
            }
            &:hover {
                color: $verint-blue;
            }
        }
        &.has-dropdown {
            overflow: visible;
        }
    }
    .divides {
        border-top: 1px solid $verint-blue;
    }
    .dropdown-header {
        &, &:hover {
            background: $white;
            color: $base-font-colour;
            cursor: auto;
            font-size: 11px;
        }
    }
    &.has-columns,
    .has-columns {
        max-height: none;
        max-width: none;
        .header-background {
            background: $grey-bg-1;
            border-bottom: 1px solid $border-1;
            height: 26px;
            @include border-top-left-radius(3px);
            @include border-top-right-radius(3px);
        }
        .body-area {
            max-height: 300px;
            overflow-x: hidden;
            overflow-y: auto;
            .header-area {
                display: table-header-group;
                li {
                    color: $base-font-colour;
                    cursor: auto;
                    font-size: 11px;
                    position: static;
                }
                span {
                    position: absolute;
                    top: 0;
                }
            }
            .dropdown-scroll {
                display: table-row-group;
            }
            li {
                display: table-row;
                padding: 0 25px;
            }
            li.whole-row {
                display: block;
            }
            li div {
                border-top: 1px solid $border-2;
                display: table-cell;
                max-width: $max-dropdown-width;
                padding-left: 10px;
                @include ellipsis;
                &:first-child {
                    min-width: 125px;
                    padding-left: 25px;
                }
                &:last-child {
                    padding-right: 25px;
                }
            }
            li:first-child div {
                border-top: none;
            }
            li:last-child {
                margin-bottom: 0;
            }
        }
        &.has-counts {
            .body-area {
                li div + div {
                    min-width: 70px;
                    text-align: right;
                    &:last-child {
                        min-width: 105px;
                    }
                }
                .header-area li div + div {
                    text-align: initial;
                    span {
                        min-width: 60px;
                        text-align: right;
                        /* width auto to counteract width: 100% on main style,
                           results in label being too far right */
                        width: auto;
                    }
                }
            }
        }
    }
    &.has-checkboxes,
    .has-checkboxes {
        li {
            /* overflow visible otherwise whole row has text ellipsis in FF */
            overflow: visible;
            padding-left: 7px;
            /* Needs extra padding because of checkboxes */
            &.info {
                padding-left: 35px;
            }
            > span {
                display: inline-block;
                /* need padding to offset
                   checkbox width from weird
                   browser behaviour */
                padding-right: 25px;
                vertical-align: middle;
                width: 100%;
                @include ellipsis;
            }
        }
        &.has-columns .header-area li div:first-child span {
            padding-left: 18px;
        }

        &.has-columns .body-area li div:first-child {
            padding-left: 7px;
        }
    }
    .search {
        border-bottom: 1px solid $border-1;
        line-height: 25px;
        padding: 0px 21px 0px 24px;
        position: relative;
        &:after {
            color: $agent-dashboard-filter-dropdown-search;
            content: "\e801";
            font-size: 14px;
            left: 8px;
            position: absolute;
            top: 1px !important;
        }
        &:hover {
            background: transparent;
            border-top-color: $border-1;
        }
        input {
            background: transparent;
            border: none;
            /* if in input-group then need to ensure display is correct */
            display: inline-block;
            min-width: 75px;
            outline: none;
            width: 100%;
        }
        .clear-search,
        .close {
            position: absolute;
            right: 5px;
            top: 6px;
        }
        &.has-results .clear-search {
            display: block;
        }
    }
    input[type=checkbox] {
        margin-right: 4px;
    }
    &.has-filters .search,
    .has-filters .search,
    .has-filters.search {
        margin: 0;
        padding: 5px 35px;
        &:after {
            left: 0;
        }
        .clear-search {
            right: 10px;
            top: 6px;
        }
    }
    /* If opening inline dialog */
}
.dropdown-filters {
    background: $grey-bg-2;
    border-bottom: 1px solid $border-1;
    padding: 10px;
    white-space: nowrap;
    .filter {
        cursor: pointer;
        display: inline-block;
        padding: 0 10px;
    }
    .active {
        font-weight: bold;
        position: relative;
        /* Arrow x */
        &:after, &:before {
            border: solid transparent;
            bottom: -11px;
            content: " ";
            height: 0;
            left: 50%;
            pointer-events: none;
            position: absolute;
            width: 0;
        }

        &:after {
            border-bottom-color: $white;
            border-color: transparent;
            border-width: 8px;
            margin-left: -8px;
        }
        &:before {
            border-bottom-color: $border-1;
            border-color: transparent;
            border-width: 9px;
            margin-left: -9px;
        }
    }
}

.dropdown {
    position: relative;
    .dropdown-menu {
        display: none;
        margin-top: 3px;
        position: absolute;
        top: 100%;
        z-index: 1;
        @include box-sizing(border-box);
        &.open-left {
            right: 0;
        }
    }
    &.dropdown-open:not(.open-up) {
        .dropdown-menu {
            display: block;
        }
        > .button,
        > .select {
            background-color: $white;
        }
    }
    &.open-up {
        .dropdown-menu {
            bottom: 100%;
            margin-bottom: 3px;
            margin-top: 0px;
            top: auto;
        }
        &.dropdown-open {
            .dropdown-menu {
                display: block;
            }
            .button,
            .select {
                background-color: $white;
            }
        }
        .search {
            border-bottom: none;
            border-top: 1px solid $border-2;
        }
    }
    &.dropdown-static {
        position: relative;
        .dropdown-menu {
            max-height: none;
        }
        > .dropdown-menu {
            position: static;
        }
    }
    &.dropdown-full-width {
        > .button {
            text-align: left;
            width: 100%;
        }
        > .dropdown-menu {
            max-width: none;
            width: 100%;
        }
    }
}

.queues-settings {
    .dropdown {
        &:not(.dropdown-open) .dropdown-menu {
            display: block !important;
        }
    }
}

/* dropdown overrides */
.dropdown {
    &.dropdown-open .dropdown-menu {
        display: block !important;
    }
    &:not(.dropdown-open) .dropdown-menu {
        display: none !important;
    }
}
.dropdown-menu {
    background: $white;
    li {
        border-bottom: 1px solid transparent;
        border-top: 1px solid transparent;
        line-height: 28px;
        padding: 0 35px;
        &:first-child {
            border-top: 1px solid transparent;
            margin-top: 10px;
        }
        &:last-child {
            margin-bottom: 10px;
        }
        &.item-with-dropdown .has-dropdown {
            padding: 0 35px;
        }
        &.item-with-dropdown .has-dropdown:before,
        &:before {
            color: $base-font-colour;
            font-size: 15px;
            left: 0;
            text-align: center;
            width: 35px;
        }
        .input.checkbox + label {
            margin-left: -2px;
            width: 25px;
        }
        /* If span has icon then add proper margin */
        span:before {
            // Make all icons the same horizontal size so the text lines up
            display: inline-block;
            margin-right: 6px;
            min-width: 1em;
        }
        &.has-sublevels {
            position: relative;
            &:after {
                @extend .icon-right-open-1, :before;
                font-family: "fontello";
                font-size: 15px;
                position: absolute;
                right: 10px;
            }
        }
        &.back-level {
            @extend .icon-left-open-1;
            color: $base-font-colour;
            &:before {
                color: $base-font-colour;
                font-family: "fontello";
                font-size: 15px;
            }
        }
        .results-levels-truncated {
            color: $base-font-colour;
        }
        .results-level {
            color: $base-font-colour;
            &:after {
                @extend .icon-right-open-1, :before;
                color: $base-font-colour;
                font-family: "fontello";
                font-size: 15px;
                padding-left: 6px;
                padding-right: 2px;
                vertical-align: bottom;
            }
        }
        &.no-results:hover,
        &.no-results {
            background: transparent;
            color: $button-text;
        }
        &.analytics {
            padding-left: 10px;
        }
    }

    .clear-all:hover,
    .clear-all {
        background: transparent;
        font-size: 12px;
        margin-top: 10px;
        padding-right: 15px;
        .clear-all-button {
            background-color: $white;
            border: none;
            margin-left: auto;
            text-decoration: underline;
        }
        .clear-all-button:hover {
            color: $verint-blue;
            cursor: pointer;
        }
    }
    .dropdown-header {
        line-height: 14px;
        padding-bottom: 2px;
    }
    .search {
        margin: 0 10px;
        padding: 5px 25px;
        &.analytics {
            border-top: 1px solid $border-2;
            padding: 5px 5px 5px 15px;
        }
        &:after {
            color: $base-font-colour;
            font-family: "fontello";
            font-size: 15px;
            left: -10px;
            line-height: 35px;
            text-align: center;
            top: 1px !important;
            width: 35px;
        }
        .clear-search {
            color: $button-text;
            cursor: pointer;
            display: none;
            &:hover {
                color: $base-font-colour;
            }
            &:after {
                left: 7px;
            }
        }
    }
    &.has-columns .divider div,
    &:not(.has-columns) .divider {
        cursor: auto;
        height: 20px;
        position: relative;
        &:hover {
            background: none !important;
            border: transparent !important;
        }
        &:before {
            background-color: $border-1;
            content: "";
            height: 1px;
            left: 10px;
            overflow: hidden;
            position: absolute;
            right: 10px;
            top: 50%;
            width: auto;
        }
    }
    &.has-columns .divider {
        &:hover {
            background: none !important;
            div {
                border: transparent !important;
            }
        }
        div {
            &:before {
                left: 0;
                right: 0;
            }
            &:first-child:before {
                left: 10px;
            }
            &:last-child:before {
                right: 10px;
            }
        }
    }
    .header-area {
        li {
            font-size: 12px;
            margin: 0;
            span:hover,
            &:hover {
                background: inherit !important;
                color: $base-font-colour !important;
                div {
                    border-color: transparent !important;
                }
            }
        }
    }
    .has-columns .header-background,
    &.has-columns .header-background {
        background: $grey-bg-2;
        height: 28px;
    }
    .has-columns .body-area,
    &.has-columns .body-area {
        padding-bottom: 10px;
        padding-top: 10px;
        :not(.header-area) div {
            border-bottom: 1px solid transparent;
            border-top: 1px solid transparent;
            line-height: 28px;
        }
        li:first-child div {
            border-top: 1px solid transparent;
        }
        li.hover div,
        li:hover div {
            border-color: $border-1;
        }
        li div {
            &:last-child {
                padding-right: 35px;
            }
            &:first-child {
                padding-right: 30px;
            }
        }
    }
    li:not(.disabled):not(.info):not(.dropdown-header):not(.no-results) {
        &.selected {
            background: transparent;
            color: $verint-blue;
            &:before {
                color: $verint-blue;
            }
        }
        &:hover, &.hover {
            background: $highlight;
        }
    }
}
.channel-dropdown {
    .dropdown-menu {
        max-height: 500px;
    }
    .button {
        max-width: 200px;
    }
    .channel-group {
         font-weight: bold;
    }
}
