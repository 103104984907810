@mixin kb-shortcut-key ($size) {
    border: 1px solid $border-1;
    background: $grey-bg-2;
    @include border-radius(2px);
    display: inline-block;
    text-align: center;
    height: $size;
    width: $size;
    line-height: $size;
}

/* Shortcuts bar */
.kb-shortcuts {
    color: $centre-panel-keyboard-shortcuts;
    background-color: $grey-bg-2;
    border-top: 1px solid $border-2;
    position: relative;
    padding: 5px 15px 5px 35px;
    height: 27px;
    @include ellipsis;
    cursor: pointer;
    &:before {
        position: absolute;
        font-size: 18px;
        line-height: 10px;
        top: 9px;
        left: 14px;
    }
    &:hover:before {
        color: $centre-panel-keyboard-hover;
    }
}
.kb-shortcut-key {
    @include kb-shortcut-key(15px);
    margin-left: 10px;
}
.kb-shortcut-description {
    height: 15px;
    line-height: 15px;
    color: $base-font-colour;
    margin-left: 2px;
}

/* Shortcuts dialog */
.kb-dialog {
    width: 340px;
    .dialog-body {
        text-align: center;
    }
    &.kb-disabled {
        .dialog-body {
            &,
            [class^="icon-"]:before,
            [class*=" icon-"]:before {
                color: $shortcut-dialog-disabled !important;
            }
        }
    }
}
.kb-dialog-toggle {
    @include link;
    cursor: pointer;
}
.kb-dialog-table {
    margin: 20px auto 10px;
}
.kb-dialog-icon,
.kb-dialog-key,
.kb-dialog-description {
    padding-bottom: 3px;
}
.kb-dialog-icon-key {
    text-align: right;
    [class^="icon-"] {
        margin-right: 10px;
    }
}
.kb-dialog-section {
    .kb-dialog-icon,
    .kb-dialog-key,
    .kb-dialog-icon-key,
    .kb-dialog-description {
        padding-top: 15px;
    }
}
.kb-dialog-icon {
    text-align: right;
    padding-right: 15px;
    [class^="icon-"]:before,
    [class*=" icon-"]:before {
        font-size: 15px;
        color: $base-font-colour;
    }
}
.kb-dialog-join {
    padding-left: 5px;
    padding-right: 5px;
    color: $centre-panel-keyboard-dialog-join;
}
.kb-key {
    @include kb-shortcut-key(18px);
    &.kb-key-mod {
        width: 22px;
    }
}
.kb-dialog-description {
    padding-left: 15px;
    text-align: left;
}
