/* Print */
.print-only {
    display: none;
}

@media print {
     html, body {
          background: transparent !important;
     }
     .col {
          -webkit-column-break-inside: avoid;
          page-break-inside: avoid;
          break-inside: avoid;
     }
     .main-nav {
          display: none;
     }
     .main-content,
     .secondary-nav {
          position: static;
          background: transparent !important;
     }
     .secondary-nav {
          height: auto !important;
          font-size: 16px;
          border-bottom: none !important;
          overflow: hidden;
          .nav-item {
               height: auto !important;
          }
     }
     .main-content {
          padding-top: 20px;
     }
     .button {
          background: transparent !important;
          border: none !important;
     }
     .has-dropdown,
     .is-select {
          &:after {
               display: none !important;
          }
     }
     .print-title {
          margin-left: 20px;
     }
     .print-only {
          display: block !important;
     }
     .print-comparison-range {
          margin-left: 28px;
          clear: both;
          font-size: 14px;
     }
     .hide-print {
          display: none !important;
     }
     .widget {
          border: none !important;
     }
     .widget-title {
          font-weight: bold;
          background: transparent !important;
     }
}
