/* History transitions */
.conversation-panel.show-back-from-older {
    .conversation-header {
        top: -93px;
        z-index: 1;
    }
    &.has-notification .conversation-header {
        top: -134px;
    }
    .conversation-panel-body {
        top: 0px;
        padding-top: 96px;
    }
    &.has-notification .conversation-panel-body {
        top: 0px;
        padding-top: 138px;
    }
}
.conversation-panel.more-recent-open {
    .recent-history-wrapper {
        display: block;
    }
    .conversation-header {
        @include transition-duration(0 !important);
        visibility: hidden;
        top: -93px;
        z-index: 1;
    }
    &.has-notification .conversation-header {
        top: -134px;
    }
    .conversation-panel-body {
        @include transition-duration(0 !important);
        top: 0px;
        /*padding-top: 91px;*/
    }
    &.has-notification .conversation-panel-body {
        top: 0px;
        /*padding-top: 132px;*/
    }
}
.conversation-panel.no-animation {
    .conversation-header,
    .conversation-panel-body {
        @include transition-duration(0);
    }
}
.recent-history-wrapper {
    display: none;
}
.conversation-panel.multiple-selected {
    .conversation-header {
        display: none;
    }
    .multiple-conversations,
    .multiple-conversation-header {
        display: block;
    }
    .conversation-panel-body {
        top: 55px;
    }
    .single-conversation-wrapper {
        display: none;
    }
}

.conversation-toggle-log,
.conversation-history-marker.recent-marker,
.conversation-history-marker.older-marker {
    .conversation-panel:not(.no-animation) & {
        @include transition(250ms opacity ease-out);
    }
    .conversation-panel.more-recent-open &,
    .conversation-panel.older-history-open & {
        opacity: 0;
    }
}
.conversation-history.older {
    display: none;
    .conversation-panel.older-history-open & {
        display: block;
    }
}
.conversation-panel.show-back-from-recent {
    .conversation-dummy-header {
        background: $grey-bg-1;
    }
}
.conversation-history-header.back-from-recent {
    top: -56px;
    .conversation-panel.show-back-from-recent & {
        top: 0px;
    }
}
.conversation-history-header.back-from-older {
    top: -56px;
    .conversation-panel.show-back-from-older & {
        top: 0px;
    }
}

/* Conversation */
.multiple-conversation-header,
.conversation-header {
    background: $white;
    position: absolute;
    border-top: 1px solid $border-2;
    left: $centre-panel-gutter + 1px;
    right: $centre-panel-gutter + 1px;
    z-index: 2;
    top: 0px;
    .conversation-panel:not(.no-animation) & {
        @include transition(250ms top ease-out);
    }
}
.conversation-dummy-header {
    background: $white;
}

.conversation-toolbar {
    padding: 11px 0px;
    margin-left: 12px;
    margin-right: 12px;
    /* z-index 2 so it's above the section tag in meta subheader */
    position: relative;
    z-index: 2;
}
/* Override for assign/tag ko bindings, have to be wrapped in dropdown twice */
.dropdown.dropdown-open-override {
    .dropdown-menu {
        display: block !important;
    }
}
.conversation-title {
    @include ellipsis;
    vertical-align: middle;
    color: $base-font-colour;
    font-size: 15px;
    height: 31px;
    line-height: 15px;
    &.multiple-title {
        line-height: 29px;
    }
    .profile-picture {
        width: 29px;
        height: 29px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 6px;
    }
    .author-name {
        display: block;
        font-weight: bold;
        font-weight: 500;
        &:before {
            display: inline-block;
            text-decoration: none !important;
        }
    }
    a:hover {
        text-decoration: none;
        span {
            text-decoration: underline;
        }
        .screen-name {
            text-decoration: none;
        }
    }
    .screen-name {
        font-weight: normal;
        font-size: 14px;
        color: $button-text;
    }
    .channel-name {
        font-size: 12px;
        color: $base-font-colour;
    }
    .join {
        color: $button-text;
        font-size: 12px;
    }
    &.multiple-title:before {
        margin-right: 6px;
    }
}
.conversation-author-name {
    color: $base-font-colour;
}

.conversation-meta {
    @include group;
    @include ellipsis;
    position: relative;
    border-top: 1px solid $border-2;
    padding: 10px 0px;
    /* Border instead of padding so that meta doesn't overflow
       into the right padding area */
    border-left: solid 12px $grey-bg-2;
    border-right: solid 12px $grey-bg-2;
    background: $grey-bg-2;
    .meta-right {
        position: relative;
        z-index: 1;
        float: right;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: -20px;
            width: 20px;
            height: 21px;
            @include fade-end($grey-bg-2);
        }
    }
    .debug-link {
        display: none;
    }
    &:hover .debug-link {
        display: inline-block;
    }
}
.conversation-notification {
    display: none;
    .conversation-panel.has-notification & {
        display: block;
    }
    line-height: 17px;
    padding: 11px 10px 12px;
    &:before {
        font-size: 16px;
        vertical-align: top;
        margin-right: 5px !important;
    }
}
.conversation-panel-body {
    .conversation-panel:not(.no-animation) & {
        @include transition(250ms padding-top ease-out, 250ms top ease-out);
    }
    @include abs-height-100(96px);
    padding-top: 0px;
    .conversation-panel.has-notification & {
        @include abs-height-100(137px);
        bottom: 27px;
    }
    left: $centre-panel-gutter;
    right: $centre-panel-gutter;
    border-left: 1px solid $border-1;
    border-right: 1px solid $border-1;
    bottom: 27px;
    overflow-y: auto;
    background-color: $grey-bg-1;
}
.conversation-toggle-log {
    position: absolute;
    right: 25px;
    bottom: -32px;
    .button.show-log {
        opacity: 0.5;
        &:hover {
            opacity: 1;
        }
    }
    .button.hide-log {
        display: none;
    }
    .conversation-panel.show-log & .button.hide-log {
        display: block;
    }
    .conversation-panel.show-log & .button.show-log {
        display: none;
    }
}
.single-conversation {
    padding: 0px 10px 20px;
}
.closed-items {
    background: $white;
    border-top: 1px solid $green;
    border-bottom: 1px solid $green;
    left: 0;
    right: 0;
    margin-top: 30px;
    padding: 0px 10px 20px 10px;
    .closed-line {
        text-align: center;
        height: 0px;
        padding: 0px;
        .closed-indicator {
            display: inline-block;
            position: relative;
            top: -25px;
            padding: 5px 8px;
            color: $white;
            background-color: $green;
            font-size: 12px;
            font-weight: normal;
            @include border-radius(7px);
        }
    }
    .conversation-action-log {
        &:before {
            background-color: $green;
        }
        .action-log {
            background: $white;
            color: $green;
            strong {
                color: $green;
            }
        }
    }
    .close-archive {
        .conversation-action-log {
            margin-top: -25px;
            &:before {
                background-color: transparent;
            }
            .action-log {
                background: transparent;
                li.new-messages-indicator {
                    background: $green;
                    border: 1px solid $green;
                    color: $white;
                }
                strong {
                    color: $white;
                }
            }
        }
    }
}
.conversation-panel.show-log .closed-items {
    margin-top: 30px;
    .conversation-action-log {
        margin: 0;
    }
}
.conversation-segment {
    position: relative;
    padding-top: 30px;
    padding-right: 5px;
    padding-left: 5px;
    &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 3px;
        left: -4px;
        top: 0;
    }
    &.positive:before {
        background: $green;
    }
    &.neutral:before {
        background: $verint-blue;
    }
    &.negative:before {
        background: $red;
    }
}
.conversation-truncated {
    padding: 10px;
    margin: 20px 5px 0px;
    @include border-radius(3px);
}
.message {
    .profile-picture {
        position: absolute;
        left: 0px;
        top: 5px;
    }
    .message-bubble-wrap {
        float: left;
        position: relative;
        margin-left: 53px;
        max-width: 575px;
        @include max-width(1750px) {
            max-width: 65%;
        }
    }
    .message-bubble {
        position: relative;
        position: relative;
        padding: 15px;
        line-height: 18px;
        @include border-radius(3px);
        padding-right: 25px;
        word-wrap: break-word;
    }
    .message-duplicate-count {
        background-color: $orange;
        border-radius: 100px;
        bottom: -6px;
        color: $white;
        padding: 1px 6px;
        font-size: 11px;
        position: absolute;
        right: 5px;
    }
    .action-icon {
        position: absolute;
        font-size: 16px;
        top: 9px;
        left: 12px;
        &:before {
            margin: 0;
        }
    }
    .action-icon.icon-cancel-1 {
        left: 14px;
    }
    /* maybe put in can-expand class */
    .close-content {
        display: none;
    }
    /* hide approvals stuff unless specified to be open */
    .approval-actions,
    .edit-box,
    .reject-box {
        display: none;
    }
}
.message-meta {
    @include group;
    .tag {
        margin-bottom: 4px;
    }
    & + .message-text {
        margin-top: 6px;
    }
}
.tag.in-x-conversations {
    max-width: none;
    cursor: pointer;
    text-decoration: none;
}
.message-text {
    white-space: pre-wrap;
}
.message-attachments {
    @include group;
    margin-top: 15px;
    white-space: nowrap;
    overflow: hidden;
    .attachment-media {
        float: left;
    }
}
.attachment-image {
    @include border-radius(3px);
    max-height: 300px;
    max-width: 100%;
    display: block;
}
.attachment-sticker {
    @include border-radius(3px);
    max-height: 80px;
    max-width: 100%;
    display: block;
}
.message-attachment-link {
    .attachment-meta {
        max-width: 60%;
    }
    &.attachment-no-photo {
        .attachment-meta {
            margin-left: 0;
        }
    }
}
.message.has-multi-photo .message-bubble-wrap {
    width: 100%;
}
.message-attachment-multi-photo {
    margin-top: 10px;
}
.message-attachment-video {
    position: relative;
}
.message-attachment-quoted-tweet {
    white-space: normal;
    padding: 10px;
    @include border-radius(3px);
    border: 1px solid $border-1;
    background-color: $grey-bg-2;
    a {
        color: $base-font-colour;
        &:hover {
            text-decoration: none;
        }
    }
}
.attachment-tweet-author {
    color: $base-font-colour;
    font-weight: bold;
    font-weight: 500;
    .screen-name {
        color: $button-text;
        font-weight: normal;
    }
}
.attachment-tweet-text {
    margin-top: 2px;
    white-space: pre-wrap;
}
.attachment-video-image {
    @include border-radius(3px);
    max-height: 300px;
    max-width: 100%;
    .attachment-image-inner {
        display: block;
        height: 100%;
        width: 100%;
        @include border-radius(3px);
    }
}
.attachment-video-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    width: 100px;
    height: 100px;
    background: $black-000-alpha-60;
    color: $white;
    @include border-radius(50%);
    border: 3px solid $white;
    &:before {
        font-size: 72px;
        position: absolute;
        left: 35px;
        top: 10px;
        line-height: 76px;
    }
}
.attachment-multi-image {
    float: left;
    position: relative;
    width: 50%;
    &:before {
        content: "";
        display: block;
        padding-top: 100%;
    }
    .attachment-image-inner {
        @include border-radius(3px);
        position: absolute;
        top: 5px;
        left: 0;
        bottom: 0;
        right: 5px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}
.attachment-meta {
    float: left;
    white-space: normal;
    margin-left: 105px;
    .meta-link {
        font-size: 15px;
        line-height: 19px;
        margin-top: 3px;
        max-height: 37px;
        overflow: hidden;
    }
    .meta-description {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        max-height: 49px;
        font-size: 12px;
        line-height: 16px;
        overflow: hidden;
        margin-top: 6px;
        color: $button-text;
    }
}
.attachment-link-image {
    width: 95px;
    height: 95px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include border-radius(3px);
}
.message-body {
    @include group;
    position: relative;
}
.action-log {
    @include remove-list;
    clear: both;
    &, a {
        color: $button-text;
    }
    .new-messages-indicator, .closed-indicator {
        padding: 5px 8px;
        background-color: $white;
        border: 1px solid $border-1;
        @include border-radius(7px);
    }
    strong {
        font-weight: normal;
        color: $base-font-colour;
    }
    li {
        font-size: 12px;
        line-height: 18px;
        display: none;
    }
    li.new-messages-indicator,
    li.closed-indicator,
    li.persist {
        display: block;
    }
    .conversation-panel.show-log & li {
        display: block;
    }
    .conversation-panel.show-log & li.new-messages-indicator,
    .conversation-panel.show-log & li.closed-indicator {
        display: none;
    }
}
.conversation-segment.log-segment {
    display: none;
    &.persist {
        display: block;
    }
    .conversation-panel.show-log & {
        display: block;
    }
}
.conversation-action-log {
    &:before {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        content: "";
        height: 1px;
        background-color: $border-1;
        overflow: hidden;
    }
    position: relative;
    text-align: center;
    .action-log {
        display: inline-block;
        position: relative;
        background: $grey-bg-1;
        .action {
            padding: 0 10px;
        }
    }
}
.message {
    .action-log {
        margin-top: 6px; /* effectively 10px after line-height */
    }
    .message-menu {
        display: none;
    }
    .message-menu.working {
        display: block;
        .has-dropdown:after {
            content: url('../../images/conv-spinner-small.gif');
            width: 16px;
            height: 16px;
            right: 5px;
        }
    }
    .message-bubble:hover .message-menu {
        display: block;
    }
    .typing-indicator {
        display: none;
    }
    .message-shortcuts {
        display: none;
        float: left;
        position: absolute;
        right: -44px;
        &.has-sentiment {
            right: -123px;
        }
        top: 0;
        .button {
            padding: 3px;
            background: transparent;
            &:hover {
                background: $white;
            }
            &:before {
                font-size: 14px;
            }
            &:after,
            &:before {
                color: $button-text;
            }
            &:hover {
                &:after,
                &:before {
                    color: $base-font-colour;
                }
            }
        }
        .button.has-dropdown {
            padding-right: 16px;
            &:after {
                right: 4px;
            }
        }
    }
    .message-shortcuts.tag-open,
    &:hover .message-shortcuts {
        display: block;
    }
}
.message-menu {
    position: absolute;
    right: 0px;
    top: 12px;
    .has-dropdown {
        cursor: pointer;
        padding: 5px;
        min-width: 27px;
        min-height: 24px;
    }
    &:after {
        margin-left: 0;
    }
    &.dropdown-open {
        display: block;
    }
    .dropdown-menu {
        max-height: none;
        overflow: visible !important;
    }
}
.message.agent-note {
    .message-bubble-wrap {
        float: right;
        margin-left: 0;
        margin-right: 53px;
    }
    .message-bubble {
        background: $yellow-pale;
        color: $base-font-colour;
        border: 1px solid $border-1;
    }
    .action-log {
        margin-right: 69px;
        li {
            text-align: right;
        }
    }
}
.message.agent-message {
    .message-bubble {
        background: $highlight;
        border: 1px solid $border-1;
        position: relative;
    }
    .tag:not(.public):not(.private):not(.deleted-message):not(.hidden-message):not(.send-private-message) {
        background: $legacy-conversations-agent-message-tag;
        border-color: transparent;
        color: $legacy-conversations-agent-message-working;
    }
    .message-menu.working  {
        .has-dropdown:after {
                content: url('../../images/conv-spinner-small.gif');
        }
    }
}
.message.agent-message {
    .message-bubble-wrap {
        float: right;
        margin-right: 53px;
        margin-left: 0;
    }
    .action-log {
        margin-right: 69px;
        li {
            text-align: right;
        }
    }
    .profile-picture {
        position: absolute;
        left: auto;
        right: 0px;
    }

    /* right arrow */
    .message-bubble:after, .message-bubble:before {
        left: 100%;
        top: 23px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .message-bubble:after {
        border-color: transparent;
        border-left-color: $highlight;
        border-width: 10px;
        margin-top: -10px;
    }
    .message-bubble:before {
        border-color: transparent;
        border-left-color: $border-1;
        border-width: 11px;
        margin-top: -11px;
    }
}
.message.escalated-message {
    .message-bubble {
        @include border-bottom-left-radius(0);
        @include border-bottom-right-radius(0);
    }
    .message-bubble + .message-escalation-note {
        border-top: none;
        @include border-top-left-radius(0);
        @include border-top-right-radius(0);
    }
    .action-icon {
        top: 14px;
    }
    .message-escalation-note {
        position: relative;
        border: 1px solid  $border-1;
        background: $grey-bg-2;
        @include border-radius(3px);
        padding: 15px 10px 15px 38px;
        line-height: 18px;
    }
}
.message.pending-approval,
.message.not-approved {
    .message-bubble {
        @include border-bottom-left-radius(0);
        @include border-bottom-right-radius(0);
    }
    .approval-actions {
        @include ellipsis;
        position: relative;
        line-height: 28px;
        border: 1px solid  $border-1;
        background: $grey-bg-2;
        padding: 10px 10px 10px 36px;
        @include border-radius(3px);
        .toolbar-right {
            margin-left: 10px;
        }
    }
    &.pending-approval,
    &.is-expanded {
        .message-bubble + .approval-actions {
            border-top: none;
        }
    }
    .approval-actions {
        display: block;
    }
}
.message.pending-approval {
    .approval-actions {
        padding-bottom: 12px;
    }
    &.agent-message .message-menu {
        display: none !important;
    }
    .reject-box,
    .edit-box {
        display: none;
    }
    &.editing {
        .message-bubble-wrap {
            > .toolbar,
            > .message-bubble {
                /* Use visibility hidden and reduce to zero height as we want
                   to keep the container the same width, otherwise edit box
                   is a different size to message box */
                visibility: hidden;
                height: 0;
                padding-top: 0;
                padding-bottom: 0;
                border-top: none;
                border-bottom: none;
                overflow: hidden;
            }
        }
        .edit-box {
            display: block;
        }
    }
    &.rejecting {
        .approval-actions {
            /* Use visibility hidden and reduce to zero height as we want
               to keep the container the same width, otherwise reject box
               is a different size to message box */
            visibility: hidden;
            padding-top: 0;
            padding-bottom: 0;
            height: 0;
        }
        .reject-box {
            display: block;
        }
    }
    .edit-bubble {
        width: 100%;
    }
    .conversation-segment:not(.conversation-approval) & .reject-box {
        border-top: none;
        @include border-top-left-radius(0);
        @include border-top-right-radius(0);
    }
    .reject-box {
        &:not(.conversation-action) {
        }
        .button.invisible {
            cursor: auto;
        }
    }
}
.last-archive ~ .segment-not-approved,
.message.not-approved.is-expanded,
.message.pending-approval {
    .message-bubble + .approval-actions {
        @include border-top-left-radius(0px);
        @include border-top-right-radius(0px);
    }
    .approval-actions {
        @include border-bottom-left-radius(0);
        @include border-bottom-right-radius(0);
    }
}
.message.not-approved {
    .approval-actions {
        @include user-select(none);
        cursor: pointer;
        &:hover .message-expand-toggle:before {
            color: $verint-blue;
        }
    }
    .message-menu {
        display: none !important;
    }
    .not-approved-reason {
        position: relative;
        padding: 10px 10px 10px 32px;
        line-height: 18px;
        min-height: 39px;
        border: 1px solid  $border-1;
        background: $white;
        white-space: pre-wrap;
        border-top: none;
        @include border-bottom-left-radius(3px);
        @include border-bottom-right-radius(3px);
    }
    .approval-actions {
        padding-right: 48px;
    }
    .message-expand-toggle {
        position: absolute;
        right: 13px;
        top: 13px;
    }

    /* Handle approval expand/collapse */
    .action-log,
    .message-bubble,
    .not-approved-reason,
    .profile-picture {
        display: none;
    }
    &.is-expanded {
        .action-log,
        .message-bubble,
        .not-approved-reason,
        .profile-picture {
            display: block;
        }
    }
}
/* Message being typed */
.message.is-typing {
    .typing-indicator {
        display: block;
        margin-top: -12px;
        margin-left: -15px;
        html.cssanimations & {
            margin-top: -7px;
            margin-left: -26px;
        }
    }
    .message-meta,
    .message-menu,
    .message-text,
    .action-log {
        display: none !important;
    }
    .message-bubble {
        width: 100px;
        height: 50px;
    }
}
/* Expand approvals after last archive automatically */
.no-archives .segment-not-approved,
.no-archives .segment-not-approved .message.is-expanded,
.last-archive ~ .segment-not-approved .message.is-expanded,
.last-archive ~ .segment-not-approved {
    .close-content {
        display: none;
    }
    .expand-content {
        display: none;
    }
    .contracted-text {
        display: none;
    }
    .action-log,
    .message-bubble,
    .not-approved-reason,
    .profile-picture {
        display: block;
    }
    .approval-actions {
        cursor: auto;
    }
    .message-bubble + .approval-actions {
        border-top: none;
    }
}
.message-expand-toggle {
    @include border-radius(3px);
    cursor: pointer;
    background: $white;
    text-align: center;
    line-height: 20px;
    width: 22px;
    height: 22px;
    /* Use full icon- selector to override top level class properly */
    &[class^="icon-"]:before,
    &[class*=" icon-"]:before {
        margin-right: 0px !important;
    }
    &:before {
        font-size: 16px;
    }
    &:hover:before {
        color: $verint-blue;
    }
}
.message.is-expanded {
    .close-content {
        display: block;
    }
    .expand-content {
        display: none;
    }
    .contracted-text {
        display: none;
    }
}
.message.messages-by-others {
    .message-content {
        display: none;
    }
    .message-by-other:first-child {
        margin-top: 10px;
    }
    &.is-expanded {
        .message-content {
            display: block;
        }
    }
    &.is-first-item {
        .message-meta {
            cursor: auto;
        }
        .message-expand-toggle {
            display: none;
        }
    }
    .message-meta {
        @include user-select(none);
        position: relative;
        cursor: pointer;
        &:hover .message-expand-toggle {
            color: $verint-blue !important;
        }
    }
    .message-expand-toggle {
        position: absolute;
        @include border-radius(3px);
        right: -5px;
        top: -2px;
        &.working {
            &:before {
                content: none;
            }
            background-image: url('../../images/conv-spinner-small.gif');
            background-repeat: no-repeat;
            background-position: 3px 3px;
        }
    }
    .profile-picture {
        line-height: 38px;
        text-align: center;
        top: 0px;
        &:before {
            font-size: 20px;
            margin-right: 0;
            color: $base-font-colour;
        }
    }
    .message-bubble {
        background: $grey-bg-2;
        border: 1px solid $border-1;
        padding: 10px 15px;
    }
    .message-text {
        margin-right: 25px;
    }
    .message-text:before {
        margin-right: 5px;
        color: $base-font-colour;
    }
    /* Left arrow */
    .message-bubble:after, .message-bubble:before {
        right: 100%;
        top: 19px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .message-bubble:after {
        border-color: transparent;
        border-right-color: $grey-bg-2;
        border-width: 10px;
        margin-top: -10px;
    }
    .message-bubble:before {
        border-color: transparent;
        border-right-color: $border-1;
        border-width: 11px;
        margin-top: -11px;
    }
}
.message-by-other {
    margin-top: 15px;
    line-height: 18px;
    position: relative;
    .profile-picture {
        width: 25px;
        height: 25px;
        position: absolute;
        left: 0px;
        top: 5px;
    }
    .mbo-body {
        margin-left: 35px;
    }
    .mbo-author {
        color: $base-font-colour;
        font-weight: bold;
    }
    .mbo-meta {
        color: $button-text;
    }
    .mbo-text {
        white-space: pre-wrap;
    }
}
.message.customer-message {
    .message-bubble {
        background: $white;
        border: 1px solid  $border-1;
    }
    .action-log {
        margin-left: 69px;
        li {
            position: relative;
            text-align: left;
            &:before {
                position: absolute;
                left: -15px;
                color: $button-text;
            }
            &.icon-thumbs-up:before {
                left: -15px;
            }
            &.icon-star-1:before {
                left: -15px;
            }
            &.icon-tag-1:before {
                left: -15px;
            }
            &.icon-reply-1:before {
                left: -14px;
            }
            &.icon-retweet-1:before {
                left: -16px;
            }
            &.icon-positive:before {
                left: -18px;
            }
            &.icon-neutral:before {
                left: -18px;
            }
            &.icon-negative:before {
                left: -18px;
            }
            &.icon-trash-1:before {
                left: -14px;
            }
        }
    }

    /* left arrow */
    .message-bubble:after, .message-bubble:before {
        right: 100%;
        top: 23px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .message-bubble:after {
        border-color: transparent;
        border-right-color: $white;
        border-width: 10px;
        margin-top: -10px;
    }
    .message-bubble:before {
        border-color: transparent;
        border-right-color: $border-1;
        border-width: 11px;
        margin-top: -11px;
    }
}
.message.hidden-message,
.message.deleted-message {
    &.customer-message .message-bubble {
        background: $grey-bg-2;
    }
    &.agent-message .message-bubble {
        background: $light-blue-grey-background;
        border-color: $light-blue-grey-border;
    }
    /* Left arrow */
    &.customer-message .message-bubble:after,
    &.customer-message .message-bubble:before {
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    &.customer-message .message-bubble:after {
        border-color: transparent;
        border-right-color: $grey-bg-2;
        border-width: 10px;
        margin-top: -10px;
    }
    &.customer-message .message-bubble:before {
        border-color: transparent;
        border-right-color: $border-1;
        border-width: 11px;
        margin-top: -11px;
    }

    /* Right arrow */
    &.agent-message .message-bubble:after,
    &.agent-message .message-bubble:before {
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    &.agent-message .message-bubble:after {
        border-color: transparent;
        border-left-color: $light-blue-grey-background;
        border-width: 10px;
        margin-top: -10px;
    }
    &.agent-message .message-bubble:before {
        border-color: transparent;
        border-left-color: $light-blue-grey-border;
        border-width: 11px;
        margin-top: -11px;
    }
}
.message.deleted-message {
    .message-text {
        color: $button-text;
        text-decoration: line-through;
    }
    .message-menu {
        display: none !important;
    }
}

.conversation-history-marker {
    text-align: center;
    .button {
        position: relative;
        display: inline-block;
        &, &:before {
            color: $button-text;
        }
        &:hover {
            background: $white;
            color: $base-font-colour;
        }
        &.disabled {
            background: $grey-bg-2;
            border-color:  $border-1;
        }
        background: $grey-bg-2;
        font-size: 12px;
        line-height: 18px;
        padding: 8px 10px;
    }

    position: relative;
    .loading-spinner {
        display: none;
    }
    &.loading {
        .button span {
            opacity: 0.1;
        }
        .loading-spinner {
            display: block;
        }
    }
    .button span:before {
        font-size: 16px;
        vertical-align: middle;
        display: inline-block;
    }
}
/* arrow animations */
@include keyframes(point-up) {
    0%, 20%, 50%, 60%, 100% {
    top: 0px;
  }
    40%, 80% {
    top: -3px;
  }
}
@include keyframes(point-down) {
    0%, 20%, 50%, 60%, 100% {
    top: 0px;
  }
    40%, 80% {
    top: 3px;
  }
}
.conversation-history-marker.recent-marker {
    position: absolute;
    bottom: -39px;
    width: 100%;
    .button {
        @include border-top-left-radius(0);
        @include border-top-right-radius(0);
        border-left: none;
        border-right: none;
        border-bottom: none;
        &:not(.disabled) active {
            border-top-color: $white;
        }
    }
    .button:hover .icon-up-thin:before {
        position: relative;
        @include animation(point-up ease 1.5s);
    }
}
.conversation-history-marker.older-marker {
    margin-top: 50px;
    & + .reply-box {
        margin-top: 20px;
    }
    .button:hover .icon-down-thin:before {
        position: relative;
        @include animation(point-down ease 1.5s);
    }
}

.twitter-autocomplete.dropdown-menu {
    li, span {
        max-width: none;
    }
}

/* Multiple selection */
.multiple-conversations,
.multiple-conversation-header {
    display: none;
}
.multiple-conversations {
    font-size: 12px;
    padding: 20px;
    .conversation-small-conversation {
        margin-bottom: 30px;
    }
    .loading-spinner {
        position: static;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    &.loading .loading-spinner {
        display: block;
    }
}

/* CRM wh */
.integration-incident-push {
    text-align: center;
    margin-top: 30px;
    .integration-incident-details {
        margin-top: 6px;
        color: $button-text;
        font-size: 12px;
    }
}

.jumping-dots span {
  position: relative;
  bottom: 0px;
  -webkit-animation: jump 1500ms infinite;
  animation: jump 2s infinite;
}
.jumping-dots .dot-1{
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms;
}
.jumping-dots .dot-2{
  -webkit-animation-delay: 400ms;
  animation-delay: 400ms;
}
.jumping-dots .dot-3{
  -webkit-animation-delay: 600ms;
  animation-delay: 600ms;
}

@-webkit-keyframes jump {
  0%   {bottom: 0px;}
  20%  {bottom: 5px;}
  40%  {bottom: 0px;}
}

@keyframes jump {
  0%   {bottom: 0px;}
  20%  {bottom: 5px;}
  40%  {bottom: 0px;}
}
