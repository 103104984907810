.small-tag {
    display: inline-block;
    line-height: 17px;
    font-size: 11px;
    padding: 0px 3px;
    vertical-align: top;
    margin-right: 4px; /* Effectively 7px as inline-block */
    min-width: 18px;
    height: 18px;
    max-width: 80px;
    @include border-radius(3px);

    &.priority {
        padding: 0px 2px;
    }
    &.neutral-sentiment {
        padding: 0;
    }
}

.tag,
.small-tag {
    background-color: $grey-bg-2;
    border: 1px solid $grey-bg-2;
    color: $base-font-colour;
    text-align: left;
    @include ellipsis;
    &:empty:before {
        margin: 0;
    }
}

.tag {
    display: inline-block;
    max-width: 125px;
    position: relative;
    line-height: 20px;
    font-size: 12px;
    padding: 0px 4px;
    vertical-align: top;
    margin-right: 7px; /* Effectively 7px as inline-block */
    min-width: 21px;
    height: 21px;
    @include border-radius(3px);
    &[class^="icon-"],
    &[class*=" icon-"] {
        &:not(:empty):before {
            margin-right: 4px;
        }
    }
    .delete-tag {
        position: absolute;
        right: 0;
        top: 0;
        display: none;
        margin-left: 1px;
        padding-left: 3px;
        padding-right: 1px;
        cursor: pointer;
        color: $button-text;
        @include border-top-right-radius(2px);
        @include border-bottom-right-radius(2px);
        &:hover {
            background: $verint-blue;
            &:before {
                color: $white;
            }
        }
    }
    &.can-delete {
        padding: 0 8px;
    }
    &.neutral-sentiment {
        padding: 0 1px 0 0;
    }
    &.tag-error {
        background-color: $orange;
        border-color: $orange-border;
        color: $white;
        .delete-tag:hover {
            background: $legacy-tag-error;
        }
    }
}

.allow-tag-delete:hover {
    &.tag.can-delete,
    .tag.can-delete {
        padding: 0 13px 0 3px;
        .delete-tag {
            display: block;
        }
    }
}
