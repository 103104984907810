/* Left column */
@mixin left-col-width($screen-width, $col-width) {
    @include max-width($screen-width) {
        .conversation-list-panel {
            width: $col-width;
        }
        .conversation-container {
            margin-left: $col-width;
            width: 100% - $col-width;
        }
    }
}
@include left-col-width(1270px, 25.5%);
@include left-col-width(1245px, 26.0%);
@include left-col-width(1220px, 26.5%);
@include left-col-width(1200px, 27.0%);
@include left-col-width(1175px, 27.5%);

/* Remove sort order icon when below 1150px */
@include max-width(1150px) {
    .sort-order-select .is-select:before {
        display: none;
    }
}
/* Contrain width at very narrowest */
@include max-width(1080px) {
    .sort-order-select .is-select {
        text-align: left;
        max-width: 108px;
    }
}
/* Shorten action required text below 1200px */
@include collapse-text(1200px, channel-notifications-toggle);
/* Only show scree-name in conversation header below 1200px */
@include max-width(1200px) {
    .conversation-header .author-name .full-name-collapsible {
        display: none;
    }
    .conversation-header .author-name .screen-name {
        color: inherit;
        font-weight: inherit;

        &:hover {
            text-decoration: underline;
        }

        /* A space character is rendered between spans, this adjusts to remove the
           space as full-name is now hidden */
        position: relative;
        left: -5px;
    }
}
/* Shorten inbox label when below 1100px */
@include max-width(1100px) {
    .inbox-dropdown .section-name {
        max-width: 175px;
    }
}

/* Reply box margin at bigger widths, also
   make sure Clipboard is next to it */
@include min-width(1550px) {
    .reply-box {
        margin-left: 57px;
        margin-right: 57px;
    }
    .clipboard-container {
        margin-left: -78px;
        width: 45%;
        &.bottom-left-version {
            margin-left: 65px;
        }
    }
}

/* Reduce space between reply box action buttons at smaller screen sizes */
@include max-width(1100px) {
    .reply-box-actions .toolbar {
        > .button,
        .toolbar-text,
        .select-container,
        .button-group {
            float: left;
            display: block;
            margin-left: 3px;
            margin-right: 3px;
        }
    }
}

/* Reply box channel selector */
@include set-max-width-at(1270px, reply-box-channel-selector, 170px);
@include set-max-width-at(1245px, reply-box-channel-selector, 150px);
@include set-max-width-at(1215px, reply-box-channel-selector, 130px);
@include max-width(1215px) {
    .reply-box-channel-selector {
        &:before {
            display: none;
        }
    }
}
@include set-max-width-at(1125px, reply-box-channel-selector, 110px);
@include set-max-width-at(1160px, reply-box-channel-selector, 86px);

/* Twitter reply as text */
@include collapse-text(1190px, reply-as-text);
@include hide-text(1085px, reply-as-text);

/* Other reply as text width */
@include set-max-width-at(1245px, reply-as-text, 260px);
@include set-max-width-at(1190px, reply-as-text, 225px);
@include set-max-width-at(1170px, reply-as-text, 205px);
@include set-max-width-at(1095px, reply-as-text, 165px);
