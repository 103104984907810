.dialog-body {
    .edit-reply-users {
        @include border-radius(5px);
        border: 1px solid $border-1;

        .edit-reply-users-select-all {
            background: $white;
            border-bottom: 1px solid $border-1;
            padding: 15px 40px;
        }
    }

    .user-mentions-list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        // This height is set to allow 7 rows without a scrollbar
        max-height: 225px;
        overflow: auto;
        padding-bottom: 10px;
        padding-top: 10px;
        width: 400px;

        li {
            flex-basis: 50%;
            line-height: 25px;
        }
    }
}
