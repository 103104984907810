@use '~compass-mixins/lib/compass/css3.scss';
@use '../../styles/fonts/fontello/css/fontello-codes.scss';

/* Typography */
.text-right {
    text-align: right;
}
.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}

/* Fonts */
@mixin web-font($name, $filename) {
    $stub: '#{$filename}/#{$name}';
    @font-face {
        font-family: $name;
        src: url('#{$stub}.eot');
        src: url('#{$stub}.eot?#iefix') format('embedded-opentype'),
            url('#{$stub}.woff2') format('woff2'),
            url('#{$stub}.woff') format('woff'),
            url('#{$stub}.ttf') format('truetype'),
            url('#{$stub}.svg#' + $name) format('svg');
        font-style: normal;
        font-weight: normal;
    }
}

@include web-font('fontello', '../../styles/fonts/fontello/font');

/* Override fontello css */
[class^='icon-']:before,
[class*=' icon-']:before {
    font-family: 'fontello';
    font-style: normal;
    font-weight: normal;
    speak: none;

    /*display: inline-block;*/
    text-decoration: inherit;
    /*width: 1em;*/
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    /*line-height: 1em;*/

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /*margin-left: .2em;*/

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.hover-green:hover:before {
    color: $legacy-font-green-hover-icon !important;
}
.hover-red:hover:before {
    color: $legacy-font-red-hover-icon !important;
}
.icon-positive:before {
    content: '\e820';
    width: 16px;
}
.icon-neutral:before {
    content: '\e821';
    @include transform(rotate(90deg) translate(0, 0.15em));
    margin-left: -2px;
    display: inline-block;
    width: 16px;
}
.icon-negative:before {
    content: '\e821';
    width: 16px;
}
.icon-assign:before {
    content: '\e807';
    width: 16px;
}
.icon-archive:before {
    width: 16px;
}
.icon-twitter-search:before {
    font-family: 'fontello';
    content: '\e801';
}
.icon-instagram_ads:before {
    font-family: 'fontello';
    content: '\e776';
}
.conversation-container .icon-twitter-search{
    @extend .icon-twitter-1;
    &:before {
        font-family: 'fontello';
        color: $twitter-blue;
    }
}

.icon-all:before {
    @include transform(scale(-1, 1));
    content: '\e8c3';
}
.icon-delete:before {
    font-family: 'fontello';
    content: '\e82e' !important;
}
.icon-delete.icon-filled:before {
    color: $red;
}
.icon-reply:before {
    font-family: 'fontello';
    content: '\e712';
}
.icon-note:before {
    font-family: 'fontello';
    content: '\e8d1';
}
.icon-forward:before {
    font-family: 'fontello';
    content: '\e824' !important;
}

/* Platform icons */
.icon-facebook {
    @extend .icon-facebook-squared-1;
    &:before {
        font-family: 'fontello';
        color: $facebook-blue;
    }
}
.icon-twitter {
    @extend .icon-twitter-1;
    &:before {
        font-family: 'fontello';
        color: $twitter-blue;
    }
}
.icon-youtube {
    @extend .icon-youtube-play;
    &:before {
        font-family: 'fontello';
        color: $red;
    }
}
.icon-instagram {
    @extend .icon-instagram;
    &:before {
        font-family: 'fontello';
        color: $facebook-blue;
    }
}
.icon-googleplus {
    @extend .icon-gplus-squared;
    &:before {
        font-family: 'fontello';
        color: $google-plus-red;
    }
}
.icon-twitter-search {
    @extend .icon-search-1;
    &:before {
        font-family: 'fontello';
        color: $button-text;
    }
}
.icon-sms {
    @extend .icon-sms;
    &:before {
        font-family: 'fontello';
    }
}
.icon-synthesio {
    @extend .icon-synthesio;
    &:before {
        font-family: 'fontello';
        color: $synthesio-red;
    }
}
.icon-googleplaystore {
    @extend .icon-google-play;
    &:before {
        font-family: 'fontello';
    }
}
.icon-instagram_ads {
    @extend .icon-instagram;
    &:before {
        font-family: 'fontello';
        color: $facebook-blue;
    }
}
.icon-wechat:before {
    color: $wechat-green;
}
.icon-whatsapp:before,
.icon-whatsapp_gateway:before {
    color: $whatsapp-green;
}
.icon-cheepr {
    @extend .icon-rocket;
    &:before {
        color: $red;
    }
}
.icon-abc {
    @extend .icon-comment;
    &:before {
        font-family: 'fontello';
        color: $abc-blue;
    }
}
.icon-public {
    @extend .icon-globe-1;
    &:before {
        font-family: 'fontello';
    }
}
.icon-private {
    @extend .icon-lock;
    &:before {
        font-family: 'fontello';
    }
}
.icon-agent {
    @extend .icon-user-1;
    &:before {
        font-family: 'fontello';
    }
}
.icon-team {
    @extend .icon-users-1;
    &:before {
        font-family: 'fontello';
    }
}
.icon-location {
    @extend .icon-globe-1;
    &:before {
        font-family: 'fontello';
    }
}
.icon-website {
    @extend .icon-network;
    &:before {
        font-family: 'fontello';
    }
}
.icon-search {
    @extend .icon-search-1;
    &:before {
        font-family: 'fontello';
    }
}
.icon-play {
    @extend .icon-play;
    &:before {
        font-family: 'fontello';
    }
}
.icon-print {
    @extend .icon-print-1;
    &:before {
        font-family: 'fontello';
    }
}
.icon-calendar {
    @extend .icon-calendar-empty;
    &:before {
        font-family: 'fontello';
    }
}
.icon-convo {
    @extend .icon-chat;
    &:before {
        font-family: 'fontello';
    }
}
.icon-configure {
    @extend .icon-wrench;
    &:before {
        font-family: 'fontello';
    }
}
.icon-warning {
    @extend .icon-attention-1;
    &:before {
        font-family: 'fontello';
    }
}
.icon-mail {
    @extend .icon-mail-1;
    &:before {
        font-family: 'fontello';
    }
}
.icon-twitter-like {
    @extend .icon-heart-1;
    &:before {
        font-family: 'fontello';
    }
}
.icon-twitter-like.icon-filled:before {
    color: $twitter-like-red;
}
.icon-retweet.icon-filled:before {
    color: $green-light;
}
.icon-like {
    @extend .icon-thumbs-up;
    &:before {
        font-family: 'fontello';
    }
}
.icon-like.icon-filled:before {
    color: $verint-blue;
}
.icon-undoretweet {
    @extend .icon-retweet;
    &:before {
        font-family: 'fontello';
    }
}
.icon-spanner {
    @extend .icon-wrench;
    &:before {
        font-family: 'fontello';
    }
}
h2 span {
    &[class^='icon-']:before,
    &[class*=' icon-']:before {
        color: $border-1;
    }
}
.filled {
    &.icon-all:before {
        color: $base-font-colour !important;
    }
    &.icon-twitter:before {
        color: $twitter-blue !important;
    }
    &.icon-facebook:before {
        color: $facebook-blue !important;
    }
    &.icon-youtube:before {
        color: $legacy-button-youtube !important;
    }
    &.icon-googleplus:before {
        color: $google-plus-red !important;
    }
    &.icon-instagram:before,
    &.icon-instagram_ads:before {
        color: $instagram-blue !important;
    }
    &.icon-synthesio:before {
        color: $synthesio-red !important;
    }
}

.icon-up-big:before {
    font-family: 'fontello';
    content: '\e90d';
}

.icon-down-big:before {
    font-family: 'fontello';
    content: '\e90a';
}

.icon-right-big:before {
    font-family: 'fontello';
    content: '\e90c';
}

.icon-csat-change-up:before {
    @extend .icon-up-big;
    color: $legacy-font-csat-change-up-icon;
}

.icon-csat-change-down:before {
    @extend .icon-down-big;
    color: $legacy-font-csat-change-down-icon;
}

.icon-csat-no-change:before {
    @extend .icon-right-big;
    color: $legacy-font-csat-no-change-icon;
}

.icon-info-circled {
    @extend .icon-info-circled;
}
