/*
 *  From http://http://www.responsivegridsystem.com/
 *  * Margin at 1.2% (about 15px at 1280px)
 *
 *  Usage:
 *
 *    <div class="section group">
 *        <div class="col span_1_of_3">
 *            This is column 1
 *        </div>
 *        <div class="col span_1_of_3">
 *            This is column 2
 *        </div>
 *        <div class="col span_1_of_3">
 *            This is column 3
 *        </div>
 *    </div>
 *
 */

/*  SECTIONS  */
.section {
    clear: both;
    padding: 0px;
    margin: 0px;
}

/*  COLUMN SETUP  */
.col {
    display: block;
    float:left;
    margin: 1% 0 1% 1.2%;
}
.col:first-child { margin-left: 0; }

/*  GROUPING  */
/*.group:before,
.group:after { content:""; display:table; }
.group:after { clear:both;}
*/

/*  GRID OF TWO  */
.span_2_of_2 { width: 100%; }
.span_1_of_2 { width: 49.4%; }

/*  GRID OF THREE  */
.span_3_of_3 { width: 100%; }
.span_2_of_3 { width: 66.26%; }
.span_1_of_3 { width: 32.53%; }


/*  GO FULL WIDTH AT LESS THAN 480 PIXELS */
/*
@media only screen and (max-width: 480px) {
    .col {
        margin: 1% 0 1% 0%;
    }
    .span_2_of_2, .span_1_of_2 { width: 100%; }
    .span_3_of_3, .span_2_of_3, .span_1_of_3 { width: 100%; }
}*/
