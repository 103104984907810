/* Structure */
.conversations-page {
    .main-nav,
    .secondary-nav {
        position: relative;
    }
    .main-nav + .main-content {
        @include abs-height-100(5px + $main-nav-height);
    }
    .secondary-nav + .main-content {
        @include abs-height-100(5px +$main-nav-height + $secondary-nav-height);
    }
}

/* Conversations Styles */
.conversations-wrapper {
    @include height-100;
}
.conversation-list-panel {
    float: left;
    @include height-100;
    position: relative;
    width: 25%;
}
.conversation-container {
    @include height-100;
    position: relative;
    margin-left: 25%;
    width: 75%;
    overflow: visible;
}
.conversation-panel {
    @include height-100;
    width: 72%;
    margin-right: 30%;
}
.customer-panel-container {
    @include height-100;
    float: right;
    width: 28%;
}
.clipboard-container {
    display: none;
    position: absolute;
    right: 100%;
    margin-right: -15px;
    z-index: 10;
    width: 40%;
}
.clipboard-dropdown.dropdown-menu {
    width: 100%;
    max-width: none;
    .dropdown-scroll {
        max-height: 167px;
    }
}

/* Inbox dropdown */
.inbox-dropdown {
    .section-name {
        max-width: 250px;
    }
    a {
        max-width: none !important;
    }
    .inbox-toggle {
        display: inline-block;
        vertical-align: top;
        height: 29px;
    }
    .section-count {
        margin-left: 0px;
    }
    .channel-group {
        font-weight: bold;
    }
    .header-area li div span:before {
        margin-right: 4px !important;
    }
}

/* Common styles */
.profile-picture {
    @include border-radius(3px);
    width: 40px;
    height: 40px;
}
.priority-highlight {
    display: inline-block;
    background: $flagged-text-highlight;
}
.tag,
.small-tag {
    .conversation-preview:hover &,
    .conversation-preview.active &,
    .conversation-meta & {
        &:not(.priority) {
            border-color: $border-1;
        }
    }
    &.priority {
        background: $yellow;
        border-color: $yellow;
        &:before {
            color: $brown;
        }
    }
    &.retweeted:before {
        color: $green-light;
    }
    &.twitter-liked:before {
        color: $twitter-like-red;
    }
    &.liked:before {
        color: $verint-blue;
    }
    &.instagram-liked:before {
        color: $heart-red;
    }
    &.positive-sentiment:before {
        color: $green;
    }
    &.negative-sentiment:before {
        color: $red;
    }
    &.neutral-sentiment:before {
        color: $verint-blue;
    }
    &.private {
        background: $dark-blue;
        border-color: $dark-blue;
        color: $white;
        &:before {
            color: $white;
        }
    }
    &.public {
        background: $bright-blue-background;
        border-color: $bright-blue-background;
        color: $white;
        &:before {
            color: $white;
        }
    }
    &.escalated {
        background: $legacy-button-cyan;
        border-color: $legacy-button-cyan;
        color: $white;
        &:before {
            color: $white;
        }
    }
    &.hidden-message,
    &.deleted-message {
        max-width: none;
        background: $button-text !important;
        border-color: $button-text !important;
        color: $white;
        &:before {
            color: $white;
        }
    }
    &.rating {
        @include border-radius(5px);
        background: $tag-rating;
        border-color: $tag-rating;
        color: $white;
        .star:before {
            margin-right: 0;
            margin-left: 2px;
        }
    }
    &:last-child {
        margin-right: 0;
    }
}
a.tag {
    &.public {
        &:hover {
            text-decoration: none;
            background-color: $tag-public;
            border-color: $tag-public;
        }
    }
    &.private {
        &:hover {
            text-decoration: none;
            background-color: $tag-private;
            border-color: $tag-private;
        }
    }
}
.tag {
    &.neutral-sentiment {
        padding: 0 1px 0 0;
    }
}

/* Dialogs */
@import "dialogs";

/* Conversations List Panel */
@import "preview_list";

/* Conversation Panel */
@include keyframes(tada) {
    0% {@include transform(scale(1));}
    0.25%, 0.50% {@include transform(scale(0.9) rotate(-3deg));}
    0.75%, 1.25%, 1.75%, 2.25% {@include transform(scale(1.1) rotate(3deg));}
    1.00%, 1.50%, 2.00% {@include transform(scale(1.1) rotate(-3deg));}
    2.5% {@include transform(scale(1) rotate(0));}
}
@mixin tada($duration, $delay, $function, $fill, $visibility) {
    @include animation-name(tada);
    @include animation-iteration-count(infinite);
    @include duration($duration);
    @include delay($delay);
    @include function($function);
    @include fill-mode($fill);
    @include visibility($visibility);
}
.typing-indicator,
.loading-spinner {
    /* Image spinner, for IE9 etc */
    div {
        display: inline-block;
    }
    background-image: url("../../images/conv-spinner.gif");
    .app-loading & {
        background-image: url("../../images/conv-app-spinner.gif");
        width: 48px;
        height: 48px;
    }
    width: 24px;
    height: 24px;
    &.smaller {
        margin-top: -12px;
        margin-left: -12px;
    }

    /* CSS spinner */
    html.cssanimations & {
        @include three-bounce($grey-3);
        background-image: none;
        width: auto;
        height: auto;
        div {
            display: inline-block;
        }
        &.smaller {
            margin-top: -10px;
            margin-left: -21px;
        }
    }

    /* Common styles */
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -11px;
    margin-left: -30px;
    &.smaller {
        div {
            height: 12px;
            width: 12px;
        }
    }
}
.typing-indicator {
    margin-top: -9px;
    html.cssanimations & {
        @include three-jump($grey-3);
        div {
            width: 15px;
            height: 15px;
        }
    }
}
.conversation-empty-watermark {
    &.hidden {
        display: none;
    }
    position: absolute;
    left: 50%;
    top: 50%;
    width: 94px;
    height: 88px;
    margin-left: -47px;
    margin-top: -44px;
    background: url("../../images/bubble.svg") no-repeat;
    background-size: 100%;
    opacity: 0.2;
    @include tada(40.0s, 5.0s, $function, $fill, $visibility);
}
.conversation-panel {
    display: none;
    position: relative;
}
.conversation-container {
    &.has-conversation {
        .conversation-empty-watermark {
            display: none;
        }
        .conversation-panel {
            display: block;
        }
        .customer-panel {
            display: block;
        }
    }
    .loading-spinner {
        display: none;
    }
    &.loading {
        .loading-spinner {
            display: block;
        }
        .conversation-empty-watermark {
            display: none;
        }
    }
}

@import "conversation";
@import "conversation_history";
@import "centre_panel";
@import "customer_panel";
@import "channel_notifications";
@import "reply_box";
@import "reminders";
@import "edit_reply_users";

.conversation-shortcuts {
    position: absolute;
    bottom: 0;
    left: $centre-panel-gutter;
    right: $centre-panel-gutter;
    border-left: 1px solid $border-1;
    border-right: 1px solid $border-1;
    font-size: 12px;
}

.customer-panel {
    display: none;
    z-index: 3;
}

@import "media_queries";

/* Loading */
.app-loading {
    @include transition(opacity 0.5s ease-out);
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: $login-bg;
    .loading-spinner div {
        background: $white !important;
    }
    &.loaded {
        opacity: 0;
    }
}

/* uiv6 */
.ab-alt-agent-message {
    .message.agent-message {
        .message-bubble {
            background: inherit;
            border: inherit;
            color: inherit;
        }
        &:not(.editing) .tag {
            color: inherit;
            border-color: inherit;
            background: inherit;
        }
        .message-text a {
            color: inherit;
        }
    }
}
.dialog-optin {
    display: none;
    &.show-dialog {
        display: block;
    }
    .dialog {
        width: 420px;
        left: 50%;
        top: 30%;
        margin-left: -210px;
    }
    h4:before {
        color: $green;
    }
}
