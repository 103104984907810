@use '../styles/fonts/fontello/css/fontello-codes.scss';

/* Success/error overlay at top */
.error-overlay-wrap {
    position: fixed;
    top: 8px;
    left: 50%;
    z-index: 9;

    &.error .error-overlay {
        @extend .icon-attention-1;
        background-color: $red;
        background: $legacy-error-overlay-background;
        border: 1px solid $legacy-error-overlay-border;
        padding-left: 30px;
        color: $white;

        &:before {
            position: absolute;
            font-family: 'fontello';
            color: $white;
            font-size: 18px;
            left: 8px;
        }
        a {
            text-decoration: underline;
            color: $white;
            &:hover {
                text-decoration: none;
            }
        }
        .close {
            top: 12px;
            background: url("../images/icons2/close-white.png") 0px 0px no-repeat;
            &:hover {
                background: url("../images/icons2/close.png") 0px 0px no-repeat;
            }
        }
    }

    &.warning {
        color: $legacy-error-overlay-warning;
    }
    &.warning .error-overlay {
        background-color: $legacy-error-overlay-background;
        background: $legacy-error-overlay-background2;
    }

    .close {
        position: absolute;
        top: 13px;
        right: 10px;
        width: 10px;
        height: 10px;
        cursor: pointer;
    }

    &.success {
        color: $base-font-colour;
    }
    &.success .error-overlay {
        @extend .icon-ok-circled;
        padding-left: 30px;
        background-color: $grey-1;
        padding: 10px 40px;
        border: 1px solid $grey-4;
        position: relative;
        &:before {
            position: absolute;
            font-family: 'fontello';
            color: $legacy-error-overlay-success;
            font-size: 28px;
            left: 8px;
        }
    }


}
.error-overlay {
    position: relative;
    float: left;
    left: -50%;
    padding: 7px 25px;
    line-height: 18px;
    min-width: 150px;
    text-align: center;
    a {
        @extend a;
    }
}
