.conversation-list-panel {
    background: $white;
    border-right: 1px solid $border-1;
    border-top: 1px solid $border-2;
}
.conversation-list-toolbar {
    border-bottom: 1px solid $border-1;
    /*height: 46px;*/
    left: 0px;
    padding: 12px 12px 12px 10px;
    position: absolute;
    right: 0px;
    z-index: 3;
    .filters-active {
        &:before {
            color: $verint-blue !important;
        }
        background: $white !important;
        border-color: $legacy-conversations-preview-list-filters-active !important;
    }
}
.sort-order-select {
    .dropdown-menu {
        /* Sort icons, combine flag + sort in dropdown only */
        .icon-flag.icon-sort-number-down:before {
            content: '\e8ab  \e9a6';
        }
        .icon-flag.icon-sort-number-up:before {
            content: '\e8ab  \e9a5';
        }
        li {
            padding-left: 50px;
            &:before {
                padding-left: 7px;
                text-align: right;
            }
        }
    }
}
.conversation-list {
    @include abs-height-100(55px);
    overflow-y: auto;
    width: 100%;
}
.conversation-list-panel {
    &.has-notification {
        .list-notification {
            display: block;
        }
        .conversation-list {
            @include abs-height-100(100px);
        }
    }
    &.has-filters {
        .list-filters {
            display: block;
        }
        .conversation-list {
            @include abs-height-100(114px);
        }
    }
    &.has-notification.has-filters {
        .conversation-list {
            @include abs-height-100(159px);
        }
    }
}
.conversation-list-panel .loading-spinner {
    display: none;
}

.conversation-list-panel .istyping .loading-spinner {
    display: block;
}
.conversation-list-panel.loading {
    .loading-spinner {
        display: block;
    }
    .conversation-preview {
        display: none;
    }
}
.list-notification {
    display: none;
    line-height: 17px;
    margin-top: 12px;
    padding: 8px 10px;
    position: relative;
    @include border-radius(3px);
    &:before {
        color: $white;
        font-size: 16px;
        margin-right: 8px !important;
        vertical-align: middle;
    }
    .close-notification {
        cursor: pointer;
        position: absolute;
        right: 6px;
        top: 9px;
    }
}
.button-play {
    &:before {
        font-size: 15px;
    }
}
.button-group.pagination {
    .button:before {
        font-size: 21px;
    }
}
.list-filters {
    background: $grey-bg-2;
    border: 1px solid $border-1;
    display: none;
    margin-top: 12px;
    padding: 0px 0px 8px 7px;
    position: relative;
    @include border-radius(3px);

    .button-group {
        margin-left: 0px;
        margin-right: 0;
        margin-top: 7px;
        padding-right: 7px;
        width: 50%;
    }
    .dropdown,
    .button {
        text-align: left;
        width: 100%;
    }

    /* Up arrow - make mixin */
    &:after,
    &:before {
        border: solid transparent;
        bottom: 100%;
        content: " ";
        height: 0;
        left: 34px;
        pointer-events: none;
        position: absolute;
        width: 0;
    }
    &:before {
        border-bottom-color: $border-1;
        border-color: transparent;
        border-width: 6px;
        margin-left: -6px;
    }
    &:after {
        border-bottom-color: $grey-bg-2;
        border-color: transparent;
        border-width: 5px;
        margin-left: -5px;
    }
}
.conversation-list-empty {
    color: $button-text;
    padding: 10px 30px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 100%;
    @include transform(translateY(-50%));
}
.conversation-preview {
    border-bottom: 1px solid transparent;
    border-top: 1px solid $border-2;
    cursor: pointer;
    margin: 0px 5px;
    position: relative;
    &:first-child {
        border-top: none;
    }
    &:hover,
    &.active,
    &.active:hover {
        background: $grey-bg-1;
        border-bottom-color: $border-1;
        margin: 0;
        padding: 0 5px 1px;
    }
    &:hover,
    &.active {
        .preview-played {
            left: 7px;
        }
    }
    .checkbox-label {
        float: left;
        margin-left: 5px;
        margin-top: 15px;
    }
    &:hover + &:not(.active),
    &.active + &:not(.active) {
        border-top: none;
    }
    &:hover + &.active,
    &.active + &.active {
        border-top: none;
    }
    .typing-indicator {
        display: none;
    }
}
.conversation-preview.is-typing {
    .preview-text {
        color: $grey-2;
        overflow: visible;
        position: relative;
    }
    .typing-indicator {
        display: block;
        margin-left: -38px;
        margin-top: -7px;
    }
}
.preview-played {
    left: 2px;
    padding: 5px;
    position: absolute;
    top: 33px;
    &:before {
        color: $green;
    }
}
.preview-body {
    padding: 15px 15px 15px 26px;
}
.preview-header {
    @include group;
    .profile-picture {
        float: left;
    }
}
.preview-meta,
.preview-title {
    margin-left: 48px;
}

.preview-title {
    @include ellipsis;
    height: 15px;
    margin-bottom: 7px;
}
.preview-author-name {
    color: $base-font-colour;
    font-weight: bold;
    font-weight: 500;
    margin-right: 5px;
    &:before {
        display: inline-block;
        margin-right: 4px !important;
        text-decoration: none !important;
    }
}
.preview-author-screen-name {
    color: $button-text;
    font-size: 12px;
    font-weight: normal;
}
.preview-date {
    color: $button-text;
    float: right;
}
.preview-meta {
    @include ellipsis;
    height: 18px;
}
.preview-message-count {
    float: right;
    margin-left: 5px;
    margin-right: 0;
    overflow: visible;
    position: relative;
    text-align: center;
    z-index: 1;
}
.preview-meta.no-count,
.preview-message-count {
    position: relative;
    &:after {
        content: '';
        height: 21px;
        position: absolute;
        top: -1px;
        width: 20px;
        @include fade-end;
        .conversation-preview:hover &,
        .conversation-preview.active & {
            @include fade-end($grey-bg-1);
        }
    }
}
.preview-meta.no-count:after {
    right: -1px;
}
.preview-message-count:after {
    left: -21px;
}
.preview-section-meta {
    margin-top: 10px;
}
.preview-text {
    -webkit-box-orient: vertical;

    display: -webkit-box;
    font-size: 12px;
    -webkit-line-clamp: 3;
    line-height: 16px;
    margin-top: 10px;
    max-height: 46px;
    overflow: hidden;
}
.istyping .preview-text {
    opacity: 0.5;
}
.preview-text-container {
    position: relative;
}
.preview-attachments-container {
    margin-top: 10px;
    &.preview-show-attachments {
        .preview-att-show {
            display: none;
        }
        .preview-attachments,
        .preview-att-hide {
            display: block;
        }

    }
}
.preview-toggle-attachment {
    color: $button-text;
    cursor: pointer;
    display: inline;
    font-size: 12px;
    &:hover {
        color: $verint-blue;
    }
    &.preview-att-hide {
        display: none;
    }
}
.preview-attachments {
    display: none;
    margin-top: 10px;
    overflow: hidden;
    white-space: nowrap;
    img {
        @include border-radius(3px);
        height: 55px;
        margin-right: 5px;
        max-width: 250px;
    }
}


.author-online-state {
    background: $legacy-conversations-preview-author-online-state;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    display: inline-block;
    height: 10px;
    width: 10px;

    &.active {
        background: $legacy-conversations-preview-author-online-state-active;
    }
}
