body {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: $base-font-size;
    color: $base-font-colour;
    margin: 0;
    padding: 0;
    min-width: 1024px;
}

body, html {
    @include height-100;
    background-color: $grey-bg-1;
}
* {
    @include box-sizing(border-box);
}

/* Links */
a {
    @include link;
}

.group {
    @include group;
}
.float-left {
    float: left;
}
.float-right {
    float: right;
}
