/* Conversocial Global Styles */

/* Compass imports */
@import "~compass-mixins/lib/compass/css3.scss";
@import "~compass-mixins/lib/compass/typography/text/ellipsis";
@import "~compass-mixins/lib/compass/css3/user-interface";
@import "../vendor/animate.scss/animate.css";

/* Vendor library "animate.scss" */
@import "../vendor/animate.scss/source/properties";

/* Variables */
@import "../../styles/custom_properties";
@import "variables";
@import "mixins";

/* Fontello Icon Codes */
@import "../styles/fonts/fontello/css/fontello-codes";

/* Reset */
@import "lib/normalize";

/* Core */
@import "foundation";
@import "structure";
@import "font";
@import "form";
@import "lib/three_bounce";
@import "lib/responsive_grid";

/* Components */
@import "dropdowns";
@import "buttons";
@import "button-groups";
@import "toolbars";
@import "select";
@import "navs";
@import "tooltips";
@import "notification";
@import "dialogs";
@import "tables";
@import "calendar";
@import "tags";
@import "progress";

/** Tags */

/* Pages */
@import "main/login";
@import "main/onboarding";
@import "main/error_pages";
@import "conversations/app";
@import "analytics/app";
@import "outbound/app";
@import "account/app";

/* account_legacy */
@import "error-overlay";

/** Notifications */
/** Conversation preview */
