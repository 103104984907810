.conversation-history-header {
    @include user-select(none);
    @include transition(250ms top ease-out);
    cursor: pointer;
    background: $white;
    position: absolute;
    border-top: 1px solid $border-2;
    left: $centre-panel-gutter;
    right: $centre-panel-gutter;
    z-index: 1;
    line-height: 53px;
    text-align: center;
}
.conversation-history {
    background-color: $dark-grey-background;
    padding: 20px;
    font-size: 12px;
    &.recent {
        border-bottom: 1px solid $border-1;
    }
    &.older {
        border-top: 1px dashed $border-1;
    }
}
.recent-history-wrapper {
    padding-top: 64px;
    background-color: $dark-grey-background;
}

.conversation-small-conversation {
    .loading-spinner {
        display: none;
    }
    .conversation-small-wrapper {
        @include transition(ease-out .25s opacity);
        opacity: 1;
    }
    &.faded-out .conversation-small-wrapper {
        opacity: 0;
    }
    &.loading {
        position: relative;
        padding-top: 30px;
        margin-bottom: 10px;
        .loading-spinner {
            display: block;
        }
    }
}

.conversation-history.recent {
    .conversation-small-conversation {
        margin-bottom: 50px;
    }
}
.conversation-history.older {
    .conversation-small-conversation {
        margin-top: 50px;
    }
}
.conversation-small-meta {
    line-height: 21px;
    vertical-align: middle;
    color: $button-text;
    label {
        margin-right: 4px;
    }
    .meta-value {
        color: $base-font-colour;
        font-weight: bold;
        font-weight: 500;
        margin-bottom: 5px;
    }
    .profile-picture {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        vertical-align: middle;
    }
    .tag {
        margin-bottom: 5px;
    }
}
.conversation-small-segment {
    position: relative;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    &:first-child {
        padding-top: 0;
    }
    &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 2px;
        left: 0;
        top: 0;
    }
    &.positive:before {
        background: $green;
    }
    &.neutral:before {
        background: $verint-blue;
    }
    &.negative:before {
        background: $red;
    }
}
@include keyframes(conversation-flash) {
    0% {
        background-color: $grey-bg-2;
    }
    50% {
        background-color: $legacy-conversations-flash;
    }
    100% {
        background-color: $grey-bg-2;
    }
}
.conversation-small-conversation.flash-convo {
    .conversation-small-body {
        @include animation(conversation-flash 0.50s);
    }
}
.conversation-small-body {
    background: $grey-bg-2;
    border: 1px solid $border-1;
    @include border-radius(3px);
    margin-top: 5px;
    padding: 15px 10px 20px;
}
.conversation-history .conversation-small-body:hover {
    cursor: pointer;
    border-color: $legacy-conversations-history;
}
.conversation-small-date-header {
    color: $button-text;
    text-align: center;
}

.hidden-conversation-message {
    display: block;
    width: 290px;
    margin-left: auto;
    margin-right: auto;
}

.small-message {
    @include group;
    position: relative;
    .small-message-bubble {
        position: relative;
        max-width: 70%;
        padding: 10px;
        @include border-radius(3px);
        line-height: 16px;
        min-height: 38px;
        word-wrap: break-word;
    }
    .profile-picture {
        position: absolute;
        left: 0px;
        top: 5px;
        width: 20px;
        height: 20px;
    }
}
.small-message.customer-message {
    .small-message-bubble {
        background: $white;
        border: 1px solid  $border-1;
        margin-left: 33px;
        float: left;
    }
    /* left arrow */
    .small-message-bubble:after, .small-message-bubble:before {
        right: 100%;
        top: 15px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    .small-message-bubble:after {
        border-color: transparent;
        border-right-color: $white;
        border-width: 7px;
        margin-top: -7px;
    }
    .small-message-bubble:before {
        border-color: transparent;
        border-right-color: $border-1;
        border-width: 8px;
        margin-top: -8px;
    }
}
.small-message.agent-message {
    .small-message-bubble {
        background: $highlight;
        border: 1px solid $border-1;
        position: relative;
        margin-right: 33px;
        float: right;
    }
    .profile-picture {
        left: auto;
        right: 0px;
    }

    /* right arrow */
    .small-message-bubble:after, .small-message-bubble:before {
        left: 100%;
        top: 15px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    .small-message-bubble:after {
        border-color: transparent;
        border-left-color: $highlight;
        border-width: 7px;
        margin-top: -7px;
    }
    .small-message-bubble:before {
        border-color: transparent;
        border-left-color: $border-1;
        border-width: 8px;
        margin-top: -8px;
    }
}
.small-message.agent-note {
    .small-message-bubble {
        background: $yellow-pale;
        color: $base-font-colour;
        border: 1px solid $border-1;
        margin-right: 33px;
        float: right;
    }
    .profile-picture {
        left: auto;
        right: 0px;
    }
}
.small-message.deleted-message {
    .small-message-bubble {
        text-decoration: line-through;
        color: $button-text;
    }
    &.customer-message .small-message-bubble {
        background: $grey-bg-2;
    }
    &.agent-message .small-message-bubble {
        background: $light-blue-grey-background;
        border-color: $light-blue-grey-border;
    }

    /* Left arrow */
    &.customer-message .small-message-bubble:after,
    &.customer-message .small-message-bubble:before {
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    &.customer-message .small-message-bubble:after {
        border-color: transparent;
        border-right-color: $grey-bg-2;
        border-width: 7px;
        margin-top: -7px;
    }
    &.customer-message .small-message-bubble:before {
        border-color: transparent;
        border-right-color: $border-1;
        border-width: 8px;
        margin-top: -8px;
    }

    /* Right arrow */
    &.agent-message .small-message-bubble:after,
    &.agent-message .small-message-bubble:before {
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    &.agent-message .small-message-bubble:after {
        border-color: transparent;
        border-left-color: $light-blue-grey-background;
        border-width: 7px;
        margin-top: -7px;
    }
    &.agent-message .small-message-bubble:before {
        border-color: transparent;
        border-left-color: $light-blue-grey-border;
        border-width: 8px;
        margin-top: -8px;
    }
}
.small-message-meta {
    float: left;
    margin-left: 5px;
    margin-top: 8px;
}
.small-attachment {
    color: $button-text;
}
