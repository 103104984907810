@import 'colors';

$app-width: 64rem;

// Verint Variables
$verint-header-logo-width: 158px;
$account-app-height: calc(100vh - 9.125rem);

// Legacy Conversocial Variables
$base-font-size: 13px;

/* Navigations */
$main-nav-height: 80px;
$main-nav-top-row-height: 40px;
$main-nav-internal-height: 16px;
$secondary-nav-height: 40px;
$secondary-nav-internal-height: 29px;
$side-nav-width: 270px;

/* UI: Dropdowns */
$max-dropdown-width: 425px;
$fixed-dropdown-width: 210px;

/* For animate.scss */
$duration: 1s;
$delay: .2s;
$function: ease;
$fill: both;
$visibility: hidden;
$centre-panel-gutter: 5px;
$settings-list-panel: 15.625rem;
