.toolbar {
    @include group;
    > .button,
    .toolbar-text,
    .select-container,
    .button-group {
        float: left;
        display: block;
        margin-left: 5px;
        margin-right: 5px;
    }
    > .button:first-child,
    .toolbar-text:first-child,
    .select-container:first-child,
    .button-group:first-child {
        margin-left: 0px;
    }
    > .button:last-child,
    .toolbar-text:last-child,
    .select-container:last-child,
    .button-group:last-child {
        margin-right: 0px;
    }
    .toolbar-text {
        line-height: 29px;
        color: $button-text;
    }
    .button-group.right {
        float: right;
    }
    &.left {
        float: left;
    }
    &.right {
        float: right;
    }
    .toolbar-left {
        float: left;
    }
    .toolbar-right {
        float: right;
    }
}

.toolbar {
    .input.checkbox + label {
        float: left;
        margin-top: 8px;
    }
    &, .toolbar-left, .toolbar-right {
        > .button-group,
        > .select-container,
        > .button {
            margin-right: 5px;
            &:last-child {
                margin-right: 0px;
            }
        }
    }
    .toolbar-left + .toolbar-left {
        border-left: 1px solid $border-1;
        padding-left: 10px;
        margin-left: 10px;
    }
}
.toolbar .toolbar-input,
.toolbar-input {
    float: left;
    width: auto;
}
