.channel-notifications-area {
    position: relative;
    display: inline-block;

    .channel-notifications-toggle {
        border: 1px solid $orange-border;
        line-height: 28px;
        @include antialiased;
        background: $orange;
        color: $white;
        @include border-radius(3px);
        padding: 0px 7px;
        cursor: pointer;
        &:hover {
            background: $orange-hover;
        }
        &:before {
            font-size: 13px;
            padding-right: 2px;
        }
    }
    .channel-notifications-container {
        display: none;
        position: absolute;
        right: 0px;
        top: 33px;
        &.dropdown-open {
            display: block;
        }
    }
}

.channel-notifications-container {
    border: 1px solid $border-1;
    background: $grey-bg-2;
    padding: 5px;
    position: relative;
    width: 575px;
    .channel-notifications-title {
        font-weight: bold;
        padding-left: 5px;
        padding-top: 2px;
        padding-bottom: 8px;
        font-size: 13px;
    }
    &.critical-errors {
        .channel-notifications-title:before {
            color: $red;
            font-size: 18px;
            padding-right: 3px;
        }
    }
    ul.channel-notifications {
        @include remove-list;
        overflow-y: auto;
    }
}

.channel-notification {
    margin-bottom: 5px;
    &:last-child {
        margin-bottom: 0px;
    }
    padding: 10px;
    background: $white;
    border: 1px solid $legacy-achannel-notification-border;
    .notification-image {
        float: left;
        width: 50px;
        height: 50px;
    }
    .notification-body {
        margin-left: 61px;
        a {
            display: inline !important;
        }
    }
    .notification-title {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 12px;
        margin-top: 4px;
    }
    .notification-description {
        margin-bottom: 10px;
        ul li {
            margin-bottom: 10px;
        }
    }
    .notification-warning {
        color: $red;
        font-weight: bold;
        margin-bottom: 11px;
    }
}
