@import 'variables';

.reactable-previous-page,
.reactable-next-page,
.reactable-page-button,
.button {
    white-space: nowrap;
    &, &:before {
        color: $button-text;
    }
    &.large {
        font-size: 14px;
        line-height: 18px;
        padding: 8px 10px
    }
    .subtext {
        color: $button-text;
        font-size: 12px;
        line-height: 12px;
    }
    &.small {
        line-height: 16px;
        font-size: 12px;
        padding: 4px 6px;
    }
    font-weight: normal;
    cursor: pointer;
    border: 1px solid $border-1;
    min-width: 0;
    line-height: 18px;
    text-align: center;
    padding: 5px 8px;
    min-width: 20px;

    &, &.working:hover, &.working:active, &.working:focus,
    &.working:not(.invisible):focus, &.working:not(.invisible):hover {
        color: $button-text;
        background: $grey-button;
        border-color: $border-1;
    }

    &.disabled,
    &:disabled {
        border-color: $border-1 !important;
        background-color: $grey-disabled !important;
        color: $form-disabled-text;
        cursor: default;
    }
    &:focus {
        outline: none;
    }
    &.active:not(.disabled):not([disabled]),
    &:active:not(.disabled):not([disabled]) {
        position: relative;
        top: 1px;
        background-color: $grey-active;
    }
    &.active {
        background-color: $grey-active;
    }
    &:focus,
    &:hover {
        border-color: $border-1;
        background-color: $grey-hover;
    }
    &.dropdown-open {
        position: relative;
        top: 0px;
    }
    &:focus {
        outline: none;
    }
    &:not(:empty) {
        min-width: 70px;
    }
    &:empty {
        padding-left: 4px;
        padding-right: 4px;
        &:before {
            margin-right: 0;
        }
    }
    @include box-sizing(border-box);
    &:before {
        font-size: 16px;
        margin-right: 4px;
        vertical-align: top;
        display: inline-block;
    }
    &.follow-button {
        width: 78px;
        .unfollow,
        .following {
            display: none;
        }
        &.is-following {
            color: $white-text;
            @include antialiased;
            background: $legacy-button-is-following;
            border-color: $legacy-button-is-following-border;
            .follow,
            .unfollow {
                display: none;
            }
            .following {
                display: inline;
            }
            &:hover {
                background: $legacy-button-is-following-hover;
                border-color: $legacy-button-is-following-border-hover;
                .unfollow {
                    display: inline;
                }
                .following {
                    display: none;
                }
            }
            &:before {
                display: none;
            }
        }
    }
    &:after {
        vertical-align: top;
    }
    @include ellipsis;
    &.has-dropdown {
        position: relative;
        padding-right: 21px;
    }
    &.has-dropdown:after {
        font-family: "fontello";
        content: "\e848";
        font-size: 16px;
        padding-right: 0px;
        position: absolute;
        right: 7px;
        top: 5px;
    }
    &.has-dropdown:empty {
        padding-right: 21px;
    }
    /* Working spinners from http://http://www.loadinfo.net/ */
    &.working:before {
        content: url('../images/conv-spinner-small.gif');
        display: inline-block;
        margin-top: -1px;
        position: relative;
        top: 2px;
    }
    &.google,
    &.googleplus,
    &.youtube,
    &.instagram,
    &.twitter,
    &.instagram,
    &.facebook {
        &:before {
            color: $white-text;
            font-size: 16px;
            display: inline-block;
        }
    }
    &.instagram {
        @extend .icon-instagram;
        &:before {
            @include antialiased;
            font-family: 'fontello';
            color: $instagram-blue;
            padding-left: 3px;
        }
    }
    &.googleplus {
        @extend .icon-gplus-1;
        &:before {
            font-family: 'fontello';
            color: $legacy-button-google-plus-icon;
            padding-left: 3px;
        }
    }
    &.google {
        @extend .icon-gbm;
        &:before {
            font-family: 'fontello';
            color: $legacy-button-google-plus-icon;
            padding-left: 3px;
        }
    }
    &.youtube {
        @extend .icon-youtube-play;
        &:before {
            font-family: 'fontello';
            color: $legacy-button-youtube;
            padding-left: 2px;
        }
    }
    &.facebook {
        @extend .icon-facebook-squared-1;
        &:before {
            font-family: 'fontello';
            color: $facebook-blue;
            padding-left: 5px;
        }
    }
    &.twitter {
        @extend .icon-twitter-1;
        &:before {
            font-family: 'fontello';
            color: $twitter-blue;
            padding-left: 3px;
        }
    }
    &.login.google {
        @include antialiased;
        padding-left: 6px;
        background: $legacy-button-google-plus;
        border-color: $legacy-button-google-plus;
        color: $white-text;
        &:before {
            color: $white-text;
            font-size: 20px;
            padding-right: 5px;
            border-right: 1px solid $legacy-button-google-plus-border;
            margin-right: 15px;
        }
        padding-right: 15px;
    }
    &.login.googleplus {
        @include antialiased;
        padding-left: 6px;
        background: $legacy-button-google-plus;
        border-color: $legacy-button-google-plus;
        color: $white-text;
        &:before {
            color: $white-text;
            font-size: 20px;
            padding-right: 5px;
            border-right: 1px solid $legacy-button-google-plus-border;
            margin-right: 15px;
        }
        padding-right: 15px;
    }
    > img {
        display: inline-block;
        width: 15px;
        height: 15px;
        vertical-align: text-top;
    }
}

a.button,
a.button:hover {
    text-decoration: none;
}

.button:empty {
    min-width: 23px;
    padding-left: 6px;
    padding-right: 6px;
}
.button:not(:empty) {
    min-width: 75px;
}
.button.has-dropdown {
    position: relative;
    &:after {
        @extend .icon-down-open-1, :before;
        font-family: "fontello";
        margin-left: 5px;
        vertical-align: top;
        font-size: 15px;
        color: $button-text;
    }
}
.button.button-tiny {
    height: 21px;
    line-height: 20px;
    font-size: 11px;
    padding: 0 5px;
    &:not(:empty) {
        min-width: 50px;
    }
    &:before {
        font-size: 11px;
    }
}

.select.invisible,
.button.invisible {
    background: transparent;
    border-color: transparent;
    &:not(:empty):before {
        margin-right: 8px;
    }
}
.button {
    @include user-select(none);
    &:not(:empty):before {
        margin-right: 6px;
    }
}
.button-green,
.button-red ,
.button.button-green,
.button.button-red {
    &:before {
        color: $white-text;
    }
}

.button.follow-button {
    &.icon-twitter:before {
        color: $twitter-blue !important;
    }
}
.button-green,
.button.button-green {
    &:before {
        color: $white-text !important;
    }

    &, &.working:hover, &.working:active, &.working:focus,
    &.working:not(.invisible):focus, &.working:not(.invisible):hover {
        color: $white-text;
        background-color: $green;
        border: 0;
    }
    @include antialiased;

    &.disabled,
    &:disabled {
        opacity: 0.4;
    }

    &:not(.invisible) {
        &:focus,
        &:hover,
        &:active,
        &.progress {
            color: $white-text;
            background-color: $green-hover;
        }
    }
    &.working:before {
        content: url('../images/conv-spinner-small-green.gif');
    }
    &.working[disabled]:before,
    &.working.disabled:before {
        content: url('../images/conv-spinner-small-green-disabled.gif');
    }
}
.button-red,
.button.button-red {
    &:before {
        color: $white-text !important;
    }

    &, &.working:hover, &.working:active, &.working:focus,
    &.working:not(.invisible):focus, &.working:not(.invisible):hover {
        color: $white-text;
        background-color: $red;
        border: 0;
    }
    @include antialiased;

    &.disabled,
    &:disabled {
        opacity: 0.4;
    }

    &:not(.invisible) {
        &:focus,
        &:hover,
        &.progress {
            background-color: $legacy-button-is-following-hover;
            color: $white-text;
        }
    }

    &.working:before {
        content: url('../images/conv-spinner-small-red.gif');
    }
}
.button-blue,
.button.button-blue {
    &:after,
    &:before {
        color: $white-text !important;
    }

    &, &.working:hover, &.working:active, &.working:focus,
    &.working:not(.invisible):focus, &.working:not(.invisible):hover {
        color: $white-text;
        background-color: $bright-blue-background;
        border-color: $legacy-button-blue-border;
    }

    .dropdown-open & {
        background-color: $bright-blue-background !important;
    }

    &.disabled,
    &:disabled {
        background-color: $legacy-button-blue-disabled !important;
        border-color: $legacy-button-blue-border-disabled !important;
    }

    &:not(.invisible) {
        &:focus,
        &:hover,
        &:active,
        &.progress {
            color: $white-text;
            background-color: $legacy-button-blue-active;
            border-color: $legacy-button-blue-border;
        }
    }
    &.working:before {
        content: url('../images/conv-spinner-small-light-blue.gif');
    }
}
.button-dark-blue,
.button.button-dark-blue {
    &:after,
    &:before {
        color: $white-text !important;
    }

    &, &.working:hover, &.working:active, &.working:focus,
    &.working:not(.invisible):focus, &.working:not(.invisible):hover {
        color: $white-text;
        background-color: $dark-blue;
        border-color: $legacy-button-dark-blue-border;
    }

    .dropdown-open & {
        background-color: $dark-blue !important;
    }

    &.disabled,
    &:disabled {
        background-color: $legacy-button-dark-blue-disabled !important;
        border-color: $legacy-button-dark-blue-border-disabled !important;
    }

    &:not(.invisible) {
        &:focus,
        &:hover,
        &:active,
        &.progress {
            color: $white-text;
            background-color: $legacy-button-dark-blue-active;
            border-color: $legacy-button-dark-blue-border;
        }
    }
    &.working:before {
        content: url('../images/conv-spinner-small-dark-blue.gif');
    }
}
.button-yellow,
.button.button-yellow {
    &:before {
        color: $white-text !important;
    }

    &, &.working:hover, &.working:active, &.working:focus,
    &.working:not(.invisible):focus, &.working:not(.invisible):hover {
        color: $white-text;
        background-color: $button-yellow;
        border-color: $legacy-button-yellow-border;
    }

    &.disabled,
    &:disabled {
        background-color: $legacy-button-yellow-disabled !important;
        border-color: $legacy-button-yellow-active !important;
    }

    &:not(.invisible) {
        &:focus,
        &:hover,
        &:active,
        &.progress {
            color: $white-text;
            background-color: $legacy-button-yellow-active;
            border-color: $legacy-button-yellow-border;
        }
    }
    &.working:before {
        content: url('../images/conv-spinner-small-yellow.gif');
    }
}
.button-cyan,
.button.button-cyan {
    &:before {
        color: $white-text !important;
    }

    &, &.working:hover, &.working:active, &.working:focus,
    &.working:not(.invisible):focus, &.working:not(.invisible):hover {
        color: $white-text;
        background-color: $legacy-button-cyan;
        border-color: $legacy-button-cyan-border;
    }

    &.disabled,
    &:disabled {
        background-color: $legacy-button-cyan-disabled !important;
        border-color: $legacy-button-cyan-border !important;
    }

    &:not(.invisible) {
        &:focus,
        &:hover,
        &:active,
        &.progress {
            color: $white-text;
            background-color: $legacy-button-cyan-active;
            border-color: $legacy-button-cyan-border-active;
        }
    }
    &.working:before {
        content: url('../images/conv-spinner-small-cyan.gif');
    }
}
.button-grey,
.button.button-grey {
    &:after,
    &:before {
        color: $white-text !important;
    }

    &, &.working:hover, &.working:active, &.working:focus,
    &.working:not(.invisible):focus, &.working:not(.invisible):hover {
        color: $white-text;
        background-color: $button-text;
        border-color: $legacy-button-grey-border;
    }

    .dropdown-open & {
        background-color: $button-text !important;
    }

    &.disabled,
    &:disabled {
        background-color: $legacy-button-grey-disabled !important;
        border-color: $legacy-button-grey-disabled !important;
    }

    &:not(.invisible) {
        &:focus,
        &:hover,
        &:active,
        &.progress {
            color: $white-text;
            background-color: $legacy-button-grey-active;
            border-color: $legacy-button-grey-border-active;
        }
    }
    &.working:before {
        content: url('../images/conv-spinner-small-grey.gif');
    }
}
