/* Use dialog styles for old datepicker too */
.calendar-datepicker,
.dialog {
    float: left;
    position: absolute;

    background-color: $white;

    margin-bottom: 15px;

    &.min-top {
        top: 25px !important;
        margin-top: 0px !important;
    }

    .bold-message {
        font-weight: bold;
        white-space: pre-wrap;
        max-height: 80px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    }

    &.is-success .dialog-header {
        color: $green;
    }
    &.is-error .dialog-header {
        color: $red;
    }

    h2 {
        margin-top: 0;
        margin-bottom: 15px;
        font-size: 13pt;
        font-weight: normal;
    }

    h3 {
        margin-top: 0;
        margin-bottom: 20px;
        font-size: 13pt;
        font-weight: normal;
    }

    .dialog-header {
        position: relative;
        background: $dialog-header;
        color: $white-text;
        padding: 15px 10px;

        h1, h4 {
            font-size: 13px;
            margin: 0;
            font-weight: bold;
        }
        .dg-close {
            display: block;
            position: absolute;
            right: 15px;
            top: 19px;
            width: 8px;
            height: 8px;
            background: url("../images/icons2/close.png") no-repeat;
            &:hover {
                cursor: pointer;
                background: url("../images/icons2/close-on.png") no-repeat;
            }
        }
    }

    .dialog-errors,
    .dialog-success {
        &:before {
            margin-right: 5px;
        }
        position: relative;
        @include transition(0.25s ease-out opacity);
        opacity: 0;
        &:not(:empty) {
            padding: 10px 10px 9px;
            line-height: 18px;
            opacity: 1;
        }
        .close {
            display: block;
            position: absolute;
            right: 13px;
            top: 13px;
            width: 10px;
            height: 10px;
            background: url("../images/icons2/close-white.png") no-repeat;
            &:hover {
                cursor: pointer;
                background: url("../images/icons2/close-white.png") no-repeat;
            }
        }
        p:first-child {
            margin-top: 5px;
        }
        p:last-child {
            margin-bottom: 5px;
        }
        &:before {
            margin-right: 6px;
        }
    }

    .dialog-success {
        &:not(:empty) {
            border-bottom: 1px solid $green
        }
        background-color: $green;
        color: $white;
    }

    .dialog-errors {
        &:not(:empty) {
            border-bottom: 1px solid $orange-border;
        }
        background-color: $orange;
        color: $white;
        a {
            color: $critical-link;
        }
    }

    .dialog-below {
        position: absolute;
        left: 25px;
        right: 25px;
        bottom: -35px;
        text-align: center;
    }

    .warning-message {
        color: $dialog-warning-message;
    }

    .info {
        background-color: $highlight;
        border: 1px solid $dialog-info-border;
        padding: 0px 10px;
        margin-bottom: 10px;
        p.code {
            padding: 0px 10px;
            font-weight: bold;
            color: $base-font-colour;
        }
    }
    .errorlist {
        @include remove-list;
    }
    .dialog-list {
        margin-bottom: 20px;
    }
}
.react-datepicker,
.dialog.dialog-from-input {
    border: 1px solid $border-1;
}
.dialog-body {
    @include group;
    padding: 15px;
    min-width: 300px;
    p {
        margin-top: 5px;
        &:last-child {
            margin-bottom: 5px;
        }
    }
    .input-group:last-child {
        margin-bottom: 5px;
    }
    textarea {
        width: 100%;
    }
    .section {
        margin-top: 5px;
        border-top: 1px solid $grey-2;
        padding-top: 10px;
        &:first-child {
            border-top: none;
            padding-top: 2px;
            margin-top: 0px;
        }
    }
    .query-select {
        input {
            width: 22px;
            float: left;
        }
        label {
            float: left;
            width: 100px;
            line-height: 23px;
            cursor: pointer;
        }
        .help {
            float: left;
            height: 15px;
            width: 15px;
            background: url("../images/icons2/help.png") 1px 2px no-repeat;
            cursor: pointer;
        }
    }
    .info{
        position: relative;
        .close {
            position: absolute;
            right: 4px;
            top: 5px;
            width: 8px;
            height: 8px;
            background: url("../images/icons2/close.png") no-repeat;
            &:hover {
                cursor: pointer;
                background: url("../images/icons2/close-on.png") no-repeat;
            }
        }
    }
}

/* Session timeout dialog */
.dialog.session {
    .dialog-body {
        width: 225px;
    }
}

.dialog-footer {
    margin-top: 5px;
    @include group;
    background: $grey-bg-2;
    margin-top: 0px;
    padding: 14px 15px;
    height: auto;
    text-align: right;

    .button {
        display: inline-block;
        float: none;
        margin-left: 10px;
        &.left {
            margin-left: 0;
            margin-right: 15px;
        }
    }

    .button {
        margin-left: 15px;
    }
    .right {
        float: right;
    }
    .left {
        float: left;
    }
    .dialog-footer-help-link {
        // Should always be combined with icon-help-circled.
        float: left;
        margin-top: 5px;

        &:before {
            font-size: 20px;
            color: $information;
            vertical-align: middle;
            margin-right: 8px;
        }
    }
}

.dialog.dialog-white-header {
    .dialog-header {
        background: transparent;
        border-bottom: none;
        h4 {
            text-align: left;
            font-size: 16px;
            font-weight: bold;
        }
        h4:before {
            font-size: 36px;
            vertical-align: middle;
            margin-right: 14px;
            margin-left: 5px;
        }
    }
    .dialog-success,
    .dialog-errors {
        &:not(:empty) {
            margin: 0px 15px 15px 15px;
        }
    }
    .dialog-success {
        &:not(:empty) {
            border: 1px solid $green;
        }
    }
    .dialog-errors {
        &:not(:empty) {
            border: 1px solid $orange-border;
        }
    }

    .dialog-body {
        padding-top: 0px;
        p:first-child {
            margin-top: 0;
        }
    }
}
.dialog-container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 101;
    overflow: auto;
    &.dialog-overlay {
        background: $black-000-alpha-20;
    }
}
.dialog.quick {
    max-width: 375px;
    .dialog-body {
        position: relative;
        padding-top: 20px;
        padding-left: 55px;
        padding-bottom: 15px;
        p:first-child {
            margin-top: 0px;
        }
        p:last-child {
            margin-bottom: 0px;
        }
        &:before {
            margin-right: 6px;
        }
        min-height: 65px;
    }
    .dialog-body:after {
        position: absolute;
        top: 15px;
        left: 15px;
        width: 30px;
        height: 30px;
    }
}
.dialog.quick {
    .dialog-body:after {
        display: block; /* For IE, otherwise freaks out display:table from group mixin */
        font-family: 'fontello';
        font-size: 32px;
    }
    &.dg-confirm .dialog-body:after {
        @extend .icon-help-circled, :before;
        color: $information;
    }
    &.dg-success .dialog-body:after {
        @extend .icon-ok-circled, :before;
        color: $green;
    }
    &.dg-error .dialog-body:after {
        @extend .icon-minus-circled, :before;
        color: $red;
    }
    &.dg-alert .dialog-body:after {
        @extend .icon-attention-circled, :before;
        color: $warning-yellow;
    }
}
.dialog-nav-container {
    @include group;
    display: table;
    width: 100%;
}
.dialog-nav {
    display: table-cell;
    vertical-align: top;
    background: $grey-bg-1;
    width: 170px;
    border-right: 1px solid $border-1;
    .nav-item {
        cursor: pointer;
        margin: 15px 20px;
        &, a {
            display: block;
            color: $base-font-colour;
            text-decoration: none;
        }
        &:hover a,
        &.active a {
            color: $verint-blue;
        }
        &.active a {
            font-weight: bold;
        }
        a {
            &:before {
                margin-right: 5px;
            }
        }
    }
}
.dialog-body-container {
    display: table-cell;
    vertical-align: top;
    min-width: 400px;
    .dialog-errors,
    .dialog-success {
        margin: 15px 15px 0px;
    }
}
.dialog-nav-bar {
    @include remove-list;
    margin: 10px;
    background: $white;
    border: 1px solid $border-1;
}
.dialog.multi-select-dialog {
    .dialog-body {
        width: 655px;
    }
}
/* Add channels dialog */
.channels-dialog {
    margin-bottom: 15px;
    .container {
        @include group;
    }
    .content {
        @include group;
        float: left;
    }
    .channel-col {
        float: left;
        width: 275px;
        margin-left: 20px;
        &:first-child {
            margin-left: 0px;
        }
    }
    .col-end {
        margin-right: 0px;
    }
    .item-list {
        @include remove-list;
        line-height: 25px;
        max-height: 400px;
        overflow: auto;
        p {
            line-height: 25px;
            display: inline;
            vertical-align: middle;
            margin-top: 0;
        }
        li {
            @include ellipsis;
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0;
            }
            &.progress {
                cursor: progress;
                background: $grey-bg-1;
            }
        }
        input[type=checkbox] {
            margin-right: 3px;
        }
    }
    .white-box {
        padding: 10px;
        background: $white;
        border: 1px solid  $border-1;
        p:last-child {
            margin-bottom: 10px;
        }
        margin-bottom: 10px;
    }
    .white-box.progress {
        cursor: progress;
    }
    .success {
        background: $green;
        border-color: $green;
        color: $white;
    }
    p.success {
        padding: 10px;
        margin-bottom: 0 !important;
    }
    p.success + .item-list {
        @include border-top-left-radius(0);
        @include border-top-right-radius(0);
        border-top: none;
    }
    .white-box.error {
        background-color: $orange;
        border-color: $orange-border;
        color: $white;
    }
    .change-fb {
        float: right;
        display: block;
    }
    .follow_container {
        @include group;
        padding-left: 0 !important;
        line-height: 23px;
        height: auto !important;
        label,
        input[type=checkbox] {
            line-height: 23px;
            display: inline;
            float: none !important;
        }
    }
    h2 {
        margin: 0 0 15px 0;
        font-size: 13px;
        color: $base-font-colour;
        font-weight: normal;
    }
    .source-image {
        width: 25px;
        height: 25px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        img {
            width: 25px;
            height: 25px;
            display: block;
        }
    }
    .fb_button {
        display: block;
        margin: 0 auto;
        width: 75px;
        height: 25px;
        background-image: url("../images/fb-login.png");
        text-indent: -9999px;
    }
    .login-area {
        text-align: center;
    }
    .settings-section-col {
        margin: 0;
        &:first-child {
            margin-top: 5px;
        }
    }
}

.dialog-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .dialog-background {
        background: $login-bg;
        opacity: 0.6;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.dialog-wrapper {
    border-right: solid 5px $dialog-border;
    border-bottom: solid 5px $dialog-border;
    border-left: solid 5px $dialog-border;
}

/* Overrides for react-based dialogs */
.dialog.react-dialog {
    float: none;
    position: relative;
    display: block;
    margin: 15% auto;
    max-width: 600px;
}

.dialog-body-searchable {
    height: 293px;
    padding: 0;
}
