.dialog-message.message-by-other {
    background: $grey-bg-2;
    border: 1px solid  $border-1;
    padding: 10px;
    max-width: 420px;
    @include border-radius(3px);
    .message-by-other {
        margin-top: 0px;
    }
    .mbo-text {
        max-height: 87px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .profile-picture {
        left: 10px;
        top: 15px;
    }
    margin-top: 0px;
    margin-bottom: 15px;
}
.dialog-retweet-or-like {
    .icon-retweet:before {
        color: $green-light;
    }
    .icon-twitter-like:before {
        color: $twitter-like-red;
    }
    .icon-heart:before {
        color: $heart-red;
    }
}
.dialog.dialog-save-to-clipboard {
    width: 350px;
    .dialog-header h4:before {
        margin-left: 10px;
    }
    .clipping-text {
        max-height: 250px;
        min-height: 100px;
    }
}
.dialog-reauth {
    max-width: 450px;
    .reauth-account {
        font-size: 14px;
        img {
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}
.dialog.dialog-add-reminder {
    width: 450px;
    textarea {
        background: $yellow-pale;
        min-height: 100px;
    }
}
