.main-nav {
    background: $login-bg;
    height: $main-nav-height;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    /* z-index to be above than converaiton panel */
    z-index: 4;

    & > div {
        height: $main-nav-height + 20px;
        overflow-x: auto;
        width: 100%;
    }
    & > div > div {
        display: flex;
        height: $main-nav-height;
        min-width: 1024px;
        width: 100%;
    }
    .logo {
        display: block;
        height: 28px;
        padding-right: 2px;
    }
    ul {
        @include remove-list;
    }
}
.main-nav-bar {
    align-items: center;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    float: left;
    height: $main-nav-height;

    li.nav-item {
        flex-shrink: 0;
        float: left;
        font-size: 14px;
        padding: 0 15px;

        &, a {
            color: $base-font-colour;
            display: block;
            text-decoration: none;
        }
        &:hover a,
        &.active a {
            color: $white;
        }
        a {
            &:before {
                margin-right: 5px;
            }
        }
        &.profile-email {
            @include ellipsis;
            /* Different widths */
            @include min-width(1090px) {
                max-width: 285px;
            }
            @include min-width(1130px) {
                max-width: 325px;
            }
        }
        &.profile-logout {
            padding: 12px 10px;
            a:before {
                font-size: 17px;
            }
        }
        &.logged-in-as {
            background-color: $orange;
            color: $white;
            a {
                color: $white;
            }
        }
        /* uiv6 */
        &.old-design-button {
            height: 30px;
            margin: 7px 0;
            padding: 0;
            .button {
                background-color: $legacy-nav-old-design-button;
                border-color: $legacy-nav-old-design-button;
                color: $legacy-nav-old-design-button-text;
                &:before {
                    color: $legacy-nav-old-design-button-text;
                }
                &:hover {
                    background-color: $legacy-nav-old-design-button-hover;
                    border-color: $legacy-nav-old-design-button-hover;
                }
            }
        }
    }
}
.main-nav-bar.main-nav-right {
    align-content: center;
    flex-grow: 1;
    flex-shrink: 1;
    flex-wrap: wrap-reverse;
    float: right;
    justify-content: flex-end;
    margin-left: 10px;

    li.nav-item {
        flex-shrink: 1;
        font-size: 13px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (max-width: 1200px) {
    .main-nav-bar li.nav-item {
        font-size: 13px;
        padding-right: 5px;
    }
    .main-nav-bar.main-nav-right li.nav-item {
        font-size: 12px;
    }
}

.secondary-nav {
    background: $white;
    left: $verint-header-logo-width;
    position: fixed;
    right: 0;
    top: $main-nav-top-row-height;
}

.tertiary-nav {
    background: $grey-bg-2;
    left: 0;
    position: fixed;
    right: 0;
    top: $main-nav-height;
}

.secondary-nav, .tertiary-nav {
    border-bottom: 1px solid $border-1;
    height: $secondary-nav-height;
    min-width: 1024px;
    /* z-index to be above than converaiton panel, and above preview list*/
    z-index: 5;
    ul {
        @include remove-list;
    }
}

.secondary-nav-bar, .tertiary-nav-bar {
    float: left;
    li.nav-item {
        align-items: center;
        display: flex;
        float: left;
        height: $secondary-nav-height;
        padding: 0 20px;
        &:first-child {
            padding-left: 15px;
        }
        a {
            color: $base-font-colour;
            display: block;
            height: 30px;
            line-height: $secondary-nav-internal-height;
            max-width: 300px;
            text-decoration: none;
            @include ellipsis;
        }
        &:hover a,
        &.active a {
            color: $verint-blue;
            .section-name:before {
                color: $verint-blue;
            }
        }
        &.active {
            position: relative;
            &:after {
                background: $verint-blue;
                bottom: 0px;
                content: "";
                height: 2px;
                left: 20px;
                position: absolute;
                right: 20px;
            }
            &:first-child:after {
                left: 17px;
            }
        }
        .active,
        &.active {
            .section-count {
                background: $white;
                border-color: $blue-focus;
                &, span:before {
                    color: $verint-blue;
                }
            }
        }
        &.no-padding {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .search,
    .section-count,
    .section-name {
        display: inline-block;
        @include ellipsis;
    }
    .search + .section-count {
        display: none;
    }
    .search.has-results .input {
        background: $white;
    }
    .search.has-results + .section-count {
        display: inline-block;
    }
    .section-count {
        background-color: $white;
        border: 1px solid $border-1;
        line-height: 27px;
        margin-left: 10px;
        padding: 0 6px;
        @include border-radius(3px);
        span:before {
            color: $base-font-colour;
        }
        span {
            margin-left: 4px;
            &:first-child {
                margin-left: 0px;
            }
        }
    }
    .section-name:before {
        color: $base-font-colour;
        font-size: 16px;
        margin-right: 10px;
        vertical-align: top;
    }
}
.secondary-nav-bar.secondary-nav-right, .tertiary-nav-bar.tertiary-nav-right {
    position: absolute;
    right: 0;
    .nav-item:first-child {
        padding-left: 0;
        &.active:after {
            left: 0px;
        }
    }
    .nav-item:last-child {
        padding-right: 15px;
        &.active:after {
            right: 15px;
        }
    }
}
.side-nav {
    float: left;
    margin-top: $main-nav-height;
    width: $side-nav-width;
    .nav-item {
        cursor: pointer;
        margin: 15px 20px;
        &, a {
            color: $base-font-colour;
            display: block;
            text-decoration: none;
        }
        &:hover a,
        &.active a {
            color: $verint-blue;
        }
        &.active a {
            font-weight: bold;
        }
        a {
            &:before {
                margin-right: 5px;
            }
        }
    }
}
.side-nav-heading {
    color: $button-text;
    font-size: 14px;
    font-weight: bold;
    margin: 20px 35px 10px;
}
.side-nav-bar {
    @include remove-list;
    background: $white;
    border: 1px solid $border-1;
    margin: 10px 15px;
    @include border-radius(3px);
}

.side-nav-dg {
    float: left;
    margin-left: 10px;
    width: 200px;
    ul {
        @include remove-list;
    }
    &.nav-fixed {
        bottom: 0;
        overflow-y: auto;
        position: fixed;
        top: 80px;
    }
    .nav-item {
        cursor: pointer;
        margin: 10px 0px;
        &:not(.collapsable) {
            &.active {
                border-left: 2px solid $verint-blue;
                color: $verint-blue;
                font-weight: bold;
                padding-left: 3px;
            }
            &:hover {
                color: $verint-blue;
            }
        }
        &.collapsable {
            ul {
                display: none;
            }
            .section-name:hover {
                color: $verint-blue;
            }
            &.active {
                .section-name {
                    color: $verint-blue;
                    font-weight: bold;
                }
            }
            &.active ul {
                display: block;
            }
        }
    }
    .sub-nav {
        font-size: 12px;
        margin-left: 10px;
        .nav-item {
            margin: 5px 0;
        }
    }
}
