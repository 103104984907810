@import "~compass-mixins/lib/compass.scss";
@import "~compass-mixins/lib/animate.scss";

/* 100% height mixing */
@mixin height-100 {
    min-height: 100%;
    height: 100%;
}
@mixin abs-height-100 ($top: 0px, $widthpx: null, $screen: false) {
    position: absolute;
    top: $top;
    bottom: 0px;
    height: auto;
    min-height: auto;
    @if $widthpx {
        width: $widthpx;
        left: 50%;
        margin-left: -$widthpx / 2;
    }
    @if $screen == true {
        @include screen-width(true);
    }
}

/* screen size */
@mixin max-width($width) {
    @media screen and (max-width: $width) { @content; }
}
@mixin min-width($width) {
    @media screen and (min-width: $width) { @content; }
}

/* Text collapse */
.collapsed-text {
    display: none;
}
@mixin collapse-text($screen-width, $selector) {
    @include max-width($screen-width) {
        .#{$selector} {
            .collapsed-text {
                display: inline;
            }
            .full-text {
                display: none;
            }
        }
    }
}
@mixin hide-text($screen-width, $selector) {
    @include max-width($screen-width) {
        .#{$selector} {
            .collapsed-text {
                display: none;
            }
            .full-text {
                display: none;
            }
        }
    }
}
@mixin set-max-width-at($screen-width, $selector, $max-width) {
    @include max-width($screen-width) {
        .#{$selector} {
            max-width: $max-width;
        }
    }
}

/* Clear child elements */
@mixin group {
    &:after {
        content:"";
        display:table;
        clear:both;
    }
}

/* Helpers */
@mixin remove-list {
    margin: 0px;
    list-style: none;
    padding-left: 0px;
}

@mixin link {
    &, &:visited, &:active {
        color: $verint-blue;
    }
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

@mixin antialiased {
    -webkit-font-smoothing: antialiased;
}

@mixin fade-end ($color: white) {
    @include background-image(linear-gradient(left, rgba(255,255,255,0) 0%, $color 80%, $color 100%));
    pointer-events: none;
}

/* checkboxes */
@mixin checkboxes($padding-left: 0, $padding-right: 0) {
    $background-left: -3px;
    .input.checkbox {
        margin-right: 6px;
    }
    .input.checkbox + label {
        @include user-select(none);
        cursor: pointer;
        vertical-align: text-top;
        padding: 0 $padding-right 0 $padding-left;
        &:not(:empty) {
            padding-left: $padding-left;
            margin-right: 5px;
        }
        @if ($padding-left != 0) {
            margin-left: 0 - $padding-left;
        }
        display: inline-block;
        /* min height-width so empty label can be used and still show background */
        min-width: 13px;
        min-height: 14px;
        line-height: 14px;
        font-weight: normal;
        margin-bottom: 0;
    }
}

@mixin radios($padding-left: 0, $padding-right: 0) {
    $background-left: -3px + $padding-left;
    .input.radio {
        display: none;
    }
    .input.radio + label {
        @include user-select(none);
        cursor: pointer;
        vertical-align: middle;
        padding: 0 $padding-right 0 $padding-left;
        &:not(:empty) {
            padding-left: $padding-left + 18px;
            margin-right: 5px;
        }
        @if ($padding-left != 0) {
            margin-left: 0 - $padding-left;
        }
        display: inline-block;
        min-width: 13px;
        height: 14px;
        line-height: 14px;
        font-weight: normal;
        background: url('../images/inputs/radio.png') no-repeat $background-left -5px;
        margin-bottom: 0;
    }
    .input.radio + label:active {
        background: url('../images/inputs/radio.png') no-repeat $background-left -30px;
    }
    .input.radio:checked + label {
        background: url('../images/inputs/radio.png') no-repeat $background-left -55px;
    }
    .input.radio:checked + label:active {
        background: url('../images/inputs/radio.png') no-repeat $background-left -80px;
    }
}

@mixin three-jump($spinkit-spinner-color: $border-1) {
  $spinkit-spinner-margin: 0 auto;
  &.sk-spinner {
    margin: $spinkit-spinner-margin;
    width: 70px;
    text-align: center;
  }

  div {
    width: 16px;
    height: 16px;
    background-color: $spinkit-spinner-color;

    border-radius: 100%;
    display: inline-block;
    @include animation(sk-threeJumpDelay 1.8s infinite ease-out);
    /* Prevent first frame from flickering when animation starts */
    @include animation-fill-mode(both);
  }

  .bounce1 {
    @include animation-delay(0s);
  }

  .bounce2 {
    @include animation-delay(0.175s);
  }

  .bounce3 {
    @include animation-delay(0.350s);
  }
}

@include keyframes(sk-threeJumpDelay) {
  0% {
    @include transform(translateY(0px));
  } 16.0% {
    @include transform(translateY(-10px));
  } 28.75% {
    @include transform(translateY(0px));
  } 100% {
    @include transform(translateY(0px));
  }
}
