@use '../../../styles/fonts/fontello/css/fontello-codes.scss';

.widgets-grid {
    min-width: 1024px;
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 0.7%;
    padding-left: 1.2%;
    padding-right: 1.2%;
}
.widgets-grid .global-notification {
    margin-top: 10px;
}
.widgets-gridster {
    @include remove-list;
    margin-left: -7.5px;
    margin-top: 7.5px;
    position: relative;
    .widget {
        position: absolute;
        min-width: 405px;
    }
    .widget[data-sizey="2"] {
        .widget-body {
            height: 362px;
        }
    }
}
.widget {
    border: 1px solid $border-1;
    @include border-radius(3px);
}
.widget-info {
    @include transform(translateY(-50%));
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    padding: 0 35px;
    color: $button-text;
    &:before {
        margin-right: 5px;
    }
    &.widget-greeting-info {
        width: 520px;
        margin: 0 auto;
        h1 {
            font-weight: normal;
            margin-top: 30px;
            margin-bottom: 25px;
        }
        p {
            margin-bottom: 40px;
        }
        .widget-greeting-dismiss {
            color: $information;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
.widget-title {
    position: relative;
    background-color: $grey-bg-2;
    border-bottom: 1px solid $border-1;
    @include border-top-left-radius(3px);
    @include border-top-right-radius(3px);
    padding: 14px 15px;
    font-size: 14px;
    .widget.widget-large & {
        font-size: 16px;
    }
    &:hover .widget-menu {
        display: block;
    }
    .widget-toolbar {
        float: right;
        margin-right: 15px;
        font-size: 13px;
        button {
            margin-top: -5px;
        }
    }
    .widget-help {
        float: right;
        a:hover {
            text-decoration: none;
        }
        .icon-help-circled {
            color: $base-font-colour;
        }
    }
    .widget-close {
        float: right;
        a:hover {
            text-decoration: none;
        }
        .icon-cancel {
            cursor: pointer;
            color: $base-font-colour;
        }
    }

    &.widget-title-seamless {
        background-color: $white;
        border-bottom: none;
        overflow: hidden;
    }
}
.widget-footer {
    height: 100%;
    background-color: $white;
    p {
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 15px;
        margin-left: 15px;
        a {
            text-decoration: underline;
            cursor: pointer;
        }

        .icon-attention {
            margin-right: 5px;
            color: $legacy-analytics-widget-footer-attention-icon;
        }
    }
}
.widget-menu {
    display: none;
    &.dropdown-open {
        display: block;
    }
    position: absolute;
    right: 13px;
    top: 11px;
    font-size: $base-font-size;
    .has-dropdown {
        cursor: pointer;
        position: relative;
        padding: 5px;
        min-width: 27px;
        min-height: 24px;
        background: $grey-bg-2;
        color: $base-font-colour;
    }
    > .dropdown-menu {
        overflow: visible;
        max-height: none;
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: -20px;
        width: 20px;
        height: 21px;
        @include fade-end($grey-bg-2);
    }
}
.widget-title-meta {
    position: absolute;
    right: 13px;
    line-height: 16px;
    font-size: $base-font-size;
    top: 15px;
    color: $button-text;
}
.widget-meta-item {
    margin-left: 10px;
    &:first-child {
        margin-left: 0;
    }
}
.widget-channels {
    @include remove-list;
    @include ellipsis;
    position: relative;
    margin-top: -3px;
    margin-bottom: 10px;
    max-width: 373px;
    color: $base-font-colour;
    li {
        @include ellipsis;
        max-width: 95px;
        display: inline-block;
        margin-left: 10px;
        &:first-child {
            margin-left: 0;
        }
    }
    &.channel-count-0 li,
    &.channel-count-1 li {
        max-width: 373px;
    }
    &.channel-count-2 li {
        max-width: 175px;
    }
    &.channel-count-3 li {
        max-width: 138px;
    }
}
.widget-channels-more {
    position: absolute;
    right: 0;
    padding-right: 13px;
    &:before {
        position: absolute;
        right: 0;
        font-size: 16px;
    }
}
.widget-body {
    background: $white;
    @include border-bottom-left-radius(3px);
    @include border-bottom-right-radius(3px);
    padding: 15px;
    min-height: 362px;
    overflow: auto;
    .widget.widget-large & {
        min-height: 375px;
    }
    .widget.widget-half-height & {
        min-height: 150px;
    }

    /* position and z-index are so the metric-bar
       class works correctly */
    position: relative;
    z-index: 0;
}
.widget-greeting-body {
    overflow: hidden;
}
/* Widget nav */
.widget-nav-bar {
    @include remove-list;
    display: inline-block;
    color: $base-font-colour;
    font-size: $base-font-size;
    margin-left: 10px;
    li {
        display: inline-block;
        cursor: pointer;
        margin-left: 20px;
        position: relative;
        &.active,
        &:hover {
            color: $verint-blue;
        }
        &.active {
            &:after {
                content: "";
                width: 100%;
                height: 2px;
                background: $verint-blue;
                position: absolute;
                left: -4px;
                bottom: -15px;
                padding: 0 4px;
            }
        }
    }
}
/* Specific widgets */
.widget-rs-graph {
    position: relative;
}
.widget-rs-zoom-helper {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}
.widget-rs-zoom {
    width: 14px;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    color: $button-text;
}
.widget-rs-zoom-helper:hover {
    .widget-rs-zoom-tip {
        display: block;
    }
}
.widget-rs-zoom-tip.qtip.qtip-tipsy {
    left: auto;
    top: 100%;
    right: 0px;
    padding: 10px;
    margin-top: 5px;
    white-space: nowrap;
}

/* SortableTable */
.reactable-filterer {
    position: absolute;
    top: 15px;
    left: 15px;
    td {
        @extend .icon-search-1;
        &:before {
            position: absolute;
            font-family: 'fontello';
            top: 6px;
            left: 8px;
            font-size: 17px;
            color: $base-font-colour;
        }
    }
}
.reactable-filter-input {
    padding-left: 25px;
    width: 250px;
}
.reactable-pagination {
    position: absolute;
    top: 15px;
    right: 85px;
    td {
        padding: 0 !important;
        a:only-child {
            display: none;
        }
    }
}
.widget-click-category {
    cursor: pointer;
    &:hover {
        color: $verint-blue;
    }
}
.widget-exit-drill-down {
    margin-left: 5px;
    cursor: pointer;
    color: $verint-blue;
    font-size: 12px;
    &:hover {
        text-decoration: underline;
    }
}
.widget-table-paginated {
    margin-top: 42px;
}
.widget-showall.button {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 60px;
    min-width: 0px;
    &:active {
        position: absolute !important;
        top: 16px !important;
    }
}
/* override styles, most styles in _buttons.scss */
.reactable-previous-page,
.reactable-next-page,
.reactable-page-button {
    display: inline-block;
    font-size: 13px;
    text-decoration: none !important;
    min-width: 0px !important;
    @include border-radius(0);
    margin-left: -1px;
    position: relative;
    &:hover {
        z-index: 2;
    }
    &:first-child {
        @include border-top-left-radius(3px);
        @include border-bottom-left-radius(3px);
    }
    &:last-child {
        @include border-top-right-radius(3px);
        @include border-bottom-right-radius(3px);
    }
}
.reactable-current-page:focus,
.reactable-current-page:hover,
.reactable-current-page {
    color: $verint-blue;
    background: $verint-blue-alpha-10;
    border-color: $verint-blue;
    z-index: 2;
}

/* CSAT / NPS Widget */

.widget-csat {
    font-family: 'Arial', sans-serif;
    display: flex;
    align-items: center;
    margin: 50px;

    & > .widget-csat-piechart {
        width: 280px;

        & > .widget-csat-title {
            text-align: center;
            font-size: 20px;
            margin-bottom: 15px;
        }

        & > .widget-csat-main {
            position: relative;

            .widget-csat-piechart-centre {
                display: inline-flex;
                flex-direction: column;
                justify-content: center;
                position: absolute;
                top: 85px;
                bottom: 85px;
                border-radius: 100%;
                width: 85%;
            }

            %widget-csat-piechart-middle-base {
                display: inline-flex;
                justify-content: flex-end;
                width: 50%;
            }

            .widget-csat-piechart-middle-score {
                @extend %widget-csat-piechart-middle-base;
                font-weight: bold;
                font-size: 35px;
                width: 65%;

            }

            .widget-csat-piechart-middle-average {
                @extend %widget-csat-piechart-middle-base;
                font-weight: 600;
                font-size: 24px;
                width: 65%;

            }

            %widget-csat-piechart-middle-change-base {
                display: inline-block;
                color: $button-text;
                font-size: 12px;
                font-weight: bold;
            }

            .widget-csat-piechart-middle-change {
                @extend %widget-csat-piechart-middle-change-base;
                padding-left: 5px;

                .icon-up-big:before {
                    color: $legacy-font-csat-change-up-icon;
                }

                .icon-down-big:before {
                    color: $legacy-font-csat-change-down-icon;
                }

                .icon-right-big:before {
                    color: $legacy-font-csat-no-change-icon;
                }
            }

            .widget-csat-piechart-middle-change-text {
                @extend %widget-csat-piechart-middle-change-base;
            }

            .widget-csat-piechart-middle-no-change-text {
                @extend %widget-csat-piechart-middle-change-base;
                white-space: pre-line;
                position: relative;
                top: 6px;
            }
        }

        & > .widget-csat-footer {
            text-align: center;
            font-size: 11px;
            margin-top: 25px;
        }
    }

    & > .widget-csat-breakdown {
        $path: 'images/analytics/csat';

        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 90px;

        & > .widget-csat-label {
            line-height: 20px;
            font-size: 11px;
            color: $grey-3;
        }

        & > [class^=widget-csat-score-] {
            display: flex;
            align-items: center;
            height: 40px;
            cursor: pointer;

            & > .widget-csat-icon {
                width: 30px;
                height: 30px;
                margin-left: -40px;
                margin-right: 10px;
            }

            & > .widget-csat-bar-outer {
                background-color: $legacy-analytics-widget-csat-bar-outer;
                width: 140px;

                &, & > .widget-csat-bar-inner {
                    border-radius: 100px;
                    height: 9px;
                }
            }

            & > .widget-csat-metrics {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 20px;
                text-align: right;

                & > .widget-csat-percentage {
                    font-size: 14px;
                    font-weight: bold;
                }

                & > .widget-csat-count {
                    font-size: 10px;
                    color: $grey-3;
                }
            }
        }

        & > .widget-csat-score-1 {
            .widget-csat-bar-inner { background-color: $legacy-font-csat-change-down-icon; }
            .widget-csat-icon { background-image: url('../../#{$path}/score1.png'); }
            .widget-csat-percentage { color: $legacy-font-csat-change-down-icon; }
        }
        & > .widget-csat-score-2 {
            .widget-csat-bar-inner { background-color: $legacy-analytics-widget-score-2; }
            .widget-csat-icon { background-image: url('../../#{$path}/score2.png'); }
            .widget-csat-percentage { color: $legacy-analytics-widget-score-2; }
        }
        & > .widget-csat-score-3 {
            .widget-csat-bar-inner { background-color: $legacy-analytics-widget-score-3; }
            .widget-csat-icon { background-image: url('../../#{$path}/score3.png'); }
            .widget-csat-percentage { color: $legacy-analytics-widget-score-3; }
        }
        & > .widget-csat-score-4 {
            .widget-csat-bar-inner { background-color: $legacy-analytics-widget-score-4; }
            .widget-csat-icon { background-image: url('../../#{$path}/score4.png'); }
            .widget-csat-percentage { color: $legacy-analytics-widget-score-4; }
        }
        & > .widget-csat-score-5 {
            .widget-csat-bar-inner { background-color: $legacy-font-csat-change-up-icon; }
            .widget-csat-icon { background-image: url('../../#{$path}/score5.png'); }
            .widget-csat-percentage { color: $legacy-font-csat-change-up-icon; }
        }

    }

    & >.widget-feedback-box-feedback {

        & > .widget-feedback-box-heading {
            padding: 10px;
            border-bottom: 1px solid $border-1;
            background-color: $grey-bg-2;
            border-radius: 3px 3px 0 0;

            & > %widget-heading-score-title-base {
                font-weight: bold;
            }

            & > .widget-csat-heading-5 {
                @extend %widget-heading-score-title-base;
                color: $legacy-font-csat-change-up-icon;
            }

            & > .widget-csat-heading-4 {
                @extend %widget-heading-score-title-base;
                color: $legacy-analytics-widget-score-4;
            }

            & > .widget-csat-heading-3 {
                @extend %widget-heading-score-title-base;
                color: $legacy-analytics-widget-score-3;
            }

            & > .widget-csat-heading-2 {
                @extend %widget-heading-score-title-base;
                color: $legacy-analytics-widget-score-2;
            }

            & > .widget-csat-heading-1 {
                @extend %widget-heading-score-title-base;
                color: $legacy-font-csat-change-down-icon;
            }
        }

        & > .widget-feedback-box-arrow {
            &.widget-csat-score-1 { top: 200px; }
            &.widget-csat-score-2 { top: 160px; }
            &.widget-csat-score-3 { top: 120px; }
            &.widget-csat-score-4 { top: 80px; }
            &.widget-csat-score-5 { top: 40px; }
        }
    }
}

.widget-nps-no-survey-response-footnote {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 10px;
    font-size: 10px;
}

.widget-nps {
    font-family: 'Arial', sans-serif;
    display: flex;
    align-items: center;
    margin: 25px;
    margin-right: 60px;

    & > .widget-nps-donut-chart {
        width: 320px;

        & > %widget-nps-title-base {
            text-align: center;
        }

        & > .widget-nps-title {
            @extend %widget-nps-title-base;
            font-size: 20px;
        }

        & > .widget-nps-footer {
            font-weight: bold;
            text-align: center;
            font-size: 13px;
        }

        & > .widget-nps-main {
            position: relative;
            height: 300px;
            width: 320px;

            .widget-nps-donut-chart-centre {
                width: 220px;
                height: 220px;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -110px;
                margin-left: -110px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            %widget-nps-donut-chart-middle-base {
                display: inline-flex;
                justify-content: flex-end;
                width: 50%;
            }

            .widget-nps-donut-chart-middle-score {
                @extend %widget-nps-donut-chart-middle-base;
                font-weight: bold;
                font-size: 50px;
                width: 60%;
            }

            .widget-nps-donut-chart-middle-score-100 {
                @extend .widget-nps-donut-chart-middle-score;
                font-size: 40px;
            }

            .widget-nps-donut-chart-middle-average {
                @extend %widget-nps-donut-chart-middle-base;
                font-weight: bold;
                font-size: 22px;
                width: 60%;

            }

            %widget-nps-donut-chart-middle-change-base {
                display: inline-block;
                color: $button-text;
                font-size: 19px;
            }

            .widget-nps-donut-chart-middle-change {
                @extend %widget-nps-donut-chart-middle-change-base;
                padding-left: 10px;

                .icon-up-big:before {
                    font-size: 17px;
                    color: $legacy-font-csat-change-up-icon;
                }

                .icon-down-big:before {
                    font-size: 17px;
                    color: $legacy-font-csat-change-down-icon;
                }

                .icon-right-big:before {
                    font-size: 17px;
                    color: $legacy-font-csat-no-change-icon;
                }
            }

            .widget-nps-donut-chart-middle-change-text {
                @extend %widget-nps-donut-chart-middle-change-base;
            }

            .widget-nps-donut-chart-middle-no-change-text {
                @extend %widget-nps-donut-chart-middle-change-base;
                white-space: pre-line;
                position: relative;
                top: 6px;
                font-size: 11px;
            }
        }
    }

    .widget-nps-breakdown {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: 70px;

        & > %widget-nps-breakdown-column-header-base {
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
            position: relative;
            width: 96%;
            font-weight: bold;
        }

        & > .widget-nps-breakdown-column-header-detractors {
            @extend %widget-nps-breakdown-column-header-base;
            bottom: 280px;
            color: $legacy-analytics-widget-score-detractors;
        }

        & > .widget-nps-breakdown-column-header-passives {
            @extend %widget-nps-breakdown-column-header-base;
            bottom: 205px;
            color: $legacy-analytics-widget-score-passives;
        }

        & >.widget-nps-breakdown-column-header-promoters {
            @extend %widget-nps-breakdown-column-header-base;
            bottom: 130px;
            color: $legacy-analytics-widget-score-promoters;
        }

        .widget-nps-breakdown-column-header-pointer {cursor: pointer;}

        .widget-nps-tooltip {
            position: absolute;
            display: inline-flex;
            flex-direction: column;
            align-items: flex-end;
            z-index: 1;

            & > .widget-nps-tooltip-container {
                flex-grow: 1;
                border: 1px solid $border-1;
                border-radius: 3px;
                box-shadow: 1px 1px 5px $border-1;
                margin-right: 10px;
                position: relative;
                display: flex;
                flex-direction: column;
                font-size: 11px;
                color: $button-text;
                align-items: flex-end;
                background: $white;

                .widget-nps-tooltip-content {
                    flex-grow: 1;
                    padding: 20px;

                    .feedback-breakdown-table {
                        width: 250px;
                        border-bottom: 1px solid $legacy-analytics-widget-table-light-grey;;
                        margin-bottom: 10px;
                    }

                    %feedback-breakdown-table-header-cell-base {
                        border-bottom: 1px solid $legacy-analytics-widget-table-light-grey;;
                        padding: 10px;
                        font-size: 13px;
                    }

                    .feedback-group-header-cell {
                        @extend %feedback-breakdown-table-header-cell-base;
                        text-align: left;
                        width: 60%;
                        color: $legacy-analytics-widget-table-dark-grey;
                    }

                    .percentage-header-cell {
                        @extend %feedback-breakdown-table-header-cell-base;
                        text-align: right;
                        width: 20%;
                        color: $border-1;
                    }

                    .score-header-cell {
                        @extend %feedback-breakdown-table-header-cell-base;
                        text-align: right;
                        width: 20%;
                        color: $legacy-analytics-widget-table-dark-grey;
                    }

                    %data-cell-base {
                        border-bottom: 1px solid $legacy-analytics-widget-table-light-grey;
                        padding: 10px;
                        font-size: 13px;

                    }

                    .percentage-data-cell {
                        @extend %data-cell-base;
                        text-align: right;
                        width: 20%;
                        color: $border-1;
                    }

                    .score-data-cell {
                        @extend %data-cell-base;
                        text-align: right;
                        width: 20%;
                        color: $legacy-analytics-widget-table-dark-grey;
                    }

                    .rating-data-cell {
                        @extend %data-cell-base;
                        text-align: left;
                        width: 60%;

                        & > .rating-cell-container {
                            display: inline-flex;
                            align-items: center;
                            color: $legacy-analytics-widget-table-dark-grey;

                            &> %rating-data-square {
                                display: inline-block;
                                width: 15px;
                                height: 15px;
                                margin-right: 5px;
                            }

                            & > .rating-data-square-score-0 {
                                @extend %rating-data-square;
                                background-color: $legacy-analytics-widget-rating-data-square-score-0;
                            }

                            & > .rating-data-square-score-1 {
                                @extend %rating-data-square;
                                background-color: $legacy-analytics-widget-rating-data-square-score-1;
                            }

                            & > .rating-data-square-score-2 {
                                @extend %rating-data-square;
                                background-color: $legacy-analytics-widget-rating-data-square-score-2;
                            }

                            & >.rating-data-square-score-3 {
                                @extend %rating-data-square;
                                background-color: $legacy-analytics-widget-rating-data-square-score-3;
                            }

                            & > .rating-data-square-score-4 {
                                @extend %rating-data-square;
                                background-color: $legacy-analytics-widget-rating-data-square-score-4;
                            }

                            & > .rating-data-square-score-5 {
                                @extend %rating-data-square;
                                background-color: $legacy-analytics-widget-rating-data-square-score-5;
                            }

                            & > .rating-data-square-score-6 {
                                @extend %rating-data-square;
                                background-color: $legacy-analytics-widget-rating-data-square-score-6;
                            }

                            & > .rating-data-square-score-7 {
                                @extend %rating-data-square;
                                background-color: $legacy-analytics-widget-rating-data-square-score-7;
                            }

                            & > .rating-data-square-score-8 {
                                @extend %rating-data-square;
                                background-color: $legacy-analytics-widget-rating-data-square-score-8;
                            }

                            & > .rating-data-square-score-9 {
                                @extend %rating-data-square;
                                background-color: $legacy-analytics-widget-rating-data-square-score-9;
                            }

                            & > .rating-data-square-score-10 {
                                @extend %rating-data-square;
                                background-color: $legacy-analytics-widget-rating-data-square-score-10;
                            }
                        }
                    }
                }
            }

            & > .widget-nps-tooltip-arrow {
                position: absolute;
                border-color: transparent;
                border-left-color: $border-1;
                top: 60px;

                &, & > .widget-nps-tooltip-arrow-inner {
                    border-width: 10px 0 10px 10px;
                    border-style: solid;
                }

                & > .widget-nps-tooltip-arrow-inner {
                    position: absolute;
                    top: -10px;
                    right: 1px;
                    border-color: transparent;
                    border-left-color: $white;
                }
            }
        }

        .feedback-breakdown-table-footer {
            font-size: 12px;
            color: $border-1;
            padding-top: 5px;
        }

        .widget-nps-tooltip-position-promoters { top: 168px; }
        .widget-nps-tooltip-position-passives { top: 77px; }
        .widget-nps-tooltip-position-detractors { top: -12px; }
    }

    & > .widget-feedback-box-feedback {
        /* Feedback box height matches donut chart height */
        height: 235px;
        top: 4px;

        & > .widget-feedback-box-heading {
            padding: 10px;
            border-bottom: 1px solid $border-1;
            border-radius: 3px 3px 0 0;

            & > .widget-nps-heading-promoters {
                color: $legacy-analytics-widget-score-promoters;
            }

            & > .widget-nps-heading-passives {
                color: $legacy-analytics-widget-score-passives;
            }

            & > .widget-nps-heading-detractors {
                color: $legacy-analytics-widget-score-detractors;
            }
        }
        & > .widget-feedback-box-arrow {
            &.widget-nps-score-detractors { top: 23px; }
            &.widget-nps-score-passives { top: 113px; }
            &.widget-nps-score-promoters { top: 205px; }
        }

        %widget-nps-feedback-box-score-base {
            font-size: 13px;
            margin-top: 10px;
            text-align: center;
            /* Width matches profile picture */
            width: 36px;
        }

        .widget-nps-feedback-box-score-promoters {
            @extend  %widget-nps-feedback-box-score-base;
            color: $legacy-analytics-widget-score-promoters;
        }
        .widget-nps-feedback-box-score-passives {
            @extend  %widget-nps-feedback-box-score-base;
            color: $legacy-analytics-widget-score-passives;
        }
        .widget-nps-feedback-box-score-detractors {
            @extend  %widget-nps-feedback-box-score-base;
            color: $legacy-analytics-widget-score-detractors;
        }
    }

    .widget-nps-footnote {
        position: absolute;
        bottom: 5px;
        text-align: center;
        width: 93%;
        font-size: 8px;
        color: $border-1;
    }
}

.widget-feedback-box-feedback {
    flex-grow: 1;
    height: 260px;
    border: 1px solid $border-1;
    border-radius: 3px;
    box-shadow: 5px 5px 20px $border-1;
    margin-left: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 11px;
    color: $button-text;

    & > .widget-feedback-box-arrow {
        position: absolute;
        left: -10px;
        border-color: transparent;
        border-right-color: $border-1;
        transition: top .2s ease-in-out;

        &, & > .widget-feedback-box-arrow-inner {
            border-width: 10px 10px 10px 0;
            border-style: solid;
        }

        & > .widget-feedback-box-arrow-inner {
            position: absolute;
            top: -10px;
            left: 1px;
            border-color: transparent;
            border-right-color: $white;
        }
    }

    & > .widget-feedback-box-content {
        flex-grow: 1;
        overflow-y: auto;

        & > .widget-feedback-box-item {
            padding: 15px;
            display: flex;

            &:not(:first-child) {
                border-top: 1px solid $grey-bg-2;
            }

            & > .widget-feedback-box-picture-score {
                display: flex;
                flex-direction: column;

                & > .widget-feedback-box-author-picture {
                    flex-shrink: 0;
                    width: 36px;
                    height: 36px;
                    border-radius: 100%;
                    margin-right: 15px;
                }
            }

            & > .widget-feedback-box-content {
                flex-grow: 1;
                line-height: 19px;

                & > a.widget-feedback-box-author {
                    display: block;
                    margin-bottom: 10px;
                    line-height: normal;
                    color: $button-text;

                    & > .widget-feedback-box-author-real-name {
                        font-weight: bold;
                        margin-right: 15px;
                    }
                }
            }
        }
    }
}
