$base-font-colour: var(--color-base-font-colour);
$button-text: var(--color-button-text);
$border-1: var(--color-border-1);
$border-2: var(--color-border-2);
$grey-bg-1: var(--color-grey-bg-1);
$grey-bg-2: var(--color-grey-bg-2);
$white: var(--color-white);
$white-text: var(--color-white-text);
$white-text-disabled: var(--color-white-text-disabled);
$white-text-disabled-2: var(--color-white-text-disabled-2);

$verint-logo-background: var(--color-verint-logo-background);
$verint-logo-foreground: var(--color-verint-logo-foreground);

$verint-blue: var(--color-verint-blue);
$green-light: var(--color-green-light);
$green-dark: var(--color-green-dark);
$highlight: var(--color-highlight);
$brown: var(--color-brown);
$yellow: var(--color-yellow);
$yellow-pale: var(--color-yellow-pale);

$assistant-outline: var(--color-assistant-outline);
$assistant-fill: var(--color-assistant-fill);
$assistant-info: var(--color-assistant-info);
$handover-bot: var(--color-handover-bot);
$bot-stroke: var(--color-bot-stroke);
$bot-fill: var(--color-bot-fill);
$handover-agent: var(--color-handover-agent);

$orange: var(--color-orange);
$information: var(--color-information);
$dialog-background: var(--color-dialog-background);
$warning-yellow: var(--color-warning-yellow);

$form-border: var(--color-form-border);
$form-placeholder: var(--color-form-placeholder);
$form-disabled-text: var(--color-form-disabled-text);

$grey-1: var(--color-grey-1);
$grey-2: var(--color-grey-2);
$grey-3: var(--color-grey-3);
$grey-4: var(--color-grey-4);
$grey-5: var(--color-grey-5);

$blue-highlight-1: var(--color-blue-highlight-1);
$blue-lowlight-1: var(--color-blue-lowlight-1);
$blue-lowlight-2: var(--color-blue-lowlight-2);
$blue-focus: var(--color-blue-focus);

$grey-button: var(--color-grey-button);
$grey-hover: var(--color-grey-hover);
$grey-active: var(--color-grey-active);
$grey-disabled: var(--color-grey-disabled);

$blue-hover: var(--color-blue-hover);
$blue-active: var(--color-blue-active);
$blue-disabled: var(--color-blue-disabled);

$red: var(--color-red);
$red-hover: var(--color-red-hover);
$red-active: var(--color-red-active);
$red-disabled: var(--color-red-disabled);

$green: var(--color-green);
$green-hover: var(--color-green-hover);
$green-active: var(--color-green-active);
$green-disabled: var(--color-green-disabled);

$green-light-hover: var(--color-green-light-hover);
$green-light-border: var(--color-green-light-border);
$green-light-disabled: var(--color-green-light-disabled);

$orange-hover: var(--color-orange-hover);
$orange-active: var(--color-orange-active);
$orange-disabled: var(--color-orange-disabled);

$dark-blue: var(--color-dark-blue);
$dark-blue-hover: var(--color-dark-blue-hover);
$dark-blue-active: var(--color-dark-blue-active);
$dark-blue-disabled: var(--color-dark-blue-disabled);

$button-yellow: var(--color-button-yellow);
$button-yellow-hover: var(--color-button-yellow-hover);
$button-yellow-active: var(--color-button-yellow-active);
$button-yellow-disabled: var(--color-button-yellow-disabled);

$purple: var(--color-purple);
$purple-hover: var(--color-purple-hover);
$purple-active: var(--color-purple-active);
$purple-disabled: var(--color-purple-disabled);

$tag-red: var(--color-tag-red);
$tag-orange: var(--color-tag-orange);
$tag-yellow: var(--color-tag-yellow);
$tag-green: var(--color-tag-green);
$tag-aqua: var(--color-tag-aqua);
$tag-blue: var(--color-tag-blue);
$tag-pink: var(--color-tag-pink);

$login-bg: var(--color-login-bg);

$play-button-icon: var(--color-play-button-icon);
$white-label-text: var(--color-white-label-text);
$default-selector-button-bg: var(--color-default-selector-button-bg);
$convo-card-bg: var(--color-convo-card-bg);
$convo-card-bg-selected: var(--color-convo-card-bg-selected);
$action-bar-bg: var(--color-action-bar-bg);
$meta-bar-bg: var(--color-meta-bar-bg);
$wide-bar-bg: var(--color-wide-bar-bg);
$dropdown-bg: var(--color-dropdown-bg);
$dropdown-highlight: var(--color-dropdown-highlight);

$dialog-header: var(--color-dialog-header);
$dialog-border: var(--color-dialog-border);
$dialog-footer: var(--color-dialog-footer);

$scheme: var(--color-scheme);

$label-text: var(--color-label-text);
$alert-text: var(--color-alert-text);
$tag-text: var(--color-tag-text);
$tooltip-text: var(--color-tooltip-text);

$form-checkbox-bg: var(--color-form-checkbox-bg);
$form-checkbox-bg-active: var(--color-form-checkbox-bg-active);
$form-checkbox-bg-disabled: var(--color-form-checkbox-bg-disabled);
$form-checkbox-blue: var(--color-form-checkbox-blue);
$form-checkbox-blue-active: var(--color-form-checkbox-blue-active);
$form-checkbox-blue-disabled: var(--color-form-checkbox-blue-disabled);
$form-checkbox-border: var(--color-form-checkbox-border);
$form-checkbox-border-hover: var(--color-form-checkbox-border-hover);
$form-checkbox-border-active: var(--color-form-checkbox-border-active);
$form-checkbox-border-disabled: var(--color-form-checkbox-border-disabled);

$form-on-off-switch-blue: var(--color-form-checkbox-blue);
$form-on-off-switch-text: var(--color-form-on-off-switch-text);
$form-on-off-switch-white-text: var(--color-form-on-off-switch-white-text);
$form-on-off-switch-handle: var(--color-form-on-off-switch-handle);
$form-on-off-switch-handle-active: var(--color-form-on-off-switch-handle-active);
$form-on-off-switch-handle-disabled: var(--color-form-on-off-switch-handle-disabled);

$slider-bar-bg: var(--color-slider-bar-bg);
$slider-bar-bg-disabled: var(--color-slider-bar-bg-disabled);
$slider-bar-border: var(--color-slider-bar-border);
$slider-bar-border-disabled: var(--color-slider-bar-border-disabled);
$slider-blue: var(--color-slider-blue);
$slider-blue-disabled: var(--color-slider-blue-disabled);
$slider-arrow: var(--color-slider-arrow);
$slider-arrow-hover: var(--color-slider-arrow-hover);
$slider-arrow-active: var(--color-slider-arrow-active);
$slider-arrow-disabled: var(--color-slider-arrow-disabled);
$slider-notch: var(--color-slider-notch);
$slider-notch-disabled: var(--color-slider-notch-disabled);
$slider-thumb-bg: var(--color-slider-thumb-bg);
$slider-thumb-inner: var(--color-slider-thumb-inner);
$slider-thumb-inner-hover: var(--color-slider-thumb-inner-hover);
$slider-thumb-inner-active: var(--color-slider-thumb-inner-active);
$slider-thumb-inner-disabled: var(--color-slider-thumb-inner-disabled);
$slider-thumb-border: var(--color-slider-thumb-border);
$slider-thumb-border-hover: var(--color-slider-thumb-border-hover);
$slider-thumb-border-active: var(--color-slider-thumb-border-active);
$slider-thumb-border-disabled: var(--color-slider-thumb-border-disabled);

$selector-border: var(--color-selector-border);
$form-on-off-switch-text-disabled: var(--color-form-on-off-switch-text-disabled);

$clipboard-selected-text: var(--color-clipboard-selected-text);
$tag-text-unselected: var(--color-tag-text-unselected);
$clipping-preview-text: var(--color-clipping-preview-text);

$text-highlighter: var(--color-text-highlighter);

// Third Party Brand colours

$youtube-color: var(--color-youtube-color);
$twitter-blue: var(--color-twitter-blue);
$facebook-blue: var(--color-facebook-blue);
$google-plus-red: var(--color-google-plus-red);
$google-my-business-blue: var(--color-google-my-business-blue);
$instagram-blue: var(--color-instagram-blue);
$synthesio-red: var(--color-synthesio-red);
$wechat-green: var(--color-wechat-green);
$whatsapp-green: var(--color-whatsapp-green);
$abc-blue: var(--color-abc-blue);
$sidepanel-shopify-green: var(--color-sidepanel-shopify-green);
$sidepanel-shopify-unfulfilled: var(--color-sidepanel-shopify-unfulfilled);
$legacy-button-google-plus: var(--color-legacy-button-google-plus);
$legacy-button-google-plus-icon: var(--color-legacy-button-google-plus-icon);
$legacy-button-google-plus-border: var(--color-legacy-button-google-plus-border);
$twitter-like-red: var(--color-twitter-like-red);


// Old colours still in use

$black-000: #000;
$black-000-alpha-03: rgba(0, 0, 0, 0.03);
$black-000-alpha-10: rgba(0, 0, 0, 0.1);
$black-000-alpha-15: rgba(0, 0, 0, 0.15);
$black-000-alpha-20: rgba(0, 0, 0, 0.2);
$black-000-alpha-30: rgba(0, 0, 0, 0.3);
$black-000-alpha-50: rgba(0, 0, 0, 0.5);
$black-000-alpha-60: rgba(0, 0, 0, 0.6);
$black-000-alpha-80: rgba(0, 0, 0, 0.8);
$verint-blue-alpha-80: rgba(0, 117, 228, 0.8);
$white-alpha-80: rgba(255,255,255,0.8);

$account-background-blue: #607c8c;
$account-case-management-add: #2F839F;
$account-clipboard-clipping-meta: #455965;
$account-clipboard-dropdown-menu-item-border: #e3eaed;
$account-clipboard-dropdown-menu: #001c2c;
$account-proactive-alert-danger-background: #f2dede;
$account-proactive-alert-danger-border: #ebccd1;
$account-proactive-alert-danger: #a94442;
$account-proactive-input-border: #45a546;
$account-proactive-input-shadow: #157516;
$account-proactive-pause-button-paused-border: #973737;
$account-proactive-pause-button-paused: #C94E38;
$account-rule-description-edited: #84939b;
$account-sidepanel-channel-type-tooltip-header: #D5D9DD;
$account-sidepanel-input-label: #808F98;
$account-sidepanel-save-result-label-success: #48A449;
$agent-dashboard-border-color: #DDDFE3;
$agent-dashboard-dim-text-color: #294A59;
$agent-dashboard-filter-dropdown-search: #789;
$agent-dashboard-grey-text-color: #AFB8BC;
$agent-dashboard-icon-away: #fbc933;
$agent-dashboard-icon-busy: #5abb35;
$agent-dashboard-icon-offline: #d9d9d9;
$agent-dashboard-shadow-color: rgba(195, 202, 205, 1);
$agent-dashboard-utilisation-other: #B8DFA9;
$agent-dashboard-utilisation-tooltip-label: #98A0A7;
$agent-dashboard-utilisation-tooltip-metric: #4E6772;
$carousel-control-background-color: rgba(33, 74, 91, 0.12);
$carousel-control-btn-background-color: #AFC4C3;
$dark-grey-background: #D5D9DB;
$external-links-border: #C6C1C8;
$flagged-text-highlight: rgba(255, 228, 99, 0.3);
$legacy-button-is-following-border-hover: #A52628;
$legacy-button-is-following-border: #1582d5;
$legacy-button-is-following-hover: #E03E28;
$legacy-button-is-following: #31BAF6;
$legacy-button-red-border: #AE3E23;
$light-blue-grey-background: #E2E8EC;
$orange-border: #C7480D;
$queues-message-list-picker-circle: #667d8c;
$queues-message-list-picker-text: #2e5363;
$shortcut-dialog-disabled: #929799;
$sidepanel-crm-settings-selected: #1E87BE;
$sidepanel-crm-ticket-info: #FEE664;
$sidepanel-disabled-grey: #828F98;
$sidepanel-green-border: #B6EF9F;
$sidepanel-light-blue: #A9C3FF;
$sidepanel-link-color: #2259A0;
$sidepanel-modal-sdesc: #1A88BE;
$sidepanel-note-app-pin-active: #768d98;
$sidepanel-note-app-pin-hover: #b50530;
$sidepanel-orange-border: #FFD699;
$sidepanel-pink-border: #FFACB3;
$sidepanel-queues-extras-is-following-border: #439E45;
$sidepanel-queues-extras-is-following: #63C566;
$sidepanel-red-border: #FF6666;
$sidepanel-tag-editing-border: #00008b;
$sidepanel-tmobile-charges-transactions-status-failure: #ff0000;
$sidepanel-tmobile-charges-transactions-status-pending: #ffde00;
$sidepanel-tmobile-charges-transactions-status-success: #00d400;
$sidepanel-turquoise-border: #99F7E9;
$sidepanel-violet-inactive: #F2C2FB;
$sidepanel-yellow-border: #FFF59A;


// Legacy Colours

$bright-blue-background-alpha-03: rgba(0, 144, 210, 0.03);
$bright-blue-background: #0090d2;
$centre-panel-keyboard-dialog-join: #8F9C9B;
$centre-panel-keyboard-hover: #37A;
$centre-panel-keyboard-shortcuts: #1F4F6D;
$critical-link: #FFEFAF;
$dark-blue-alpha-05: rgba(34, 74, 92, 0.05);
$dialog-info-border: #1d232c;
$dialog-warning-message: #900;
$green-alpha-30: rgba(84, 182, 85, 0.3);
$grey-blue-text: #A4BFCD;
$heart-red: #EF2331;
$legacy-account-play-rule-move-grip-dragging: #6E7D86;
$legacy-account-play-rule-move-grip: #7F8D96;
$legacy-account-play-rules-section-header: #243546;
$legacy-account-play-run-rule: #7a7b7c;
$legacy-achannel-notification-border: #CAD0D3;
$legacy-analytics-dashboard-action-archive: rgba(0, 200, 0, .6);
$legacy-analytics-dashboard-action-assign: rgba(140, 0, 140, .5);
$legacy-analytics-dashboard-action-delete: rgba(200, 0, 0, .6);
$legacy-analytics-dashboard-action-reply: rgba(0, 0, 200, .6);
$legacy-analytics-metric-bar: #D6E2EA;
$legacy-analytics-sla-bar-bg: #e2e3e5;
$legacy-analytics-sla-bar: #00aed4;
$legacy-analytics-sla-critical-bar-hover: #cf4d46;
$legacy-analytics-sla-critical-bar: #e05a43;
$legacy-analytics-sla-critical: #f6d0c9;
$legacy-analytics-sla-delete-icon-hover: #df897a;
$legacy-analytics-sla-delete-icon: #708c9c;
$legacy-analytics-sla-icon: #203c4c;
$legacy-analytics-sla-ok-bar: #4aad4b;
$legacy-analytics-sla-ok: #d1ead1;
$legacy-analytics-sla-warning-bar: #e4a22d;
$legacy-analytics-sla-warning: #f6e0bb;
$legacy-analytics-widget-csat-bar-outer: #E8EBED;
$legacy-analytics-widget-footer-attention-icon: #ffa500;
$legacy-analytics-widget-rating-data-square-score-0: #931A1B;
$legacy-analytics-widget-rating-data-square-score-1: #B62020;
$legacy-analytics-widget-rating-data-square-score-10: #46BB4C;
$legacy-analytics-widget-rating-data-square-score-2: #D82626;
$legacy-analytics-widget-rating-data-square-score-3: #DF4848;
$legacy-analytics-widget-rating-data-square-score-4: #E56B6C;
$legacy-analytics-widget-rating-data-square-score-5: #EB8D8E;
$legacy-analytics-widget-rating-data-square-score-6: #F1B0B0;
$legacy-analytics-widget-rating-data-square-score-7: #FEDE5C;
$legacy-analytics-widget-rating-data-square-score-8: #FECC01;
$legacy-analytics-widget-rating-data-square-score-9: #64C668;
$legacy-analytics-widget-score-2: #ED9455;
$legacy-analytics-widget-score-3: #B469CE;
$legacy-analytics-widget-score-4: #46B7F6;
$legacy-analytics-widget-score-detractors: #F94D4E;
$legacy-analytics-widget-score-passives: #FCCC2F;
$legacy-analytics-widget-score-promoters: #4AB951;
$legacy-analytics-widget-table-dark-grey: #797A7C;
$legacy-analytics-widget-table-light-grey: #DEE0E0;
$legacy-business-hours-info-border: #C4CDDF;
$legacy-business-hours-info: #E4EDFF;
$legacy-button-blue-active: #2AB0ED;
$legacy-button-blue-border-disabled: #648DA0;
$legacy-button-blue-border: #0776A8;
$legacy-button-blue-disabled: #7CA3B5;
$legacy-button-cyan: #03bab2;
$legacy-button-cyan-active: #50E0DA;
$legacy-button-cyan-border-active: #0FBBB4;
$legacy-button-cyan-border: #0FAEA8;
$legacy-button-cyan-disabled: #7BB7B4;
$legacy-button-dark-blue-active: #3B6D83;
$legacy-button-dark-blue-border-disabled: #565859;
$legacy-button-dark-blue-border: #1F3945;
$legacy-button-dark-blue-disabled: #6C7173;
$legacy-button-green-border-disabled: #72A572;
$legacy-button-green-disabled: #8BB88B;
$legacy-button-grey-active: #9BACB5;
$legacy-button-grey-border-active: #808D94;
$legacy-button-grey-border: #606C73;
$legacy-button-grey-disabled: #A9ABAD;
$legacy-button-red-border-active: #9f2410;
$legacy-button-red-border-disabled: #BD5840;
$legacy-button-red-disabled: #CD7370;
$legacy-button-yellow-active: #A9996A;
$legacy-button-yellow-border: #B68F23;
$legacy-button-yellow-disabled: #CFB469;
$legacy-button-youtube: var(--color-youtube-color);
$legacy-conversations-agent-message-tag: #c8e1f2;
$legacy-conversations-agent-message-working: #5a7888;
$legacy-conversations-flash: #FFFFDF;
$legacy-conversations-history: #a9bbbb;
$legacy-conversations-preview-author-online-state-active: #51b85d;
$legacy-conversations-preview-author-online-state: #727d73;
$legacy-conversations-preview-list-filters-active: #9EB6C7;
$legacy-conversations-reply-box-shadow: rgba(147,157,157,0.25);
$legacy-error-overlay-background: #fbf7dd;
$legacy-error-overlay-background: rgba(200, 20, 20, 0.85);
$legacy-error-overlay-background2: rgba(250, 245, 215, 0.85);
$legacy-error-overlay-border: #A33;
$legacy-error-overlay-success: #6FC425;
$legacy-error-overlay-warning: #876;
$legacy-font-csat-change-down-icon: #ec5950;
$legacy-font-csat-change-up-icon: #5cba3d;
$legacy-font-csat-no-change-icon: #e0e2e2;
$legacy-font-green-hover-icon: #74a653;
$legacy-font-red-hover-icon: #b64443;
$legacy-nav-old-design-button-hover: #104558;
$legacy-nav-old-design-button-text: #6EA3B6;
$legacy-nav-old-design-button: #12333F;
$legacy-permissions-advanced-switcher-hover: #E8F6FA;
$legacy-permissions-error-area: #B00;
$legacy-tag-error: #BB5120;
$light-blue-grey-border: #B8C7D2;
$prompt-blue-border: #3996B9;
$prompt-blue-hover: #5ebce0;
$red-alpha-30: rgba(211, 51, 43, 0.3);
$tag-private: #2E5E73;
$tag-public: #18A0DE;
$tag-rating: #3C97FF;
$tooltip-background: rgba(0,39,54,0.95);
$verint-blue-alpha-10: rgba(0, 117, 228, 0.1);
$verint-blue-alpha-30: rgba(0, 117, 228, 0.3);
$warning-yellow-border: #CEB861;
