:root {
    --color-scheme: 'light';

    --color-base-font-colour: #444;
    --color-button-text: #666;
    --color-border-1: #D0D4DA;
    --color-border-2: #D0D4DA;
    --color-grey-bg-1: #EEEFF0;
    --color-grey-bg-2: #F5F6F6;
    --color-white: #FFF;
    --color-white-text: #FFF;
    --color-white-text-disabled: #BBB;
    --color-white-text-disabled-2: rgba(255, 255, 255, 0.8);

    --color-verint-logo-background: #0075E4;
    --color-verint-logo-foreground: #FFF;

    --color-verint-blue: #0075E4;
    --color-green-light: #54B657;
    --color-green-dark: #459E45;
    --color-highlight: #EDF6FF;
    --color-brown: #7B6E1A;
    --color-yellow: #FFE463;
    --color-yellow-pale: #FEF9E6;

    --color-assistant-outline: #D1BDD4;
    --color-assistant-fill: #EFE0F1;
    --color-assistant-info: #8A42A7;
    --color-handover-bot: #1F8563;
    --color-bot-stroke: #9FBBAE;
    --color-bot-fill: #D9E8E1;
    --color-handover-agent: #274D5E;

    --color-orange: #EF540D;
    --color-information: #49B3DC;
    --color-dialog-background: rgba(0, 29, 44, 0.6);
    --color-warning-yellow: #F2BD25;

    --color-grey-1: #EEE;
    --color-grey-2: #DDD;
    --color-grey-3: #CCC;
    --color-grey-4: #BBB;
    --color-grey-5: #676767;

    --color-blue-highlight-1: #E2F1FF;
    --color-blue-lowlight-1: #A5BCD5;
    --color-blue-lowlight-2: #1B5CBF;
    --color-blue-focus: #3395FF;

    --color-grey-button: #F8FAFA;
    --color-grey-hover: #E7F1F1;
    --color-grey-active: #DDE5E5;
    --color-grey-disabled: #EEE;

    --color-blue-hover: #0062BF;
    --color-blue-active: #00498F;
    --color-blue-disabled: #89B8E4;

    --color-red: #D02E28;
    --color-red-hover: #B82E29;
    --color-red-active: #9E2E2A;
    --color-red-disabled: #DC9B99;

    --color-green: #0C874B;
    --color-green-hover: #0A6C3C;
    --color-green-active: #07512D;
    --color-green-disabled: #EEE;

    --color-green-light-hover: #65C566;
    --color-green-light-border: #459E45;
    --color-green-light-disabled: #A9DAAB;

    --color-orange-hover: #E24700;
    --color-orange-active: #CE4100;
    --color-orange-disabled: #F7A986;

    --color-dark-blue: #224A5C;
    --color-dark-blue-hover: #163D4E;
    --color-dark-blue-active: #103140;
    --color-dark-blue-disabled: #A7B7BE;

    --color-button-yellow: #D9A000;
    --color-button-yellow-hover: #C08D00;
    --color-button-yellow-active: #AB7E00;
    --color-button-yellow-disabled: #F0D999;

    --color-purple: #8A42A7;
    --color-purple-hover: #7F309F;
    --color-purple-active: #752794;
    --color-purple-disabled: #95839C;

    --color-tag-red: #FDD5DA;
    --color-tag-orange: #FEEACD;
    --color-tag-yellow: #FEFBCB;
    --color-tag-green: #DAF8D0;
    --color-tag-aqua: #CCFAF4;
    --color-tag-blue: #D4E1FF;
    --color-tag-pink: #F7DBFC;

    --color-login-bg: #313547;

    --color-play-button-icon: #FFF;
    --color-white-label-text: #FFF;
    --color-default-selector-button-bg: #FFF;
    --color-convo-card-bg: #FFF;
    --color-convo-card-bg-selected: #EEEFF0;
    --color-action-bar-bg: #FFF;
    --color-meta-bar-bg: #F5F6F6;
    --color-wide-bar-bg: #EEE;
    --color-dropdown-bg: #FFF;
    --color-dropdown-highlight: #EDF6FF;

    --color-dialog-header: #0075E4;
    --color-dialog-border: #DDD;
    --color-dialog-footer: #EEEFF0;

    --color-label-text: #666;
    --color-alert-text: #444;
    --color-tag-text: #444;
    --color-tooltip-text: #FFF;

    --color-form-border: #949494;
    --color-form-placeholder: #666;
    --color-form-disabled-text: #BBB;
    --color-form-white-bg: #FFF;
    --color-form-white-text: #FFF;
    --color-form-blue: #0075E4;
    
    --color-form-checkbox-bg: #FFF;
    --color-form-checkbox-bg-active: #DDD;
    --color-form-checkbox-bg-disabled: #EEE;
    --color-form-checkbox-blue: #0075E4;
    --color-form-checkbox-blue-active: #1B5CBF;
    --color-form-checkbox-blue-disabled: #CCC;
    --color-form-checkbox-border: #949494;
    --color-form-checkbox-border-hover: #0075E4;
    --color-form-checkbox-border-active: #1B5CBF;
    --color-form-checkbox-border-disabled: #DDD;
    
    --color-form-on-off-switch-blue: #0075E4;
    --color-form-on-off-switch-text: #444;
    --color-form-on-off-switch-text-disabled: #BBB;
    --color-form-on-off-switch-white-text: #FFF;
    --color-form-on-off-switch-handle: #FFF;
    --color-form-on-off-switch-handle-active: #DDD;
    --color-form-on-off-switch-handle-disabled: #EEE;

    --color-slider-bar-bg: #FFF;
    --color-slider-bar-bg-disabled: #EEE;
    --color-slider-bar-border: #CCC;
    --color-slider-bar-border-disabled: #DDD;
    --color-slider-blue: #0075E4;
    --color-slider-blue-disabled: transparent;
    --color-slider-arrow: #666;
    --color-slider-arrow-hover: #0075E4;
    --color-slider-arrow-active: #1B5CBF;
    --color-slider-arrow-disabled: #CCC;
    --color-slider-notch: #BBB;
    --color-slider-notch-disabled: #BBB;
    --color-slider-thumb-bg: #FFF;
    --color-slider-thumb-inner: #EEE;
    --color-slider-thumb-inner-hover: #E2F1FF;
    --color-slider-thumb-inner-active: #A5BCD5;
    --color-slider-thumb-inner-disabled: #EEE;
    --color-slider-thumb-border: #949494;
    --color-slider-thumb-border-hover: #0075E4;
    --color-slider-thumb-border-active: #1B5CBF;
    --color-slider-thumb-border-disabled: #DDD;

    --color-selector-border: #CCC;

    --color-clipboard-selected-text: #FFF;
    --color-tag-text-unselected: #444;
    --color-clipping-preview-text: #666;

    --color-text-highlighter: #ffebbe;

    // Third Party Brand colours

    --color-youtube-color: #FF0100;
    --color-twitter-blue: #019ad2;
    --color-facebook-blue: #395897;
    --color-google-plus-red: #d34836;
    --color-google-my-business-blue: #4f7edc;
    --color-instagram-blue: #34638b;
    --color-synthesio-red: #c5001a;
    --color-wechat-green: #00c60c;
    --color-whatsapp-green: #128c7e;
    --color-abc-blue: #007aff;
    --color-sidepanel-shopify-green: #3AAB26;
    --color-sidepanel-shopify-unfulfilled: #dda043;
    --color-legacy-button-google-plus: #E35439;
    --color-legacy-button-google-plus-icon: #E15541;
    --color-legacy-button-google-plus-border: #C54830;
    --color-twitter-like-red: #E81C4F;
}
