.table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    td, th {
        text-align: left;
    }
    td.text-right, th.text-right {
        text-align: right;
    }
    thead > tr > th {
        border-bottom: 2px solid $border-1;
        color: $base-font-colour;
        padding: 0 10px 10px;
        font-size: 12px;
    }
    tbody > tr > td {
        border-bottom: 1px solid $border-1;
        padding: 10px;
    }
    tbody > tr:last-child > td {
        border-bottom: none;
    }
    &.table-large {
        thead > tr > th {
            padding: 0 15px 10px;
            font-size: 13px;
        }
        tbody > tr > td {
            padding: 15px;
            font-size: 14px;
        }
    }
    &.table-small {
        thead > tr > th {
            padding: 0 8px 8px;
            font-size: 12px;
        }
        tbody > tr > td {
            padding: 8px;
            font-size: 12px;
        }
    }
    &.table-xsmall {
        thead > tr > th {
            padding: 0 6px 5px;
            font-size: 12px;
        }
        tbody > tr > td {
            padding: 6px;
            font-size: 12px;
        }
    }
    .tag {
        margin-right: 0;
    }
}
.reactable-header-sortable,
.header-sortable {
    position: relative;
    cursor: pointer;
    @include user-select(none);
    &:hover {
        color: $verint-blue;
    }
    &.reactable-header-sort-asc,
    &.reactable-header-sort-desc,
    &.sorted-asc,
    &.sorted-desc {
        color: $verint-blue;
        &:before {
            position: absolute;
            font-family: 'fontello';
            font-size: 16px;
            top: 0px;
            left: 0px;
            .table-xsmall &,
            .table-small & {
                left: -2px;
            }
        }
    }
    &.reactable-header-sort-desc,
    &.sorted-desc {
        @extend .icon-down-dir-1;
    }
    &.reactable-header-sort-asc,
    &.sorted-asc {
        @extend .icon-up-dir-1;
    }
}
